import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, Select, Col, Row } from "antd";
import moment from "moment";
import { normalize } from "normalizr";
import EventService from "services/event.api";
import { eventByDateSchema } from "schema/Event";
import { actGetEventByDate } from "store/actions/event.action";
import { RootState } from "models/index.model";
import { EventItemType } from "models/event.model";
import "./style.scss";

interface IProps {
  listEventCallBack: (events: EventItemType[]) => void;
}

function CalendarComponent(props: IProps) {
  const { listEventCallBack } = props;
  const dispatch = useDispatch();
  const [month] = useState(moment().format("MM-YYYY"));
  const [dateNow] = useState(moment().format("YYYY-MM-DD"));

  const listEvent =
    useSelector(
      (state: RootState) => state.eventReducer.eventsByDate.entitiesEvent
    ) || {};

  async function onPanelChange(value: any) {
    const date = moment(value._d).format("MM-YYYY");
    try {
      const { code, data } = await EventService.getEventByCalendar(date);
      if (code === 1) {
        const { entities, result } = normalize(data, [eventByDateSchema]);
        dispatch(
          actGetEventByDate({
            events: data,
            entitiesEvent: entities.date,
            eventId: result,
          })
        );
      }
    } catch {}
  }

  async function handleOnChange(value: any) {
    const dateSelected = moment(value._d).format("YYYY-MM-DD");
    const listEventByDate = listEvent[dateSelected]?.events;
    listEventCallBack(listEventByDate);
  }

  /* Get month Now */
  async function getListEventByMonthNow() {
    try {
      const { code, data } = await EventService.getEventByCalendar(month);
      if (code === 1) {
        const { entities, result } = normalize(data, [eventByDateSchema]);
        dispatch(
          actGetEventByDate({
            events: data,
            entitiesEvent: entities.date,
            eventId: result,
          })
        );
      }
    } catch {}
  }

  function dateCellRender(value: any) {
    const date = moment(value).format("YYYY-MM-DD");
    const filterEvent = listEvent[date]?.events ?? [];
    const hasEventInDate = filterEvent.length === 0;
    if (hasEventInDate) return null;
    return <div className="custom-date"></div>;
  }

  /* Get event by date now */
  useEffect(() => {
    getListEventByMonthNow();
    listEventCallBack(listEvent[dateNow]?.events);
  }, []);

  return (
    <div className="calendar-wrapper">
      <Calendar
        fullscreen={false}
        headerRender={({ value, onChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          const current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
          }

          const year = value.year();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            );
          }

          for (let index = start; index < end; index++) {
            monthOptions.push(
              <Select className="month-item" key={`${index}`}>
                {months[index]}
              </Select>
            );
          }
          const month = value.month();

          return (
            <div className="calendar-header-wrap">
              <Row gutter={20}>
                <Col span={12}>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={String(month)}
                    onChange={(selectedMonth: any) => {
                      const newValue = value.clone();
                      newValue.month(parseInt(selectedMonth, 10));
                      onChange(newValue);
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
                <Col span={12}>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    className="year-select"
                    onChange={(newYear: any) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                    value={String(year)}
                  >
                    {options}
                  </Select>
                </Col>
              </Row>
            </div>
          );
        }}
        dateCellRender={dateCellRender}
        onPanelChange={onPanelChange}
        onChange={handleOnChange}
      />
    </div>
  );
}

export default CalendarComponent;
