const doctor1 = "/image-doctor/doctor/doctor1.png";
const refresh = "/image-doctor/doctor/refresh_24px.png";
const avatar = "/image-doctor/doctor/avatar.png";
const product = "/image-doctor/doctor/product1.png";
const besins_logo = "/image-doctor/doctor/besins-mobile-logo.png";
const logo = "/image-doctor/logo.png";
const check_circle = "/image-doctor/icon/check_circle.svg";
const vi_lang = "/image-doctor/lang/vi_lang.svg";
// images homepage
const background_homepage = "/image-doctor/doctor/background-homepage.svg";
const background_no_shadow_home = "/image-doctor/doctor/background_no_shadow_home.svg";
const background_shadow_home = "/image-doctor/doctor/background_shadow_home.svg";
const icon_add_order = "/image-doctor/doctor/icon-add-order.svg";
const icon_list_order = "/image-doctor/doctor/icon_list_order.svg";
const coming_soon = "/image-doctor/doctor/coming-soon.svg";
const icon_promotion = "/image-doctor/doctor/icon_promotion.svg";
const promotion = "/image-doctor/icon/promotion.svg";
const close = "/image-doctor/icon/close.svg";
const warning = "/image-doctor/icon/warning.svg";
const arrow_back = "/image-doctor/icon/arrow_back.svg";
const icon_sale = "/image-doctor/icon/icon-sale.svg";
const icon_error_noti = "/image-doctor/icon/error_icon_24px.png";
const icon_success_noti = "/image-doctor/icon/success_icon_24px.png";
const icon_event = "/image-doctor/icon/icon-event.png";
const arow_right = "image-doctor/icon/arow-right.png";
const bgEvent = "image-doctor/doctor/bg-event.png";
const icon_start = "image-doctor/icon/icon-start.png";
const arrow_right_blue = "image-doctor/icon/arrow_right_blue.svg";
const home_header = "image-doctor/doctor/home_header.png";
const icon_storage_news = "image-doctor/doctor/icon_storage_news.svg";
const icon_storage_news_click = "image-doctor/doctor/icon_storage_new_click.svg";
const icon_news_archive = "image-doctor/doctor/icon_news_archive.svg";
const background_card = "image-doctor/doctor/background_card.svg";
const img_percent = "/image-doctor/doctor/img_percent.svg";
const promotion2 = "/image-doctor/icon/promotion-2.svg";
const icon_help = "/image-doctor/icon/help.png";
const IconSuccess = "/image-doctor/icon/success-modal.png";
const logoBesins = "/image-doctor/besins-logo.svg";
const icon_filter = "/image-doctor/doctor/icon_filter.svg";
const icon_notification_home = "/image-doctor/icon/icon_notification_home.svg";
const icon_menu_home = "/image-doctor/icon/icon_menu_home.svg";
const icon_notification_home_content = "/image-doctor/icon/icon_notification_home_content.svg"
const icon_clock_news = "/image-doctor/icon/icon_clock_news.svg";
const hoithao = "/image-doctor/icon/hoithao.svg";
const bookmark = "/image-doctor/icon/bookmark.svg";
const bookmark_news = "/image-doctor/icon/bookmark_news.svg";
const search = "/image-doctor/icon/search.svg";
const notification_create_order = "image-doctor/doctor/notification_create_order.svg";
const bookmark_click = "/image-doctor/doctor/bookmark_click.svg";

/////
const home_navigation = "/image-doctor/icon/home_navigation.svg";
const home_navigation_active = "/image-doctor/icon/home_navigation_active.svg";
const contact_navigation = "/image-doctor/icon/contact_navigation.svg";
const contact_navigation_active = "/image-doctor/icon/contact_navigation_active.svg";
const calendar_navigation = "/image-doctor/icon/calendar_navigation.svg";
const calendar_navigation_active = "/image-doctor/icon/calendar_navigation_active.svg";
const news_navigation = "/image-doctor/icon/news_navigation.svg";
const news_navigation_active = "/image-doctor/icon/news_navigation_active.svg";
const cancel_search ="/image-doctor/icon/cancel_search.svg"
const bookmark_click_news_icon ="/image-doctor/doctor/bookmark_click_news_icon.svg";
const bookmark_news_icon ="/image-doctor/doctor/bookmark_news_icon.svg";


export {
  bookmark_click_news_icon,
  bookmark_news_icon,
  cancel_search,
  notification_create_order,
  bookmark_news,
  icon_clock_news,
  bookmark_click,
  bookmark,
  logoBesins,
  avatar,
  doctor1,
  refresh,
  product,
  logo,
  check_circle,
  besins_logo,
  vi_lang,
  background_homepage,
  icon_add_order,
  icon_list_order,
  coming_soon,
  icon_promotion,
  promotion,
  close,
  warning,
  arrow_back,
  icon_sale,
  icon_error_noti,
  icon_success_noti,
  icon_event,
  arow_right,
  bgEvent,
  icon_start,
  home_header,
  promotion2,
  icon_help,
  IconSuccess,
  icon_filter,
  icon_notification_home,
  icon_menu_home,
  icon_notification_home_content,
  background_no_shadow_home,
  background_shadow_home,
  background_card,
  arrow_right_blue,
  img_percent,
  icon_storage_news,
  icon_news_archive,
  icon_storage_news_click,
  ////////
  home_navigation,
  home_navigation_active,
  contact_navigation,
  contact_navigation_active,
  calendar_navigation,
  calendar_navigation_active,
  news_navigation,
  news_navigation_active,
  hoithao,
  search
};
