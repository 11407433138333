import types from "../actionTypes";

export const actSetToken = (token: string) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_TOKEN, token });
  };
};

export const actSetTokenForMobile = (token: string, isMobile?: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_TOKEN_FOR_MOBILE, token, isMobile });
  };
};
