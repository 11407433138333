import React, { useState } from 'react';
import { Col, Collapse, DatePicker, Drawer, Radio, Row } from 'antd';
import BesinsButton from 'components/BesinsButton';
import NewServices from 'services/news.api';
import './style.scss';
import { formatDate } from 'utils/formatDate';
import { useEffect } from 'react';
const { Panel } = Collapse;

const styleRadio = {
  borderRadius: '10px',
  marginRight: '10px',
  marginBottom: '5px',
  marginTop: '5px',
};

const styleTextRadio = {
  fontSize: '14px',
  marginTop: '50px',
};

type Props = {
  onClose?: any;
  visible?: boolean;
  getDataNews: any;
  setIsSearch: any;
  setParamsFilter: any;
};

function NewsDrawer(props: Props) {
  //props
  const { onClose, visible, setParamsFilter, setIsSearch } = props;
  const [tempArticles, setTempArticles] = useState([]);
  const [tempSanKhoa, setTempSanKhoa] = useState([]);
  const [radioArticles, setRadioArticles] = useState('');
  const [radioFieldTreatment, setRadioFieldTreatment] = useState('');
  const [valueActive, setValueActive] = useState('0');
  const [date, setDate] = useState<any>({
    startDate: '',
    endDate: '',
  });
  const [params, setParams] = useState<any>({
    arrItem: [],
    typeArticle: '',
  });
  async function getDataTypeArticle() {
    try {
      const res = await NewServices.getTypeArticle('');
      if (res) {
        setTempArticles(res);
      }
    } catch (error) {}
  }

  async function getDataFieldTreatment() {
    try {
      const res = await NewServices.getFieldTreatment('');

      if (res) {
        setTempSanKhoa(res);
      }
    } catch (error) {}
  }

  function onChangeItemRadio(e: any, variable: object) {
    setParams({ ...params, arrItem: [variable] });
    setValueActive(e.target.value);
  }
  function onSearch() {
    const paramsFunction = {
      typeArticle: params.typeArticle,
      date: {
        startDate: formatDate(date.startDate),
        endDate: formatDate(date.endDate),
      },
      subCategoryId: JSON.stringify(params.arrItem),
    };
    setParamsFilter((previousState: any) => ({
      ...previousState,
      ...paramsFunction,
    }));
    setIsSearch(true);
    onClose();
  }

  function onRadioFieldTreatment(e: any, variables: any) {
    setRadioFieldTreatment(e.target.value);
    setParams({ ...params, arrItem: [variables.subCategory[0]._id] });
  }

  function onRadioArticle(e: any, variables: any) {
    setRadioArticles(e.target.value);
    setParams({ ...params, typeArticle: variables._id });
  }

  function onReset() {
    onClose();
    setRadioArticles('');
    setValueActive('');
    setIsSearch(false);
    setParamsFilter((previousState: any) => ({
      ...previousState,
      typeArticle: '',
      startDate: '',
      endDate: '',
      subCategoryId: [],
      limit:5
    }));
    setDate({
      startDate: '',
      endDate: '',
    });
  }
  
  function onChangeDate(name: string, value: any) {
    setDate((previousState: any) => ({ ...previousState, [name]: value }));
  }

  function onChangeCollapse(key: any) {
  }
  const onCloseToggle = () => {
    onClose();
  };

  useEffect(() => {
    if(visible){
      getDataFieldTreatment();
      getDataTypeArticle();

    }
  }, [visible])

  return (
    <div>
      <Drawer
        title={<div className="news-title">Lọc kết quả tìm kiếm</div>}
        placement={'right'}
        closable
        onClose={onCloseToggle}
        visible={visible}
        key={'placement'}
        width={355}
        className="news-Drawer"
        footer={[
          <div className="news-Drawer__footer">
            <BesinsButton
              type="default"
              size="large"
              className="btn-reset"
              onClick={onReset}
            >
              Thiết lập lại
            </BesinsButton>
            <BesinsButton
              type="primary"
              size="large"
              className="btn-search"
              onClick={onSearch}
            >
              Tìm kiếm
            </BesinsButton>
          </div>,
        ]}
      >
        <Collapse
          className="news-collapse"
          expandIcon={({ isActive }) => (
            <>
              {isActive ? (
                <i className="icon icon-expand-more collapse-arrow" />
              ) : (
                <i className="icon icon-expand-less collapse-arrow" />
              )}
            </>
          )}
          onChange={onChangeCollapse}
        >
          <Panel header="Thời gian" key="1">
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <div className="caption-1 date-picker">Chọn ngày</div>
              </Col>
              <Col span={12}>
                <DatePicker
                  name="startDate"
                  placeholder="Ngày bắt đầu"
                  value={date.startDate}
                  onChange={(value) => onChangeDate('startDate', value)}
                  format="DD-MM-YYYY"
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  name="endDate"
                  placeholder="Ngày kết thúc"
                  value={date.endDate}
                  onChange={(value) => onChangeDate('endDate', value)}
                  format="DD-MM-YYYY"
                />
              </Col>
            </Row>
          </Panel>
          <Panel header="Lĩnh vực điều trị" key="2">
            {tempSanKhoa?.map((prop: any, key: any) => {
              return (
                <div className="radio-group">
                  <Radio.Group
                    value={radioFieldTreatment}
                    onChange={(e) => onRadioFieldTreatment(e, prop)}
                  >
                    <Radio value={key}>
                      <span className="subtitle-3">{prop.title}</span>
                    </Radio>
                    {radioFieldTreatment === key && (
                      <div className="option-fields">
                        {prop.subCategory &&
                          prop.subCategory.length > 0 &&
                          prop.subCategory.map((prop2: any, key2: any) => {
                            return (
                              <Radio.Group
                                value={valueActive}
                                buttonStyle="solid"
                                onChange={(e) =>
                                  onChangeItemRadio(e, prop2._id)
                                }
                              >
                                <Radio.Button
                                  value={key2.toString()}
                                  style={styleRadio}
                                >
                                  <span style={styleTextRadio}>
                                    {/* {isShowIcon && <i className="icon icon-done-24px-rounded" />} */}
                                    {prop2.title}
                                  </span>
                                </Radio.Button>
                              </Radio.Group>
                            );
                          })}
                      </div>
                    )}
                  </Radio.Group>
                </div>
              );
            })}
          </Panel>
          <Panel header="Loại tin tức" key="3">
            {tempArticles?.map((prop: any, key: any) => {
              return (
                <div className="radio-group">
                  <Radio.Group
                    value={radioArticles}
                    onChange={(e) => onRadioArticle(e, prop)}
                  >
                    <Radio value={key}>
                      <span className="subtitle-3">{prop.title}</span>
                    </Radio>
                  </Radio.Group>
                </div>
              );
            })}
          </Panel>
        </Collapse>
      </Drawer>
    </div>
  );
}

NewsDrawer.propTypes = {};

export default NewsDrawer;
