import { Card, Tag, Typography } from "antd";
import React, { useState } from "react";
import "./style.scss";
import { bookmark_click, bookmark } from "variables/image";

import { Link } from "react-router-dom";
import NewServices from "services/news.api";
import { actBookMark } from "store/actions/news.action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "models/index.model";
import { isBuffer } from "lodash";
const { Paragraph } = Typography;

interface PropsLatestCard {
  loading?: boolean;
  avatar?: string;
  title?: string;
  text?: string;
  url?: string;
  createdAt?: string;
  id?: string;
  isBookmark: boolean;
  idArticle?: string;
  onBookmark?: () => void;
  isNewsOther?: boolean;
  news: object;
  isCategory?: boolean;
  tags: string;
}
const LatestCard = (props: PropsLatestCard) => {
  const {
    loading,
    avatar,
    title,
    url,
    isCategory,
    isBookmark,
    idArticle,
    onBookmark,
    isNewsOther = false,
    tags,
  } = props;
  const dispatch = useDispatch();
  const news_list_hots = useSelector((state: RootState) => state?.newsReducer.news_list?.news_list_hots);
  const news_list_new = useSelector((state: RootState) => state?.newsReducer.news_list?.news_list_new);
  const [stateBookmark, setStateBookmark] = useState(isBookmark);

  const onClickBookmark = async () => {
    const arrHotRemove = news_list_hots.filter((item:any) => item._id !== idArticle);
    const arrNewRemove = news_list_new.filter((item:any) => item._id !== idArticle);
    const hotnew = news_list_hots.find((data:any) => data._id === idArticle)
    const index_hotnew = news_list_hots.findIndex((data:any) => data._id === idArticle)
    const newnew = news_list_new.find((data:any) => data._id === idArticle)
    const index_newnew = news_list_new.findIndex((data:any) => data._id === idArticle)
    const data = {
      new:arrNewRemove,
      hot:arrHotRemove
    }    
    const param = {
      articleId: idArticle,
      isBookmark: false,
    };
    try {
      const res = await NewServices.getBookMark(param);
      if (onBookmark) {
        onBookmark();
      }
      if (res?.data === "BOOK_MARK_SUCCESS") {
        if(hotnew){
        arrHotRemove.splice(index_hotnew, 0, {...hotnew, isBookmark:true});
        }
        arrNewRemove.splice(index_newnew, 0, {...newnew, isBookmark:true});
        dispatch(actBookMark(data))
      }
      if(res?.data === "UNBOOK_MARK_SUCCESS"){
        if(hotnew){
        arrHotRemove.splice(index_hotnew, 0, {...hotnew, isBookmark:false});
        }
        arrNewRemove.splice(index_newnew, 0, {...newnew, isBookmark:false});
        dispatch(actBookMark(data))
      }

    } catch (error) {}
  };
  return (
    <>
      <Card loading={loading} className="latestCard-container">
        <div className="latestCard-content">
          <img src={avatar} alt="" className="latestCard-avatar" />{" "}
          <div className="latestCard-description">
            <Link
              to={{
                pathname: `/news/${
                  isNewsOther
                    ? "details"
                    : isCategory
                    ? "details"
                    : "details"
                }/${url}`,
                search: `id=${idArticle}`,
              }}
            >
              <Paragraph
                ellipsis={{ rows: 8, expandable: false }}
                className="latestCard-title"
              >
                {title}
              </Paragraph>
            </Link>
            <div className="d-flex align-items-center justify-content-between mt-1">
              <div className="d-flex align-items-center">
                <span
                  className="event__calendar-text"
                  style={{ cursor: "pointer" }}
                >
                  {tags?.length !== 0 ? (
                    <Tag color="processing">{tags}</Tag>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div style={{ cursor: "pointer" }}>
                <img
                  src={`${stateBookmark ? bookmark_click : bookmark}`}
                  alt=""
                  onClick={onClickBookmark}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default React.memo(LatestCard);
