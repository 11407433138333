import types from "../actionTypes";
import update from "react-addons-update";

interface IAction {
  type: string;
  payload?: any;
}

const initialState = {
  register: {
    data: {
      phone: "",
      phoneOrder: "",
      email: "",
      password: "",
      fullName: "",
      office: "",
      address: "",
      province: "",
      provinceCode: "",
      district: "",
      districtCode: "",
      ward: "",
      wardCode: "",
    },
    initStep: "step1",
    loading: false,
    error: "",
    checkPhone:false,
  },
};

const authReducer = (state = initialState, { type, payload }: IAction) => {
  switch (type) {
    case types.CHECK_PHONE_EXIST:
      return update(state, {
        register: {
          initStep: { $set: payload.initStep },
          data: {$set: payload.data},
          checkPhone:{$set:payload.checkPhone}
        },
      });
    case types.VERIFY_CODE_PENDING:
      return update(state, {
        register: {
          loading: { $set: true },
        },
      });
    case types.VERIFY_CODE_ERROR:
      return update(state, {
        register: {
          loading: { $set: false },
          error: { $set: payload.error },
        },
      });
    case types.VERIFY_CODE_SUCCESS:
      return update(state, {
        register: {
          initStep: { $set: payload.initStep },
        },
      });
    case types.RESET_AUTH :{
      return {...state};
    }
    default:
      return state;
  }
};

export default authReducer;
