import actionTypes from "../actionTypes";

const initialState = {
  quantity_homepage: {},
};

const homeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_QUANTITY_HOMEPAGE:
      state = {
        ...state,
        quantity_homepage: action.quantity_homepage,
      };
      return state;
    default:
      return state;
  }
};

export default homeReducer;
