import callApi from "../utils/callApi";

const HomeService = {
  getQuantity: async () => {
    const endpoint = `cms/dashboardCustomer`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
};

export default HomeService;
