/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// components
import BesinsButton from 'components/BesinsButton';
import LatestCard from 'components/BesinsNews/LatestNews/LatestCard';
import OutstandingNews from 'components/BesinsNews/OutstandingNews';
import NewsDrawer from 'components/Drawer/NewsDrawer';
import WithNavigation from 'layouts/WithNavigation';
import HOST_URL_IMAGE from 'variables/hostUrl';
// redux
import { RootState } from 'models/index.model';
import { useDispatch, useSelector } from 'react-redux';
// services
import {
  actGetHotNews,
  actGetNewNews,
  actGetSetNews,
} from 'store/actions/news.action';
import NewServices from 'services/news.api';
// style
import './style.scss';

type Props = {};

const NewsPage = (props: Props) => {
  const dispatch = useDispatch();
  const news = useSelector((state: RootState) => state?.newsReducer?.news);
  const news_list_hots = useSelector(
    (state: RootState) => state?.newsReducer.news_list?.news_list_hots
  );
  const news_list_new = useSelector(
    (state: RootState) => state?.newsReducer.news_list?.news_list_new
  );

  // State
  const [nextPage, setNextPage] = useState<number>(1);
  const [params, setParams] = useState({
    page: nextPage,
    limit: 5,
    search: '',
    typeArticle: '',
    startDate: '',
    endDate: '',
    subCategoryId: [],
  });
  
  const [isSearch, setIsSearch] = useState(false);
  // Function

  async function getDataHotNew() {
    try {
      const res = await NewServices.getNews('');
      if (res) {
        dispatch(actGetHotNews(res.docs));
      }
    } catch (error) {}
  }
  async function getDataNews(reset: boolean = false) {
    const paramsFilter = { ...params };
    try {
      const res = await NewServices.getListNew(paramsFilter);

      if (res) {
          dispatch(
            actGetNewNews(res.docs)
          );
        setNextPage(res.nextPage);
      }
    } catch (error) {}
  }

  function onToggleNewsFilter() {
    dispatch(
      actGetSetNews({
        ...news,
        isShow: !news?.isShow,
      })
    );
  }

  function OutstandingNewsItem() {
    return (
      <div className="outstanding-News">
        <div className="outstanding-News__title">Tin nổi bật</div>
        <OutstandingNews data={news_list_hots} />
      </div>
    );
  }


  function LatestNewsItem() {
    return (
      <div className="latest-News">
        <div className="latest-News__title">
          {isSearch ? 'Kết quả tìm kiếm' : 'Tin mới nhất'}
        </div>
        <div className="latest-News__list">
          {news_list_new?.map((el: any) => {
            return (
              <>
                <LatestCard
                  tags={el?.tags}
                  news={el}
                  idArticle={el?._id}
                  loading={news?.isLoading}
                  title={el?.title}
                  avatar={`${HOST_URL_IMAGE}/${el.images?.size.ONE_ONE?.url}`}
                  text={el?.description}
                  url={el.url}
                  id={el?._id}
                  createdAt={el?.createdAt}
                  isBookmark={el?.isBookmark}
                />
                <div
                  className="dashs"
                  style={{ height: 1, backgroundColor: '#E6E8EB' }}
                ></div>
              </>
            );
          })}
          {isSearch && news_list_new?.length === 0 && (
            <div style={{ marginTop: '10px' }}>Không có kết quả tìm kiếm</div>
          )}
          {news_list_new.length !== 0 && nextPage && !isSearch ? (
            <div className="latest-News__readMore">
              <BesinsButton
                type="primary"
                loading={news?.isLoading}
                onClick={() => setParams({...params, limit: params.limit + 5})}
              >
                Xem Thêm
              </BesinsButton>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    getDataHotNew();
  }, []);
  useEffect(() => {
    getDataNews();
  }, [params]);
  useEffect(() => {
    return function cleanup() {
      dispatch(actGetNewNews([]));
    };
  }, []);
  return (
    <>
      {news.isShow ? (
        <NewsDrawer
          visible={news.isShow}
          onClose={onToggleNewsFilter}
          getDataNews={getDataNews}
          setIsSearch={setIsSearch}
          setParamsFilter={setParams}
        />
      ) : (
        ''
      )}
      <div className="home-card-white">
        {!isSearch && <OutstandingNewsItem />}
        <LatestNewsItem />
      </div>
    </>
  );
};

export default WithNavigation(NewsPage);
