import React, { useState, useEffect } from "react";
import "./style.scss";
import { formatMoney } from "../../../utils/formatMoney";
import { RootState } from "../../../models/index.model";
import { useSelector, useDispatch } from "react-redux";
import { actUpdateCart } from "../../../store/actions/order.action";
type Props = {
  cartItem?: any;
  openDeleteConfirmModal?: any;
  currentStep?: number;
};
function BesinsProductConfirm(props: Props) {
  const dispatch = useDispatch();
  

  //props
  const { cartItem } = props;
  const cart = useSelector((state: RootState) => state.orderReducer.cart);

  //state
  const [quantity, setQuantity] = useState(cartItem.quantity);


  async function filterPromotion(quantity: number) {
    let promotionList = cartItem?.promotion?.filter((item: any) => {
      if (item.maxPerMonth === 0) {
        return item.amount <= quantity;
      }
      const maxQuantity = item.maxPerMonth - item.orderedAmount;
      return quantity >= item.amount && quantity <= maxQuantity;
    });
    findMaxQuantity(promotionList);
  }

  async function findMaxQuantity(arrayPromotion: any) {
    let arrPercent: []  = arrayPromotion?.map((item: any) => item.percent);
    let maxAmount: number = 0;
    arrPercent?.forEach((e) => {
      if(e > maxAmount){
        maxAmount = e;
      }
    })
    const promotion = arrayPromotion.filter(
      (item: any) => item.percent === maxAmount
    );
    setAvailablePromotion(promotion);
  }

  async function setAvailablePromotion(promotion: any) {
    const itemIndex = cart.findIndex((p) => p._id === cartItem._id);
    if (itemIndex >= 0) {
      let cartCopy = [...cart];
      cartCopy[itemIndex].availablePromotion = promotion?.[0]!;
      dispatch(actUpdateCart(cartCopy));
    }
  }

  async function totalProduct(quantity: number) {
    let total = 0;
    //nếu có promotion thích hợp
    if (cartItem?.availablePromotion!) {
      total =
        quantity * cartItem?.price -
        (quantity * cartItem?.price * cartItem?.availablePromotion?.percent!) /
          100;
    } else {
      // nếu ko có promotion
      total = cartItem?.price * quantity;
    }
    const itemIndex = cart.findIndex((p) => p._id === cartItem._id);
    if (itemIndex >= 0) {
      let cartCopy = [...cart];
      cartCopy[itemIndex].total = total;
      dispatch(actUpdateCart(cartCopy));
    }
  }

  useEffect(() => {
    filterPromotion(quantity);
    totalProduct(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  //   const closeConfirmModal = () => {
  //     setIsOpenModal(false);
  //   };

  useEffect(() => {
    setQuantity(cartItem.quantity);
  }, [cartItem.quantity]);

  return (
    <>
      <div className="product-confirm">
        <div className="detail-info">
            
          <span className="product-name">
            <b>{quantity} x </b>
            {cartItem.name}
          </span>
          <span className="product-price">
            {cartItem?.availablePromotion!
              ? //nếu có giảm giá
                formatMoney(
                  cartItem?.price * cartItem?.quantity -
                    (cartItem?.price *
                      cartItem?.quantity *
                      cartItem?.availablePromotion?.percent) /
                      100
                )
              : //nếu không có giảm giá
                formatMoney(cartItem?.price * cartItem?.quantity)}
          </span>
        </div>
        <div className="detail-extra">
          

          {cartItem?.availablePromotion! ? (
            <div className="promotion">
              <div className="promotion-price">
                đ {formatMoney(cartItem?.price * cartItem?.quantity)}
              </div>
              <div className="promotion-discount">
                -{cartItem?.availablePromotion?.percent}%
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

BesinsProductConfirm.propTypes = {};

export default BesinsProductConfirm;
