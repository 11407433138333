import { combineReducers } from "redux";
import exampleReducer from "./example.reducer";
import tokenReducer from "./token.reducer";
import profileReducer from "./profile.reducer";
import orderReducer from "./order.reducer";
import homeReducer from "./home.reducer";
import promotionReducer from "./promotion.reducer";
import eventReducer from "./event.reducer";
import authReducer from "./auth.reducer";
import newsReducer from "./news.reducer";

const appReducers = combineReducers({
  exampleReducer,
  authReducer,
  tokenReducer,
  profileReducer,
  orderReducer,
  homeReducer,
  promotionReducer,
  eventReducer,
  newsReducer
});

export default appReducers;
