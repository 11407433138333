import React, { useEffect, useState } from "react";
import AppLayout from "layouts/app";
import { useDispatch } from "react-redux";
import { actSetTokenForMobile } from "store/actions/token.action";
import { Skeleton } from "antd";
import "./style.scss";

const CheckLoginBy = () => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  function gup(name: string, url: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(url);
    return results == null ? null : results[1];
  }
  const uri = window.location.href;
  const encoded = encodeURI(uri);
  const token = gup("token", encoded);
  const handleRender = async () => {
    if (token) {
      localStorage.setItem("access_token", token);
      dispatch(actSetTokenForMobile(token, true));
    }
  };

  useEffect(() => {
    handleRender();
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  }, []);

  return checked ? (
    <AppLayout />
  ) : (
    <Skeleton loading={true} active paragraph={{ rows: 10 }} />
  );
};

export default CheckLoginBy;
