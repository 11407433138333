/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  icon_list_order,
  icon_promotion,
  coming_soon,
  icon_menu_home,
  arow_right,
  img_percent,
  arrow_right_blue,
  home_header,
  icon_notification_home,
  icon_notification_home_content,
} from "variables/image";
import BesinsButton from "components/BesinsButton";
import { Row, Col, Avatar } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "models/index.model";
import BesinsCarousel from "components/BesinsCarousel";
import BesinsCard from "components/BesinsCard";

import HeaderHome from "./HeaderHome";
import "./style.scss";
import HOST_URL_IMAGE from "variables/hostUrl";
import EventService from "services/event.api";
import {
  actGetEventAllPin,
  actGetEventDetail,
} from "store/actions/event.action";
import { ClipLoader } from "react-spinners";
import WithNavigation from "layouts/WithNavigation";
import { normalize } from "normalizr";
import { eventSchema } from "schema/Event";
import HomeService from "services/home.api";
import { actGetQuantityHomepage } from "store/actions/home.action";
import qs from "query-string";
import classNames from "classnames";
// import OrderService from "services/order.api";
// import NotificationItem from "components/Notification/NotificationItem";
// import { timeAgo } from "utils/formatDate";
import PromotionService from "services/promotion.api";

import moment from "moment";
import HomeNews from "components/BesinsNews/HomeNews";
import { actGetPromotionDetail } from "store/actions/promotion.action";
import PromotionDetail from "components/PromotionDetail";
import { renderName } from "utils";

// import ConfirmationModal from "components/BesinsModal/ConfirmationModal";
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const HomePage = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    if (query.isGoBack) setOpen(true);
  }, [location, query.isGoBack]);

  const user_info = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );

  const quantity_homepage = useSelector(
    (state: RootState) => state.homeReducer.quantity_homepage
  );
  const all_noti = useSelector(
    (state: RootState) => state.orderReducer.user_all_noti
  );
  const { entitiesEvent = {}, eventId = [] } = useSelector(
    (state: RootState) => state.eventReducer.events
  );

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [notiEvents, setNotiEvents] = useState<any[]>([]);
  const [dataPromotion, setDataPromotion] = useState<any[]>([]);
  const [popUpPromotion, setPopUpPromotion] = useState(false);

  // const [notiOrders, setNotiOrders] = useState<any[]>([]);
  // const [modalConfirmOrder, setModalConfirmOrder] = useState<boolean>(false);
  function toggleNoti() {
    setOpen(false);
  }
  function toggle() {
    setOpen(!open);
  }
  function closeInfo() {
    setOpen(false);
  }

  function handleFilterData() {
    let filterNoti =
      all_noti &&
      all_noti.docs &&
      all_noti.docs.filter((item) => {
        return item.type !== "ADMIN_UPDATE_ORDER" && !item.users.isSeen;
      });
    return { filterNoti };
  }

  async function getDataPromotion() {
    const data = {
      limit: 1,
    };
    try {
      const res = await PromotionService.getListPromotion(data);
      if (res) {
        setDataPromotion(res.data.docs);
      }
    } catch (error) {}
  }

  async function getPromotionDetail(id: any) {
    try {
      let res = await PromotionService.getPromotion(id);
      if (res.code === 1) {
        dispatch(actGetPromotionDetail(res.data));
      }
    } catch (err) {
      //console.log(err);
    }
  }

  async function getAllEvents() {
    setLoading(true);
    try {
      const { data } = await EventService.getEventsPin();
      const { entities, result } = normalize(data.docs, [eventSchema]);

      dispatch(
        actGetEventAllPin({
          events: data,
          entitiesEvent: entities.events,
          eventId: result,
        })
      );

      setLoading(false);
    } catch (err) {}
  }

  async function handleGetQuantity() {
    try {
      const res = await HomeService.getQuantity();
      if (res?.code === 1) {
        dispatch(actGetQuantityHomepage(res.data));
      }
    } catch (err) {
      //console.log(err);
    }
  }

  const handleOnClickCard = async (url: string, id: string) => {
    history.replace(`/events/details/${url}?id=${id}`);
    setOpen(true);
    setLoading(true);

    try {
      const { code, data: eventDetail } = await EventService.getEventDetail(
        url
      );
      if (code === 1) {
        dispatch(actGetEventDetail(eventDetail));
        setLoading(false);
      }
    } catch {}
  };

  async function getNotificationEvents() {
    try {
      const eventNotification = await EventService.getNotification({
        limit: 1,
      });
      const { code, data } = eventNotification;
      if (code === 1) {
        setNotiEvents(data.docs);
      }
    } catch (error) {}
  }

  // async function getNotificationList(data: any) {
  //   await OrderService.getNotificationForUser(data).then((res) => {
  //     if (res.code === 1) {
  //       setNotiOrders(res.data.docs);
  //     }
  //   });
  // }

  const getNotiNew = async () => {
    const params = {
      limit: 1,
      page: 1,
      type: JSON.stringify(["ORDER"]),
    };
    // const resOrder = await getNotificationList(params);
    const resEvent = await getNotificationEvents();
    await Promise.all([resEvent]);
  };

  // bug here
  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    getAllEvents();
    handleGetQuantity();
    getNotiNew();
    getDataPromotion();
  }, []);

  const renderHeaderHome = useCallback(() => {
    return <HeaderHome openInfo={open} closeInfo={closeInfo} />;
  }, [open]);

  const handleClickEvent = async (url: string, id: string) => {
    history.push(`/events/details/${url}?id=${id}`);
  };
  return (
    <div className="home-container">
      <ScrollToTopOnMount />
      <PromotionDetail
        onClose={() => setPopUpPromotion(false)}
        visible={popUpPromotion}
      />
      <div className="home-content">
        <Row gutter={[0, 0]}>
          <Col span={24} id="info-doctor" style={{ paddingBottom: -48 }}>
            <div
              className={classNames(
                "header d-flex justify-content-between align-items-center",
                scrollPosition >= 20 && "header-anchor"
              )}
            >
              <div className="icon_menu_home">
                <img
                  onClick={toggle}
                  className="icon-menu-home"
                  src={icon_menu_home}
                  alt="icon-menu-home"
                />
              </div>
              <div className="header__noti">
                <div className="notification-settings" onClick={toggleNoti}>
                  <Link to="/notifications">
                    <img
                      className="icon-notification-home"
                      src={icon_notification_home}
                      alt="icon-notification-home"
                    />
                  </Link>
                  {handleFilterData().filterNoti &&
                    handleFilterData().filterNoti.length > 0 && (
                      <div className="notification-numbers">
                        <span>
                          {handleFilterData().filterNoti.length > 99
                            ? "99"
                            : handleFilterData().filterNoti.length}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="header__info">
                {/* <div className="mr-3">
                  <span className="subdued-body1">Xin chào,</span>
                  <h3>{user_info?.profile?.fullName}</h3>
                </div> */}
                <Link to="/profile">
                  {user_info?.profile?.avatar !== "" && (
                    <Avatar
                      size={48}
                      src={`${HOST_URL_IMAGE}/${user_info?.profile?.avatar}`}
                    />
                  )}
                  {user_info?.profile?.avatar === "" && (
                    <Avatar size={48} style={{ backgroundColor: "#0073C4" }}>
                      {renderName(user_info?.profile?.fullName)}
                    </Avatar>
                  )}
                </Link>
              </div>
            </div>
          </Col>

          <div className="home-section">
            <Col span={24}>
              <Row gutter={[32, 0]}>
                <div style={{ width: "100%" }}>
                  {/* {dataPromotion &&
                    dataPromotion.map((data: any) => {
                      return (
                        <div
                          onClick={() => {
                            setPopUpPromotion(true);
                            getPromotionDetail(data._id);
                          }}
                          className="d-flex align-items-center"
                          style={{ padding: 16 }}
                        >
                          <div className="icon-notification-home-content-promotion">
                            <img src={img_percent}></img>
                            <span
                              style={{
                                fontFamily: "IBM Plex Sans",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#F45B19",
                              }}
                            >
                              {data.percent.toString().length == 1
                                ? "0" + data.percent
                                : data.percent}
                            </span>
                          </div>
                          <div className="content-notification-home">
                            <div
                              className="mb-2"
                              style={{
                                fontWeight: 600,
                                fontSize: 14,
                                color: "#42526E",
                              }}
                            >
                              {data.title}
                            </div>
                            <div className="content-promotion">
                              <span className="promotion-product">
                                Sản phẩm :{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {data.products.name}
                                </span>
                              </span>
                              <span className="promotion-date">
                                Hiệu lực : {moment(data.timeEnd).format("L")}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="dash"></div> */}
                  {notiEvents.length > 0 &&
                    notiEvents.map((item: any) => {
                      const { users, detail, event, createdAt, type } = item;
                      const url: any = event.url;
                      //////////// ngày tạo
                      let CreatedAt = createdAt;
                      const startDay = moment().format("DD MM YYYY");
                      const endDay = moment(createdAt).format("DD MM YYYY");
                      CreatedAt =
                        startDay == endDay
                          ? moment(createdAt).format("h:mm") + ` hôm nay`
                          : moment(createdAt).format("DD/MM/YYYY");
                      //////////// sự kiện bắt đầu lúc
                      let TimeTakesPlace = event.timeTakesPlace;
                      const startDay1 = moment().format("DD MM YYYY");
                      const endDay1 = moment(event.timeTakesPlace).format(
                        "DD MM YYYY"
                      );
                      TimeTakesPlace =
                        startDay1 == endDay1
                          ? `diễn ra lúc ` +
                            moment(event.timeTakesPlace).format("H:mm") +
                            ` hôm nay`
                          : `diễn ra lúc ` +
                            moment(event.timeTakesPlace).format(
                              "H:mm DD [thg] MM YYYY"
                            );

                      return (
                        <div
                          key={item._id}
                          className={classNames(
                            "noti__item ",
                            !users?.isSeen && "noti__notRead"
                          )}
                          onClick={() => handleClickEvent(url, event._id)}
                        >
                          <div className="d-flex align-items-center">
                            <div className="icon-notification-home-content">
                              <img src={icon_notification_home_content}></img>
                            </div>
                            <div className="content-notification-home">
                              <div
                                className="mb-2"
                                style={{
                                  fontWeight: 600,
                                  fontSize: 14,
                                }}
                              >
                                {event.title}
                              </div>
                              <div className="noti-calendar-home">
                                {type === "REMIND_EVENT" ||
                                type === "REMIND_EVENT_CRONJOB_1_DAY" ? (
                                  <>
                                    <div style={{ width: "100%" }}>
                                      <div className="noti__body mb-2">
                                        {detail.subject}
                                      </div>
                                      <div className="">
                                        <span className="noti__body">
                                          {detail.body}
                                        </span>
                                        <span className="notification-date-submitted">
                                          {CreatedAt}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="noti-calendar-home"
                                    style={{ width: "100%" }}
                                  >
                                    <span className="noti__body">
                                      {TimeTakesPlace}
                                    </span>
                                    <span className="notification-date-submitted">
                                      {CreatedAt}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Row>
            </Col>
          </div>

          <div className="home-section" style={{ marginTop: "5px" }}>
            <Col span={24} style={{ marginBottom: "10px" }}>
              <Row gutter={[32, 0]}>
                <Col span={24}>
                  <Link to={"/events"}>
                    <div className="event-section">
                      <span
                        className="ink-light-title"
                        style={{ fontSize: 20, color: " #172B4D" }}
                      >
                        Hội thảo
                      </span>
                      <span className="d-flex align-item-center">
                        <span
                          className="text-all"
                          style={{ color: "#0045C6", fontWeight: 600 }}
                        >
                          Xem tất cả
                        </span>
                        <img
                          className="icon-arrow-right"
                          src={arrow_right_blue}
                          alt="icon-right"
                        />
                      </span>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <BesinsCarousel arrows={false}>
                    {loading ||
                      (!eventId && (
                        <ClipLoader
                          loading={true}
                          size={30}
                          color={"#0073C4"}
                        />
                      ))}
                    {eventId.map((id) => {
                      const event = entitiesEvent[id];
                      return (
                        <BesinsCard
                          key={`card-${event._id}`}
                          data={event}
                          handleClick={handleOnClickCard}
                          allowRegister={false}
                        />
                      );
                    })}
                  </BesinsCarousel>
                </Col>
              </Row>
            </Col>
          </div>

          <div className="home-section" style={{ marginTop: "5px" }}>
            <Col span={24} style={{ marginBottom: "10px" }}>
              <Row gutter={[32, 0]}>
                <Col span={24}>
                  <Link to={"/news"}>
                    <div className="event-section">
                      <span
                        className="ink-light-title"
                        style={{ fontSize: 20, color: " #172B4D" }}
                      >
                        Tin tức
                      </span>
                      <span className="d-flex align-item-center">
                        <span
                          className="text-all"
                          style={{ color: "#0045C6", fontWeight: 600 }}
                        >
                          Xem tất cả
                        </span>
                        <img
                          className="icon-arrow-right"
                          src={arrow_right_blue}
                          alt="icon-right"
                        />
                      </span>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <HomeNews />
                </Col>
              </Row>
            </Col>
          </div>
          {/* <div className="home-section">
            <Col span={24} style={{ paddingBottom: 0 }}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <span className="ink-light-title">Tin tức</span>
                </Col>
                <Col span={24}>
                  
                  <div className="home-card-white">
                    <div className="home-news text-center">
                      <img src={coming_soon} alt="" className="mb-2" />
                      <div className="ink-light-title">COMING SOON</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </div> */}

          {/* <div className="home-section" style={{ padding: 0 }}>
            <Col span={24} style={{ paddingBottom: 0 }}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <div className="home_section_title">
                    Liên hệ dịch vụ nhà phân phối sản phẩm
                  </div>
                </Col>
                <Col span={24}>
                  <div className="header-oder">
                    <img src={home_header} alt="" />
                    <BesinsButton
                      onClick={() => history.push("/create-order")}
                      className="btn-order-fixed"
                    >
                      Liên hệ
                    </BesinsButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>

          <div className="home-section">
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="home_section_title">Tương tác với NPP</div>
                </Col>

                <Col span={24}>
                  <Link to="/orders">
                    <div className="home-card-white justify-content-start">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{ padding: "24px 16px" }}
                      >
                        <div style={{ marginRight: 12 }}>
                          <img src={icon_list_order} alt="" />
                        </div>
                        <div>
                          <div className="ink-light-subtitle2">
                            Tích lũy của tôi
                          </div>
                          <div className="ink-light-body1">
                            <b>{quantity_homepage?.totalOders}</b> đơn hàng
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                { user_info?.details?.isOrderable=== true && (
                <Col span={24}>
                  <Link to="/promotions">
                    <div className="home-card-white justify-content-start">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{ padding: "24px 16px" }}
                      >
                        <div style={{ marginRight: 12 }}>
                          <img src={icon_promotion} alt="" />
                        </div>
                        <div>
                          <div className="ink-light-subtitle2">
                            Chương trình ưu đãi từ NPP
                          </div>
                          <div className="ink-light-body1">
                            <b>{quantity_homepage?.totalPromotion}</b> khuyến
                            mãi{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                 )}
              </Row>
            </Col>
          </div> */}
        </Row>
      </div>
      {renderHeaderHome()}
      {/* <ConfirmationModal
        visible={modalConfirmOrder}
        cancelText="Hủy"
        confirmText="Xác nhận"
        title="Thông báo"
        content="Khách hàng liên hệ để được cung cấp các dịch vụ của nhà phân phối sản phẩm"
        cancel={() => setModalConfirmOrder(false)}
        confirm={() => history.push("/orders")}
      /> */}
    </div>
  );
};

export default WithNavigation(HomePage);
