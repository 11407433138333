import React, { useEffect, useState } from "react";
import { Collapse, Tag } from "antd";
import "./style.scss";
import { arrow_back } from "variables/image";
import BesinsButton from "components/BesinsButton";
import Spinner from "components/Spinner";
import moment, { Moment } from "moment";
import HOST_URL_IMAGE from "variables/hostUrl";
import classNames from "classnames";
import EventService from "services/event.api";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { EventItemType } from "models/event.model";

const { Panel } = Collapse;

interface Props {
  onToggle?: any;
  callBack?: any;
  activeTab?: string;
}

const EventDetail = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [eventCurrent, setEventCurrent] = useState<EventItemType>()
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
 
  const params: any = useParams();  
  
  const getEventDetail = async () => {
    try {
        const { data: eventDetail } = await EventService.getEventDetail(
        query?.get("url") ?? params.url
      );      
      setEventCurrent(eventDetail)
    } catch {}
  };
 
  function compare(dateTimeA: Moment, dateTimeB: Moment) {
    const momentA = moment(dateTimeA, "DD/MM/YYYY");
    const momentB = moment(dateTimeB, "DD/MM/YYYY");
    if (momentA > momentB) return 1;
    else if (momentA < momentB) return -1;
    else return 0;
  }

  function handleClose() {
    history.replace(`/events`) ;
  }
  function createMarkup(text: string) {
    return { __html: text };
  }

  function rawHTML(text: string) {
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }

  async function handleRegisterEvent() {
    if (eventCurrent?.isShowForm) {
      history.push(`/register-event?url=${eventCurrent.url}&id=${eventCurrent._id}`);
    } else {
      window.open(`${eventCurrent?.hyperlink}`, "_blank");
    }
  }

  useEffect(() => {
    if (query?.get("url") ?? params.url) {
      getEventDetail();
    }
  }, [params.url]);

  return (
      <div className="event-wrap">
        <div className="header">
          <img src={arrow_back} onClick={handleClose} alt="" />
          <div className="title">Thông tin hội thảo</div>
        </div>

        {!eventCurrent ? (
          <Spinner />
        ) : (
          <div className="event__content">
            <div className="event__intro pl-3 pr-3 mb-1">
              <div className="event__image">
                <img
                  src={`${HOST_URL_IMAGE}/${eventCurrent.images?.size.SIXTEEN_NINE?.url}`}
                  alt=""
                />
              </div>

              <div className="event__calendar">
                <div className="d-flex align-items-center">
                  <i className="icon icon-calendar mr-1"></i>
                  <span className="event__calendar-text">
                    {moment(eventCurrent.timeTakesPlace).format("DD [Thg] MM, YYYY")}
                  </span>
                </div>
                <Tag
                  color={eventCurrent.type === "OFFLINE" ? "processing" : "success"}
                >
                  {eventCurrent.type === "OFFLINE"
                    ? "Hội thảo gặp gỡ trực tiếp"
                    : "Hội thảo trực tuyến"}
                </Tag>
              </div>
              <div className="event__title">{eventCurrent.title}</div>
            </div>

            {eventCurrent.instruction && (
              <Collapse expandIconPosition="right" bordered={false}>
                <Panel header="CÁCH THAM DỰ HỘI THẢO" key={1}>
                  <div>{rawHTML(eventCurrent.instruction)}</div>
                </Panel>
              </Collapse>
            )}

            {eventCurrent.description && (
              <Collapse expandIconPosition="right" bordered={false}>
                <Panel header="MÔ TẢ CHUNG HỘI THẢO" key={1}>
                  <div className="event__desc pl-3 pr-3 mb-1">
                    {eventCurrent.description}
                  </div>
                </Panel>
              </Collapse>
            )}

            <Collapse
              defaultActiveKey={["1"]}
              expandIconPosition="right"
              bordered={false}
            >
              {(eventCurrent.content || []).map((item :any) => {
                if (!item.isActive && eventCurrent?.status === "NONE") return null;
                return (
                  <Panel header={item.title} key={item.id}>
                    <div>{rawHTML(item.content)}</div>
                  </Panel>
                );
              })}
            </Collapse>
            {eventCurrent?.status !== "NONE" && (
              <div className="event__after pl-3 pr-3 pb-3">
                <div className="event__after-title">
                  Tin tức, hình ảnh và tài liệu sau hội thảo
                </div>
                {eventCurrent.contentAfter ? (
                  rawHTML(eventCurrent.contentAfter)
                ) : (
                  <div className="event__after-img">
                    <img src="/image-doctor/icon/event-after.png" alt="" />
                    <span>Được cập nhập sau khi hội thảo kết thúc</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* {event?.status === "NONE" && ( */}
          <div
            className="event__button"
          >
            {compare(moment(eventCurrent?.timeTakesPlace), moment()) === -1 && (
              <BesinsButton
                size="large"
                justIcon={false}
                icon={eventCurrent?.status !== "NONE" ? "icon-check-circle" : null}
                onClick={handleRegisterEvent}
                className={classNames({
                  "button-wrap": true,
                  "registed-event": eventCurrent?.status !== "NONE" ? true : false,
                  "event-btn-detail": eventCurrent?.status !== "NONE" ? true : false,
                })}
              >
                Đăng ký nhận tài liệu
              </BesinsButton>
            )}
            {compare(moment(eventCurrent?.timeTakesPlace), moment()) !== -1 && (
              <BesinsButton
                size="large"
                justIcon={false}
                icon={eventCurrent?.status !== "NONE" ? "icon-check-circle" : null}
                onClick={handleRegisterEvent}
                className={classNames({
                  "button-wrap": true,
                  "registed-event": eventCurrent?.status !== "NONE" ? true : false,
                  "event-btn-detail": eventCurrent?.status !== "NONE" ? true : false,
                })}
              >
                Đăng ký tham dự
              </BesinsButton>
           )}
          </div>
        {/* )} */}
      </div>
  );
};

export default EventDetail;
