import HomePage from "../views/HomePage";
import OrderPage from "../views/OrderPage";
import CreateOrderPage from "../views/CreateOrderPage";
import PromotionPage from "../views/PromotionPage";
import Notifications from "views/Notifications";
import EventPage from "../views/EventPage";
import UserProfile from "views/UserProfile";
import Settings from "views/UserProfile/Settings";
import ChangePassword from "views/UserProfile/Settings/ChangPassword";
import GuideZoom from "views/GuideZoom";
import UpdateProfile from "views/UserProfile/UpdateProfile";
import RegisterEvent from "views/RegisterEvent";
import News from "views/News";
import NewsDetail from 'views/News/NewsDetail';
// import NewArticle from "views/News/NewArticle";
import NewsOther from "views/News/NewsOther";
import EventDetail from "views/EventPage/EventDetail";

const appRoutes = [
  {
    name: "Tạo đơn hàng",
    path: "/create-order",
    invisible: true,
    collapse: false,
    component: CreateOrderPage,
  },
  // {
  //   name: "Đơn hàng của tôi",
  //   icon: "icon icon-home",
  //   path: "/orders",
  //   invisible: true,
  //   collapse: false,
  //   component: OrderPage,
  // },

  {
    name: "Khuyến mãi của tôi",
    icon: "icon icon-home",
    path: "/promotions",
    invisible: false,
    collapse: true,
    component: PromotionPage,
  },
  {
    name: "Hội thảo",
    icon: "icon icon-calendar",
    path: "/events",
    invisible: false,
    collapse: false,
    exact: true,
    component: EventPage,
  },
  {
    name: "Profile",
    path: "/profile",
    invisible: false,
    collapse: false,
    component: UserProfile,
  },
  {
    name: "Update Profile",
    path: "/update-profile",
    invisible: false,
    collapse: false,
    component: UpdateProfile,
  },
  {
    name: "Settings",
    path: "/settings",
    invisible: false,
    collapse: false,
    component: Settings,
  },
  {
    name: "ChangePassword",
    path: "/change-password",
    invisible: false,
    collapse: false,
    component: ChangePassword,
  },
  {
    name: "GuideZoom",
    path: "/guide-zoom",
    invisible: false,
    collapse: false,
    component: GuideZoom,
  },
  {
    name: "Register Event",
    path: "/register-event",
    invisible: false,
    collapse: false,
    component: RegisterEvent,
  },
  {
    name: "Notifications",
    path: "/notifications",
    invisible: false,
    collapse: false,
    component: Notifications,
  },
  //Thêm trang gì phải để trên dòng này và invisible: true
  {
    name: "Trang chủ",
    icon: "icon icon-home",
    path: "/",
    invisible: false,
    collapse: false,
    exact: true,
    component: HomePage,
  },
  {
    name: "Tin tức",
    icon: "icon icon-file",
    path: "/news",
    invisible: false,
    collapse: false,
    exact: true,
    component: News,
  },
  {
    name: "Chi tiết tin tức",
    icon: "icon icon-file",
    path: "/news/details/:url",
    invisible: false,
    collapse: false,
    exact: true,
    component: NewsDetail,
  },
  {
    name: "Tin đã lưu của tôi",
    icon: "icon icon-file",
    path: "/news/saved",
    invisible: false,
    collapse: false,
    exact: true,
    component: NewsOther,
  },
  {
    name: "Chi tiết tin tức",
    icon: "icon icon-file",
    path: "/news_saved_detail/:url",
    invisible: false,
    collapse: false,
    exact: true,
    component: NewsDetail,
  },
  {
    name: "Chi tiết tin tức",
    icon: "icon icon-file",
    path: "/news_category_detail/:url",
    invisible: false,
    collapse: false,
    exact: true,
    component: NewsDetail,
  },
  {
    name: "Chi tiết hội thảo",
    icon: "icon icon-order-list",
    path: "/eventDetail",
    collapse: false,
    invisible: false,
  },
  {
    name: "Đơn hàng của tôi",
    icon: "icon icon-order-list",
    path: "/orders",
    collapse: false,
    invisible: false,
  },
  {
    name: "Danh sách khuyến mãi",
    icon: "icon icon-order-list",
    path: "/promotions",
    collapse: false,
    invisible: false,
  },

  {
    name: "Tin tức",
    icon: "icon icon-file",
    path: "/news",
    invisible: true,
    collapse: false,
    isNavigation: true,
  },
  {
    name: "Danh mục",
    icon: "icon icon-file",
    path: "/category/:url",
    invisible: true,
    collapse: false,
    isNavigation: true,
    component: NewsOther,
  },
  {
    name: "Danh mục",
    icon: "icon icon-file",
    path: "/article/:url",
    invisible: true,
    collapse: false,
    isNavigation: true,
    component: NewsOther,
  },
  {
    name: "Thông tin hội thảo",
    icon: "icon icon-file",
    path: "/events/details/:url",
    invisible: true,
    collapse: false,
    isNavigation: true,
    component: EventDetail,
  }
];

export default appRoutes;
