import React, { useRef, useState } from "react";
import { Modal, Button } from "antd";
import "./style.scss";
import { arrow_back } from "variables/image";
import BesinsInputSearch from "components/BesinsInputSearch";
import EventService from "services/event.api";
import BessinsCard from "components/BesinsCard/BesinsCardModal";

type Props = {
  visible?: boolean;
  onCancel?: any;
  image?: string;
  title?: string;
  content?: any;
  className?: string;
  text_button?: string;
  closable?: boolean;
  width?: number;
};
function InformationModal(props: Props) {
  const {
    visible,
    onCancel,
    image,
    title,
    content,
    className,
    text_button,
    closable,
    width,
  } = props;

  const tyingTimeoutRef = useRef(null);

  const [eventSearch, setEventSearch] = useState([]);

  async function onChangeSearch(e: any) {
    const params = {
      search: e.target.value,
    };
    try {
      const res = await EventService.getEventsSearch(params);

      if (res) {
        setEventSearch(res.data.docs);
      }
    } catch (error) {}

    return;
  }

  return (
    <Modal
      title={
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="icon-arrow-backModel">
              <img src={arrow_back} onClick={onCancel} />
            </div>
            <BesinsInputSearch onChange={onChangeSearch} allowClear={true} />
          </div>
        </>
      }
      visible={visible}
      className={`confirmModal ` + className}
      width={width ? width : "100%"}
      footer={null}
      centered={true}
      onCancel={onCancel}
      closable={closable}
    >
      <div className="events__section">
        <div className="events__section-content">
          {eventSearch.map((el: any) => {
            return <BessinsCard data={el} />;
          })}
        </div>
      </div>
    </Modal>
  );
}

InformationModal.propTypes = {};

export default InformationModal;
