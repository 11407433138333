import React from "react";
import Slider from "react-slick";
import "./style.scss";

interface CarouselProps {
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  children: React.ReactNode;
  arrows?: boolean;
}

export default function BesinsCarousel(props: CarouselProps) {
  const {
    speed = 300,
    slidesToShow = 1,
    slidesToScroll = 1,
    children,
    arrows,
  } = props;
  return (
    <Slider
      centerPadding="10px"
      dots
      infinite={false}
      speed={speed}
      arrows={arrows}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      className="slider-custom"
    >
      {children}
    </Slider>
  );
}
