import { Avatar } from "antd";
import LayoutApp from "layouts/LayoutApp";
import { RootState } from "models/index.model";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HOST_URL_IMAGE from "variables/hostUrl";
import "./style.scss";
import moment from "moment";
import { checkGender } from "utils/utilsCommon";
import { renderName } from "utils";

const ProfileUser = () => {
  const history = useHistory();
  const user = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );
  //console.log("ProfileUser -> user", user);

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <LayoutApp
      right="Chỉnh sửa"
      onClickRight={() => history.push("/update-profile")}
      handleGoBack={handleGoBack}
    >
      <div className="profile-user">
        <div className="profile-avatar text-center mb-2">
          {user?.profile?.avatar !== "" && (
            <Avatar
              size={68}
              src={`${HOST_URL_IMAGE}/${user?.profile?.avatar}`}
            />
          )}
          {user?.profile?.avatar === "" && (
            <Avatar size={68} style={{ backgroundColor: "#0073C4" }}>
              {renderName(user?.profile?.fullName)}
            </Avatar>
          )}
          <div className="profile-name mt-2">{user.profile?.fullName}</div>
        </div>
        <div className="profile-info">
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">Họ tên</span>
            <span className="profile-card-value">{user.profile?.fullName}</span>
          </div>
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">Ngày sinh</span>
            <span className="profile-card-value">
              {user.profile?.birthday
                ? moment(user.profile?.birthday).format("DD/MM/YYYY")
                : "--"}
            </span>
          </div>
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">Giới tính</span>
            <span className="profile-card-value">
              {checkGender(user.profile?.gender || "")}
            </span>
          </div>
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">Số điện thoại</span>
            <span className="profile-card-value">
              {user.profile?.phoneOrder || "--"}
            </span>
          </div>
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">Email</span>
            <span className="profile-card-value">{user.email || "--"}</span>
          </div>
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">BV/PK</span>
            <span className="profile-card-value">
              {user.details?.office || "--"}
            </span>
          </div>
          <div className="profile-card d-flex align-items-center justify-content-between mb-1">
            <span className="profile-card-title">Địa chỉ</span>
            <span className="profile-card-value">
              {user.details?.address || "--"}
            </span>
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};

export default ProfileUser;
