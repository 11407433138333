import Types from "../actionTypes";
import { PromotionReducer, PromotionDetail } from "../../models/promotion.model";

export const actGetPromotionList = (promotion_list: PromotionReducer) => {
  return {
    type: Types.GET_PROMOTION_LIST,
    promotion_list,
  };
};

export const actGetPromotionDetail = (promotion_detail: PromotionDetail) => {
  return {
    type: Types.GET_PROMOTION_DETAIL,
    promotion_detail,
  };
};
