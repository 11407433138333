import React, { FC, useRef, useState } from "react";
import classNames from "classnames";
import { RootState } from "models/index.model";
import { useSelector } from "react-redux";

interface ICodeInput {
  onChange: (newValue: string) => void;
}

const CodeInput: FC<ICodeInput> = ({ onChange }) => {
  const dataRegister = useSelector(
    (state: RootState) => state.authReducer.register
  );

  const [code, setCode] = useState("");
  const input = useRef<any>(null);

  const handleOnChange = (e: any) => {
    e.preventDefault();
    const { value } = e.target;
    setCode(value);
    onChange && onChange(value);
  };

  const onTouchablePress = () => {
    input.current.blur();
    input.current.focus();
  };

  function handleError(err: string) {
    switch (err) {
      case "PHONE_INVALID":
        return "Số điện thoại không hợp lệ";
      case "CODE_IS_INCORRECT":
        return "Mã xác thực không đúng !";
      default:
        return "Đã có lỗi xảy ra!";
    }
  }

  return (
    <div onClick={onTouchablePress}>
      <div className="input-wrapper">
        <input
          autoFocus
          className="input-hidden"
          ref={input}
          type="number"
          onChange={(e: any) => handleOnChange(e)}
          min={0}
          max={6}
        />
        <div className="input-wrapper">
          {[0, 1, 2, 3, 4, 5].map((number) => {
            const value = code[number];
            const active =
              number === code.length || (code.length === 6 && number === 5);
            const isError = dataRegister.error === "CODE_IS_INCORRECT";
            return (
              <div
                key={`input-code-${number}`}
                className={classNames(
                  "input-verify",
                  active ? "input-active" : null,
                  isError && code.length > 0 ? "input-error" : null
                )}
              >
                <div className="input-text">{value || ""}</div>
              </div>
            );
          })}
        </div>
      </div>

      {dataRegister.error && code.length > 0 && (
        <div className="input-error-message mt-1 text-center">
          {handleError(dataRegister.error)}
        </div>
      )}
    </div>
  );
};

export default CodeInput;
