import React, { useState, useEffect } from "react";
import "./style.scss";
import { Input, Row, Col, Avatar, DatePicker, notification } from "antd";
import BesinsInput from "../../BesinsInput";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../models/index.model";
import { actGetSetOrder } from "../../../store/actions/order.action";
import CustomSelect from "../../SelectIcon";
import OrderService from "../../../services/order.api";
import BesinsButton from "components/BesinsButton";
import SelectBesins from "components/SelectIcon";
import UserService from "services/user.api";
import { actGetDistrict, actGetProvince, actGetWard } from "store/actions/profile.action";
import moment from "moment";
import { convertText } from "utils/convertText";

// store

const { TextArea } = Input;

type Profile = {
  avatar: string;
  phone: string;
  fullName: string;
};

type Detail = {
  province: string;
  region: string;
  fullName: string;
  office: string;
  department: string;
};

type DoctorInfo = {
  profile: Profile;
  details: Detail;
  _id: string;
};

type Props = {
  setStep1Data?: any;
  doctorID?: string;
  doctorList?: Array<any>;
  handleOnChangeDoctor?: any;
  onSearchDoctor?: any;
  doctorInfo?: DoctorInfo;
  medRep?: string;
  distributor?: string;
  handleOnChangeMedRep?: any;
  handleOnChangeDistributor?: any;
  handleOnChangeNote?: any;
  step1Data?: any;
};

const Step1Info = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );
  const dateFormat = 'YYYY-MM-DD';
  const user_info = useSelector(
    (state: RootState) => state.profileReducer.user_info
  );
  const order = useSelector((state: RootState) => state?.orderReducer?.order);
  const { province, district, ward } = useSelector(
    (state: RootState) => state.profileReducer.initData
  );
  const [medRepList, setMedRepList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const getProvince = async () => {
    const res = await UserService.getProvince();
    const mapProvince = res.map((item: any) => ({
      label: item.name,
      value: item.provinceId,
    }));
    dispatch(actGetProvince(mapProvince));
  };

  const getDistrict = async (id: any) => {
    const res = await UserService.getDistrict(id);
    const mapDistrict = res.map((item: any) => ({
      label: item.name,
      value: item.districtId,
    }));
    dispatch(actGetDistrict(mapDistrict));
  };

  const getWard = async (id: any) => {
    const res = await UserService.getWard(id);
    const mapWard = res.map((item: any) => ({
      label: item.name,
      value: item.wardId,
    }));
    dispatch(actGetWard(mapWard));
  };

  useEffect(() => {
    if (user.details?.provinceCode) getDistrict(user.details?.provinceCode);
  }, [user.details?.provinceCode]);

  useEffect(() => {
    if (user.details?.districtCode) getWard(user.details?.districtCode);
  }, [user.details?.districtCode]);

  useEffect(() => {
    getProvince();
  }, []);
  useEffect(() => {
    if (user_info._id !== undefined) {
      getMedRepList(user_info._id);
      getChannelList(user_info._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_info]);

  useEffect(() => {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 0,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMedRepList = async (doctorId: any) => {
    const res = await OrderService.getMedRep(doctorId);
    try {
      if (res.code === 1) {
        const listOption = res.data.docs.map((prop: any, key: number) => {
          prop.label = prop.fullName;
          prop.value = prop._id;
          return prop;
        });
        setMedRepList(listOption);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getChannelList = async (id: any) => {
    OrderService.getChannelForCustomer(id).then((res) => {
      if (res.code === -1) {
        //console.log("Có lỗi xảy ra, không lấy được dữ liệu");
      } else {
        const listOption =
          res.data &&
          res.data.list_channel_by_user &&
          res.data.list_channel_by_user.length > 0 &&
          res.data.list_channel_by_user.map((prop: any, key: number) => {
            prop.label = prop.address;
            prop.value = prop._id;
            return prop;
          });
        setChannelList(listOption);
        dispatch(
          actGetSetOrder({
            ...order,
            buyer: user_info._id,
            buyerName: user_info?.profile?.fullName!,
            phone: user_info?.profile?.phoneOrder,
            channel: listOption[0] && listOption[0]._id,
            distributor: listOption[0] && listOption[0].distributor,
            channelId: listOption[0] && listOption[0].channelId,
            department: listOption[0] && listOption[0].department,
            office: listOption[0] && listOption[0].office,
            address: listOption[0] && listOption[0].label,
            medRep:
              listOption[0] &&
              listOption[0].medReps &&
              listOption[0].medReps[0],
          })
        );
      }
    });
  };

  const handleOnChangeMedRep = (value: any) => {
    if (value !== undefined && value !== "") {
      var medRepObj: any = medRepList.find((item: any) => item._id === value);

      dispatch(
        actGetSetOrder({
          ...order,
          medRep: value,
          medRepName: medRepObj.fullName,
        })
      );
    } else {
      dispatch(
        actGetSetOrder({
          ...order,
          medRep: undefined!,
          medRepName: undefined!,
        })
      );
    }
  };

  const handleOnChangeChannel = (value: any) => {
    if (value !== undefined && value !== "") {
      var channelDetail: any = channelList.find(
        (item: any) => item._id === value
      );

      dispatch(
        actGetSetOrder({
          ...order,
          channel: value,
          department: channelDetail.department
            ? channelDetail.department
            : undefined,
          office: channelDetail.office ? channelDetail.office : undefined,
          address: channelDetail.label ? channelDetail.label : undefined,
          medRep:
            channelDetail.medReps && channelDetail.medReps[0]
              ? channelDetail.medReps[0]
              : undefined,
        })
      );
    } else {
      dispatch(
        actGetSetOrder({
          ...order,
          channel: undefined!,
          department: undefined!,
          office: undefined!,
          medRep: undefined!,
          address: undefined!,
        })
      );
    }
  };
  async function sendContentChange(){
    const data = {
      fullName: order.buyerName,
      email: user_info.email,
      phoneOrder: order?.phone || user.profile?.phoneOrder,
      content: order.note
    };
    const dataContent = {
      type: true,
      content: data
    }
    try {
        await UserService.sendContentUpdate(dataContent);
        notification.success({
          message: "Gửi thông tin thành công !",
        });
    } catch (error) {
      notification.error({
        message: "Đã xảy ra lỗi!",
      });
    }
  }
  function goToProducts() {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 1,
      })
    );
    if(order.note && order.note.length > 0){
      sendContentChange()
    }
  }
  
  const handleOnChange = (e: any) => {
    dispatch(actGetSetOrder({ ...order, [e.target.name]: e.target.value }));
  };
  return (
    <>
    <div className="customer-info-card" style={{paddingBottom: '40px'}}>
      <h3 className="info-title">Thông tin cá nhân</h3>
      <div className="info-form">
        <Row gutter={[24, 12]} style={{ margin: 0 }}>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Họ tên</label>
             <BesinsInput
                className="input-custom"
                markup="Nhập chữ hoa, không dấu"
                placeholder="Nhập tên bác sĩ"
                value={user_info?.profile?.fullName}
                readOnly
              />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Số điện thoại</label>
              <BesinsInput
                className="input-custom"
                readOnly
                value={user.profile?.phone || user.profile?.phoneOrder}
              />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Ngày sinh</label>
          <DatePicker
              disabled={true}
              defaultValue={moment(user?.profile?.birthday,dateFormat)}
              className="input-custom"
              placeholder="Chọn ngày sinh"
            />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Giới tính</label>
              <BesinsInput
                className="input-custom"
                readOnly
                value={user_info.profile?.gender === 'MALE' ? 'Nam': 'Nữ'}
              />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Email</label>
              <BesinsInput
                className="input-custom"
                readOnly
                value={user_info.email}
              />
          </Col>
          
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Bệnh viện / Nơi công tác</label>
          <BesinsInput
                readOnly
                className="input-custom"
                placeholder=""
                value={user.details.address}
              />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
          <label className="input-label">Địa chỉ nhà hoặc phòng mạch tư</label>
              <BesinsInput
                className="input-custom"
                readOnly
                value={user.details.office}
              />
          </Col>
          <SelectBesins
          
            disabled
            className="select-address"
            label="Tỉnh / Thành phố"
            placeholder="Chọn Tỉnh / Thành phố"
            allowClear={false}
            optionFilterProp="label"
            filterOption={(input: any, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            option={province}
            value={user.details?.provinceCode}
          />
          <SelectBesins
            className="select-address"
            label="Quận / Huyện"
            placeholder="Chọn Quận / Huyện"
            allowClear={false}
            optionFilterProp="label"
            filterOption={(input: any, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            option={district}
            value={user.details?.districtCode}
            disabled
          />
          <SelectBesins
            className="select-address"
            label="Phường / Xã"
            placeholder="Chọn Phường / Xã"
            allowClear={false}
            optionFilterProp="label"
            filterOption={(input: any, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled
            option={ward}
            value={user.details?.wardCode}
          />
        </Row>
      </div>
    </div>
    <div className="customer-info-card">
      <h3 className="info-title">Thông tin giao hàng</h3>
      <div className="info-form">
        <Row gutter={[24, 12]} style={{ margin: 0 }}>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
            <label className="input-label">Khách hàng</label>
            <BesinsInput
              className="step1-input"
              value={user_info?.profile?.fullName}
              readOnly
              disabled
              size={"large"}
              placeholder="Khách hàng"
              prefix={
                <Avatar size="small" style={{ backgroundColor: "#0073C4" }}>
                  {user_info?.profile?.fullName?.[0]}
                </Avatar>
              }
            />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
            <label className="input-label">Số điện thoại</label>
            <BesinsInput
              className="step1-input"
              value={user_info?.profile?.phoneOrder}
              readOnly
              disabled
              size={"large"}
              placeholder="Số điện thoại"
            />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
            <CustomSelect
              value={order.channel}
              onChange={handleOnChangeChannel}
              label="Địa chỉ nhận hàng"
              allowClear
              showSearch
              option={channelList}
              optionFilterProp="label"
              placeholder="Địa chỉ nhận hàng"
            />
          </Col>
          {/* {order.department && ( */}
            <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
              <label className="input-label">Khoa</label>
              <BesinsInput
                className="step1-input"
                value={order.department}
                readOnly
                disabled
                size={"large"}
                placeholder=""
              />
            </Col>
          {/* )} */}
          {order.office && (
            <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
              <label className="input-label">Bệnh viện/Nơi công tác</label>
              <BesinsInput
                className="step1-input"
                value={order.office}
                readOnly
                disabled
                size={"large"}
                placeholder="Bệnh viện/Nơi công tác"
              />
            </Col>
          )}
          {order.medRep && (
            <Col span={24} style={{ paddingLeft: 0, width: "100%" }}>
              <CustomSelect
                value={order.medRep}
                onChange={handleOnChangeMedRep}
                label="Trình dược viên"
                allowClear
                withIcon
                showSearch
                option={medRepList}
                optionFilterProp="label"
                placeholder="Trình dược viên"
                disabled
              />
            </Col>
          )}

          <Col
            span={24}
            style={{
              paddingLeft: 0,
            }}
          >
            <label className="input-label">Ghi chú</label>
            <TextArea
              placeholder="Gợi ý: Thông tin giao nhận, thông tin thanh toán..."
              value={order.note}
              name="note"
              onChange={handleOnChange}
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </Col>
          <span style={{ fontSize: 14, color: "#6b778c", padding: "10px 0px" }}>
            &nbsp; Vui lòng kiểm tra tất cả thông tin trên để đảm bảo tất cả
            thông tin trên đều chính xác. Khi bấm "Đồng ý liên hệ NPP" và sử
            dụng chức năng này, điều đó có nghĩa là Quý thành viên đã đồng ý
            chịu trách nhiệm với hoạt động và tương tác của Quý thành viên khi
            liên hệ NPP
          </span>
          <Col
            span={24}
            style={{ paddingRight: 11, textAlign: "center" }}
          >
            <BesinsButton
              style={{ width: "100%" }}
              disabled={!order.channel}
              onClick={goToProducts}
            >
              Đồng ý & Liên hệ NPP
            </BesinsButton>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default Step1Info;
