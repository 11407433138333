import React from "react";
import "./style.scss";
// store
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
// import CustomButton from "../../Buttons";
import { RootState } from "../../../models/index.model";
import { actGetSetOrder } from "../../../store/actions/order.action";
import BesinsProductConfirm from "../../BesinsProductOrder/BesinsProductConfirm";

type Props = {
  changeStep?: any;
};

export default function Step3Confirm(props: Props) {
  let dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.orderReducer.order);
  //   const order_status = useSelector((state: RootState) => state.orderReducer.order_status);
  //   const changeStep = () => {
  //     if (order_status === "SUBMIT" || order_status === "BACK_ORDER"){
  //       props.changeStep(1)
  //     }else{
  //       props.changeStep(0)
  //     }
  //   }
  const cart = useSelector((state: RootState) => state.orderReducer.cart);

  function goToInfo() {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 0,
      })
    );
  }

  function goToOrder() {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 1,
      })
    );
  }
  return (
    <div className="order-confirmation">
      <div className="order-confirmation-box">
        <div className="box-title">
          <span>Thông tin nhận hàng</span>
          <span style={{ color: "#0073C4" }} onClick={goToInfo}>
            Thay đổi
          </span>
        </div>
        <Row>
          <Col lg={12} xs={24}>
            <p>Khách hàng</p>
            <input type="text" value={order.buyerName} name="name" disabled />
          </Col>
          <Col lg={12} xs={24}>
            <p>Điện thoại</p>
            <input type="text" value={order.phone} name="phone" disabled />
          </Col>
          <Col lg={24} xs={24}>
            <p>Địa chỉ</p>
            <input type="text" value={order.address} name="address" disabled />
            <hr />
          </Col>
          <Col lg={12} xs={24}>
            <p>Ghi chú</p>
            <span>{order.note}</span>
            <hr />
          </Col>
        </Row>
        {cart?.length > 0 && (
          <Row>
            <div className="order">
              <div className="order-top">
                <div className="text">
                  Đơn hàng {cart?.length > 0 && `(${cart?.length})`}
                </div>
                <div className="change" onClick={() => goToOrder()}>
                  Thay đổi
                </div>
              </div>

              <div className="order-list">
                {cart?.map((item, i) => {
                  return <BesinsProductConfirm key={i} cartItem={item} />;
                })}
              </div>
            </div>
          </Row>
        )}
      </div>
    </div>
  );
}
