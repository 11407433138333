import callApi from "../utils/callApi";
import moment from "moment";
import setParams from "../utils/setParams";

type paramsPromotion = {
  page?: number;
  limit?: number;
  products?: any;
  startDate?: any;
  endDate?: any;
  search?: string;
};

const PromotionService = {
  getListPromotion: async (data: paramsPromotion) => {
    data = {
      ...data,
      products: JSON.stringify(data.products),
      startDate: data.startDate && moment(data.startDate).format("YYYY-MM-DD"),
      endDate: data.endDate && moment(data.endDate).format("YYYY-MM-DD"),
    };
    const params = setParams(data);
    const endpoint = `cms/promotion/list_promotion_all_product?${params}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getPromotion: async (id?: string) => {
    const endpoint = `cms/promotion/get_detail_promotion/${id}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
};

export default PromotionService;
