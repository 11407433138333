import actionTypes from "../actionTypes";

const initialState = {
  user_info: {},
  doctor_info: {},
  initData: {
    province: [],
    district: [],
    ward: [],
  },
};

const profileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE:
      state = {
        ...state,
        user_info: action.user_info,
      };
      return state;
    case actionTypes.GET_DOCTOR_PROFILE:
      state = {
        ...state,
        doctor_info: action.user_info,
      };
      return state;
    case actionTypes.GET_PROVINCE:
      return {
        ...state,
        initData: {
          ...state.initData,
          province: action.payload,
        },
      };
    case actionTypes.GET_DISTRICT:
      return {
        ...state,
        initData: {
          ...state.initData,
          district: action.payload,
        },
      };
    case actionTypes.GET_WARD:
      return {
        ...state,
        initData: {
          ...state.initData,
          ward: action.payload,
        },
      };
    default:
      return state;
  }
};

export default profileReducer;
