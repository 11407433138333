export function renderName(name) {
    var str = name;
    var res = str.split(' ');
    if (res?.length >= 2) {
      return res[res.length - 1][0] + res[res.length - 2][0];
    }
    if (res?.length === 1) {
      return res[0][0];
    }

    return '';
  }