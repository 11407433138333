import actionTypes from "../actionTypes";

const initialState = {
  promotion_list: {
    docs: [],
  },
  promotion_detail: {}
};

const promotionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_PROMOTION_LIST:
      state = {
        ...state,
        promotion_list: action.promotion_list,
      };
      return state;
      case actionTypes.GET_PROMOTION_DETAIL:
        state = {
          ...state,
          promotion_detail: action.promotion_detail,
        };
        return state;
    default:
      return state;
  }
};

export default promotionReducer;
