import { NewsDetail } from './../../models/news.model';
import Types from "../actionTypes";


export const actGetSetNews = (news: NewsDetail) => {
  return {
    type: Types.GET_SET_NEWS,
    news,
  };
};
export const actResetNews = () => {
  return {
    type: Types.RESET_NEWS,
  };
};
export const actBookMark = (data: any) => {
  return {
    type: Types.GET_BOOKMARK,
    payload: {
      hot:data?.hot,
      new:data?.new
    }
  }
}
export const actStatePageNews = (state_page : string) => {
  return {
    type: Types.SET_STATE_PAGE,
    state_page
  };
};
export const actCategoryBookmark = (category_bookmark: object) => {

  return {
    type: Types.CATEGORY_BOOKMARK,
    category_bookmark
  }
}
export const actTypeArticleBookmark = (article_bookmark: object) => {
  return {
    type: Types.ARTICLE_BOOKMARK,
    article_bookmark
  }
}
export const actGetHotNews = (news_hot: any) => {
  return {
    type: Types.GET_HOT_NEWS,
    payload: news_hot
  }
}
export const actGetNewNews = (news_list: any) => {
  return {
    type: Types.GET_NEW_NEWS,
    payload: news_list
  }
}