import React, { useEffect } from "react";
//scss
import "./style.scss";
//views
import ForgotPage from "../../views/ForgotPage";

function ForgotLayout() {
  useEffect(() => {
    localStorage.removeItem("access_token");
  }, []);
  return (
    <div className="forgot-layout">
      <ForgotPage />
    </div>
  );
}

ForgotLayout.propTypes = {};

export default ForgotLayout;
