import React from "react";
import { Drawer, DatePicker, Checkbox, Avatar } from "antd";
// styles
import "./style.scss";
import BesinsButton from "../BesinsButton";

type Props = {
  visible?: boolean;
  onClose?: any;
  filter: any;
  setFilter: any;
  data: any;
  submit: any;
};

function PromotionFilter(props: Props) {
  const { onClose, visible, filter, setFilter, submit, data } = props;
  const { endDate, startDate, products } = filter;

  function onSelect(value: any) {
    if (!products?.includes(value)) {
      const filterData = {
        ...filter,
        products: [...products, value],
      };
      setFilter(filterData);
    } else {
      const filterData = {
        ...filter,
        products: products?.filter((prop: string) => prop !== value),
      };
      setFilter(filterData);
    }
  }

  function onSelectAll() {
    if (products?.length !== data?.length) {
      setFilter({ ...filter, products: data?.map((el: any) => el._id) });
    } else {
      setFilter({ ...filter, products: [] });
    }
  }

  function disabledStartDate(current: any) {
    // Can not select days before today and today
    return current && current < startDate?.endOf("day");
  }

  function disabledEndDate(current: any) {
    // Can not select days before today and today
    return current && current > endDate?.endOf("day");
  }

  return (
    <div>
      <Drawer
        placement={"left"}
        closable={false}
        onClose={onClose}
        visible={visible}
        className="promotion-filter-drawer"
      >
        <div className="promotion-filter-body">
          <div className="filter-header">
            <i
              className="icon icon-close"
              style={{ fontSize: 30, marginTop: 6 }}
              onClick={onClose}
            />
            <div className="title">Bộ lọc</div>
            <div
              className="ink-subtitle2"
              style={{ color: "#0073C4" }}
              onClick={() =>
                setFilter({
                  products: [],
                  endDate: null,
                  startDate: null,
                })
              }
            >
              Đặt lại
            </div>
          </div>
          <div className="filter-body">
            <div className="filter-time">
              <div className="w-100 mr-3">
                <div className="ink-caption1 mb-1">Ngày bắt đầu</div>
                <DatePicker
                  size="large"
                  className="w-100"
                  value={startDate}
                  placeholder="Bắt đầu"
                  format="DD/MM/YYYY"
                  allowClear={false}
                  disabledDate={disabledEndDate}
                  onChange={(value: any) =>
                    setFilter({
                      ...filter,
                      startDate: value,
                    })
                  }
                />
              </div>
              <div className="w-100">
                <div className="ink-caption1 mb-1">Ngày kết thúc</div>
                <DatePicker
                  size="large"
                  className="w-100"
                  value={endDate}
                  placeholder="Kết thúc"
                  format="DD/MM/YYYY"
                  allowClear={false}
                  disabledDate={disabledStartDate}
                  onChange={(value: any) =>
                    setFilter({
                      ...filter,
                      endDate: value,
                    })
                  }
                />
              </div>
            </div>
            <div className="filter-products">
              <hr style={{ margin: "16px 0" }} />
              <div className="ink-lighter-caption1 mb-4 px-1">Sản phẩm</div>
              <div className="d-flex align-items-center justify-content-between px-1">
                <div className="ink-body1">Tất cả sản phẩm</div>
                <div>
                  <Checkbox
                    indeterminate={
                      !!products?.length && products?.length < data?.length
                    }
                    checked={products?.length === data?.length}
                    onChange={() => onSelectAll()}
                  />
                </div>
              </div>
              <hr style={{ margin: "16px 0" }} />
              {data?.map((item: any, key: any) => {
                return (
                  <div
                    key={key}
                    className="d-flex align-items-center justify-content-between px-1 mb-3"
                  >
                    <div className="ink-body1 d-flex align-items-center">
                      <Avatar shape="square" className="mr-2" />
                      <span>{item.name}</span>
                    </div>
                    <div>
                      <Checkbox
                        value={item._id}
                        checked={products.includes(item._id)}
                        onChange={() => onSelect(item._id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="filter-footer">
            <BesinsButton className="btn-confirm-fixed" onClick={submit}>
              Áp dụng
            </BesinsButton>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default PromotionFilter;
