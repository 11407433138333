import React from "react";
import BesinsButton from "components/BesinsButton";
import BesinsInput from "components/BesinsInput";
import AuthLayout from "layouts/AuthLayout";
import { Formik } from "formik";
import * as yup from "yup";
import AuthenticationService from "services/authentication.api";
import { useDispatch } from "react-redux";
import { actCheckPhoneExist } from "store/actions/auth.action";
import "./style.scss";
import * as Yup from "yup";

import { Input } from "antd";
interface FormValues {
  phone: string;
  password: string;
  confirmpassword: string;
  email: string;
}

interface IActionForm {
  setFieldError: (field: string, errorMsg: string) => void;
  setStatus?: (status?: any) => void;
}

const EnterPhoneNumber = () => {
  const dispatch = useDispatch();

  const initialFormValues: FormValues = {
    phone: "",
    password: "",
    confirmpassword: "",
    email: "",
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const formSchema = yup.object({
    phone: yup
      .string()
      .required("số điện thoại không được để trống")
      .matches(phoneRegExp, "số điện thoại không hợp lệ"),
    password: yup
      .string()
      .required("mật khẩu không được để trống")
      .min(6, "mật khẩu tối thiểu 6 ký tự"),
    confirmpassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "không trùng mật khẩu"),
    }),

    email: yup
      .string()
      .required("email không được để trống")
      .email("vui lòng nhập đúng định dạng email"),
  });

  const onSubmit = async (
    values: FormValues,
    { setFieldError }: IActionForm
  ) => {
    try {
      const result = await AuthenticationService.checkPhoneExist({
        phone: values.phone,
      });
      if (result.code === -1) {
        setFieldError("phone", "Số điện thoại đã được đăng ký");
        return false;
      }
      const resultEmail = await AuthenticationService.checkEmail({
        email: values.email,
      });
      if (resultEmail.code === -1) {
        setFieldError("email", "email đã tồn tại");
        return false;
      }
      if (result.code == 1 && resultEmail.code == 1) {
        const data = {
          phone: values.phone,
          email: values.email,
          password: values.password,
        };
        dispatch(
          actCheckPhoneExist({
            initStep: "step2",
            data,
          })
        );
      }
    } catch (error) {}
  };
  return (
    <AuthLayout title="Đăng ký" hasDescription={false}>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <>
            <div className="form-range">
              <BesinsInput
                className="input-custom"
                placeholder="Nhập số điện thoại"
                label="Số điện thoại"
                value={values.phone}
                onChange={(e: any) => setFieldValue("phone", e.target.value)}
                onBlur={() => setFieldTouched("phone")}
              />
              {errors && touched.phone && (
                <div className="input-error-message mt-1">{errors.phone}</div>
              )}
            </div>
            <div className="form-range">
              <label className="label-input">Tạo mật khẩu</label>
              <Input.Password
                placeholder="Nhập mật khẩu"
                size="large"
                type="password"
                name="password"
                value={values.password}
                onChange={(e: any) => setFieldValue("password", e.target.value)}
                onBlur={() => setFieldTouched("password")}
              />
              {errors && touched.password && (
                <div className="input-error-message mt-1">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="form-range">
              <label className="label-input">Nhập lại mật khẩu</label>
              <Input.Password
                placeholder="Nhập lại mật khẩu"
                size="large"
                type="confirmpassword"
                name="confirmpassword"
                value={values.confirmpassword}
                onChange={(e: any) =>
                  setFieldValue("confirmpassword", e.target.value)
                }
                onBlur={() => setFieldTouched("confirmpassword")}
              />
              {errors && touched.confirmpassword && (
                <div className="input-error-message mt-1">
                  {errors.confirmpassword}
                </div>
              )}
            </div>
            <div className="form-range">
              <BesinsInput
                className="input-custom"
                placeholder="nhập Email@gmail.com"
                label="Email"
                value={values.email}
                onChange={(e: any) => setFieldValue("email", e.target.value)}
                onBlur={() => setFieldTouched("email")}
              />
              {errors && touched.email && (
                <div className="input-error-message mt-1">{errors.email}</div>
              )}
            </div>
            <BesinsButton
              block
              className="layout-auth-submit"
              onClick={handleSubmit}
            >
              Tiếp tục
            </BesinsButton>
          </>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default EnterPhoneNumber;
