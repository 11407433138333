import { schema } from "normalizr";

export const eventSchema = new schema.Entity(
  "events",
  {},
  { idAttribute: "_id" }
);

export const eventByDateSchema = new schema.Entity(
  "date",
  {},
  { idAttribute: "date" }
);
