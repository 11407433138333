import React from "react";
import LayoutApp from "layouts/LayoutApp";
import BesinsInput from "components/BesinsInput";
import BesinsButton from "components/BesinsButton";
import { Formik } from "formik";
import * as yup from "yup";
import AuthenticationService from "services/authentication.api";
import { useHistory } from "react-router-dom";
import { notification } from "antd";

interface FormValues {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const history = useHistory();
  const formSchema = yup.object({
    password: yup
      .string()
      .min(6, "Mật khẩu ít nhất 6 kí tự")
      .required("Mật khẩu không được để trống"),
    newPassword: yup
      .string()
      .min(6, "Mật khẩu ít nhất 6 kí tự")
      .required("Mật khẩu không được để trống"),
    confirmPassword: yup
      .string()
      .required("Xác nhận mật khẩu không được để trống")
      .equals([yup.ref("newPassword")], "Mật khẩu không trùng khớp"),
  });
  const initialFormValues: FormValues = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = async (values: FormValues) => {
    const data = {
      password: values.newPassword,
      oldPassword: values.password,
    };
    try {
      await AuthenticationService.changePassword(data);
      notification.success({
        message: "Thay đổi mật khẩu thành công!",
      });
      history.push("/");
    } catch (e) {
      notification.success({
        message: "Đã có lỗi xảy ra!",
      });
    }
  };

  return (
    <LayoutApp title="Đổi mật khẩu" customGoBack>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <div className="container-wrap mt-3">
            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                label="Mật khẩu hiện tại"
                placeholder="Nhập mật khẩu hiện tại"
                type="password"
                value={values.password}
                onChange={(e: any) => setFieldValue("password", e.target.value)}
                onBlur={() => setFieldTouched("password")}
              />
              {errors && touched.password && (
                <div className="input-error-message">{errors.password}</div>
              )}
            </div>
            <div className="mb-3">
              <BesinsInput
                className="input-custom mb-3"
                label="Mật khẩu mới"
                placeholder="Nhập mật khẩu mới (từ 6 kí tự trở lên)"
                type="password"
                value={values.newPassword}
                onChange={(e: any) =>
                  setFieldValue("newPassword", e.target.value)
                }
                onBlur={() => setFieldTouched("newPassword")}
              />
              {errors && touched.newPassword && (
                <div className="input-error-message">{errors.newPassword}</div>
              )}
            </div>
            <div className="mb-3">
              <BesinsInput
                className="input-custom mb-3"
                label="Xác nhận mật khẩu"
                placeholder="Nhập lại mật khẩu"
                type="password"
                value={values.confirmPassword}
                onChange={(e: any) =>
                  setFieldValue("confirmPassword", e.target.value)
                }
                onBlur={() => setFieldTouched("confirmPassword")}
              />
              {errors && touched.confirmPassword && (
                <div className="input-error-message">
                  {errors.confirmPassword}
                </div>
              )}
            </div>

            <BesinsButton block onClick={handleSubmit}>
              Lưu lại
            </BesinsButton>
          </div>
        )}
      </Formik>
    </LayoutApp>
  );
};

export default ChangePassword;
