import update from "react-addons-update";
import Types from "../actionTypes";

interface IAction {
  type: string;
  payload: any;
}

const initialState = {
  events: {
    events: {},
    entitiesEvent: {},
    eventId: [],
  },
  eventsUpComing: {
    events: {},
    entitiesEvent: {},
    eventId: [],
  },
  eventsByDate: {},
  eventsNotification: {},
};

const eventReducer = (state = initialState, { type, payload }: IAction) => {
  switch (type) {
    case Types.GET_ALL_EVENT_PIN:
      return update(state, {
        events: { $set: payload },
      });
    case Types.GET_EVENT_BY_PAGE:
      return update(state, {
        events: {
          events: { $set: payload.events },
          eventId: { $push: payload.eventId },
          entitiesEvent: { $merge: payload.entitiesEvent },
        },
      });
    case Types.GET_EVENT_UP_COMING_BY_PAGE:
      return update(state, {
        eventsUpComing: {
          events: { $set: payload.events },
          eventId: { $push: payload.eventId },
          entitiesEvent: { $merge: payload.entitiesEvent },
        },
      });
    case Types.GET_ALL_EVENT:
      return update(state, {
        events: { $set: payload },
      });
    case Types.GET_ALL_EVENT_UPCOMING:
      return update(state, {
        eventsUpComing: { $set: payload },
      });
    case Types.GET_EVENT_DETAIL:
      return update(state, {
        events: {
          entitiesEvent: {
            [payload._id]: {
              $set: payload,
            },
          },
        },
      });
    case Types.REGISTER_EVENT: {
      if (!(payload in state.eventsUpComing.entitiesEvent)) {
        return update(state, {
          events: {
            entitiesEvent: {
              [payload]: {
                status: { $set: "CONFIRMATION" },
              },
            },
          },
        });
      }
      return update(state, {
        events: {
          entitiesEvent: {
            [payload]: {
              status: { $set: "CONFIRMATION" },
            },
          },
        },
        eventsUpComing: {
          entitiesEvent: {
            [payload]: {
              status: { $set: "CONFIRMATION" },
            },
          },
        },
      });
    }
    case Types.REGISTER_EVENT_SUCCESS:
      return update(state, {});

    case Types.GET_EVENTS_BY_DATE:
      return update(state, {
        eventsByDate: {
          $set: payload,
        },
      });
    case Types.GET_NOTIFICATION_EVENTS:
      return update(state, {
        eventsNotification: { $set: payload },
      });

    default:
      return state;
  }
};

export default eventReducer;
