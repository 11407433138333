import React, { useEffect } from "react";
import "./style.scss";
import LoginPage from "../../views/LoginPage";

type Props = {
  children?: any;
};

const LoginLayout = (props: Props) => {
  useEffect(() => {
    localStorage.removeItem("access_token");
  },[])

  return (
    <div className="login-layout">
      <LoginPage />
    </div>
  );
};

export default LoginLayout;
