import React, { useState, useEffect } from "react";
//images
// import { logo } from "../../variables/image";
//scss
import "./style.scss";
import { Button, Row, Col, Input } from "antd";
import OTPInput from "../../components/BesinsOtpInput";
import AuthenticationService from "../../services/authentication.api";
import { Link } from "react-router-dom";
import ChangePassword from "../../components/Form/ChangePassword";
import logo from "../../assets/images/besins-logo.svg";

function RegisterPage() {
  let interval: any = null;
  //props
  const [seconds, setSeconds] = useState(60);
  const [isSent, setSend] = useState(false);
  // const [errOtp] = useState("");
  // const [currentPhone, setCurrentPhone] = useState("");
  const [otpValues, setOTPValues] = useState(Array<string>(6).fill(""));
  // const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isVisibleChangePwd, setIsVisibleChangePwd] = useState(false);
  const [errors, setErrors] = useState({
    phone: "",
    // otp: "",
    //currentPhone: "",
  });
  const [phone, setPhone] = useState("");

  async function handleChangeOTP(value: any) {
    const errors = validateForm({});
    setErrors(errors);
    // setOtp(value);
    setMessage("");
  }

  // async function getOtp() {
  //   let data = {
  //     phone: phone,
  //   };
  //   // setSend(true);

  //   try {
  //     await AuthenticationService.getOTP(data).then((res) => {
  //       if (res.code === 1) {
  //         setSend(true);
  //       }
  //     });
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // }

  // async function sendOtp() {
  //   const errors = validateForm({ phone });
  //   let data = {
  //     phone: phone,
  //   };
  //   setCurrentPhone(phone);
  //   setErrors(errors);
  //   setMessage("");
  //   setOTPValues(Array<string>(6).fill(""));
  //   setOtp("");
  //   if (!Object.keys(errors).length) {
  //     try {
  //       await AuthenticationService.checkPhone(data).then((res) => {
  //         if (res.code === 1) {
  //           if (res.data.isVerify === false) {
  //             setIsActive(true);
  //             getOtp();
  //           } else {
  //             setMessage("PHONE_IS_VERIFY");
  //           }
  //         } else {
  //           setIsActive(false);
  //           setMessage("PHONE_DOES_NOT_EXIST");
  //         }
  //       });
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   }
  // }

  async function changePassword() {
    const errors = validateForm({ phone });
    setErrors(errors);
    setMessage("");
    let data = {
      phone: phone,
      // code: otp,
    };
    try {
      await AuthenticationService.checkPhone(data).then((res) => {
        //console.log(res);
        if (res.code === 1) {
          if (res.data.isVerify === false) {
            setIsActive(true);
            setIsVisibleChangePwd(true);
          } else {
            setIsVisibleChangePwd(false);
            setMessage("PHONE_IS_VERIFY");
          }
        } else {
          setIsActive(false);
          setIsVisibleChangePwd(false);
          setMessage("PHONE_DOES_NOT_EXIST");
        }
      });
    } catch (error) {
      //console.log(error);
    }

  }

  function validateForm(values: any) {
    let errors: any = {};

    const regexp = /^\d{10,11}$/;
    if (values.phone !== undefined) {
      const checkingResult = regexp.exec(values.phone);
      if (!values.phone) errors.phone = "Vui lòng nhập số điện thoại!";
      else if (checkingResult === null)
        errors.phone = "Số điện thoại phải có 10 - 11 chữ số.";
    }
    return errors;
  }

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const phone: any = url.searchParams.get("phone");

    setPhone(phone);
    if (isActive) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setSeconds(60);
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, phone]);
  return (
    <div className="register-container">
      <div className="body">
        <div className="register-logo">
          <img src={logo} alt="logo_besins" />
        </div>
        <div className="register-form">
          {isVisibleChangePwd && (
            <ChangePassword
              phone={phone}
              title={"Tạo mật khẩu"}
              content={"Tạo mật khẩu thành công!"}
              text_button={"Tạo mật khẩu"}
            />
          )}

          {!isVisibleChangePwd && (
            <>
              <h2 className="register-title">Xác minh tài khoản</h2>
              <div className="register-form__top">
                <label className="title">Số điện thoại</label>
                <Row gutter={[12, 0]}>
                  <Col span={24}>
                    <Input
                      placeholder="Nhập số điện thoại"
                      size="large"
                      onChange={(e) => {
                        setMessage("");
                        const errors = validateForm({ phone: e.target.value });
                        setErrors(errors);
                        setPhone(e.target.value);
                      }}
                      name="phone"
                      disabled={true}
                      value={phone}
                      type="tel"
                    />
                    {errors.phone !== "" && (
                      <div className="input-error-message">{errors.phone}</div>
                    )}
                    {/* {errors.currentPhone !== "" && (
                      <div className="input-error-message">
                        {errors.currentPhone}
                      </div>
                    )} */}

                    {message === "PHONE_DOES_NOT_EXIST" && (
                      <div className="input-error-message">
                        Số điện thoại không tồn tại
                      </div>
                    )}
                    {message === "PHONE_IS_VERIFY" && (
                      <div className="input-error-message">
                        Số điện thoại đã đăng ký
                      </div>
                    )}
                  </Col>
                  {/* <Col span={8}>
                    <Button
                      type="primary"
                      size="large"
                      className="otp-button"
                      disabled={isActive ? true : false}
                      onClick={sendOtp}
                    >
                      {isActive ? (
                        <span className="disabled">Gửi lại ({seconds}s)</span>
                      ) : (
                        "Gửi OTP"
                      )}
                    </Button>
                  </Col> */}
                </Row>
              </div>
              {/* <div className="register-form__bottom">
                <label className="title">Nhập OTP</label>

                {isSent && (
                  <OTPInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    isNumberInput
                    autoFocus
                    error={errOtp !== ""}
                    errorText={errOtp}
                    value={otp}
                    otpValues={otpValues}
                    setOTPValues={setOTPValues}
                    onChangeOTP={handleChangeOTP}
                    //   disabled={!isSent}
                  />
                )}
                {!isSent && (
                  <OTPInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    isNumberInput
                    autoFocus
                    error={errOtp !== ""}
                    errorText={errOtp}
                    value={otp}
                    otpValues={otpValues}
                    setOTPValues={setOTPValues}
                    onChangeOTP={handleChangeOTP}
                    disabled
                  />
                )}
                {errors.otp !== "" && (
                  <div className="input-error-message">{errors.otp}</div>
                )}
                {message === "CODE_IS_INCORRECT" && (
                  <div
                    className="input-error-message"
                    style={{ marginTop: 10 }}
                  >
                    Mã OTP không hợp lệ!
                  </div>
                )}
              </div> */}
              <div className="btn-register-form" style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="register-form-button"
                  onClick={changePassword}
                >
                  Xác minh tài khoản
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

RegisterPage.propTypes = {};

export default RegisterPage;
