
import callApi from "../utils/callApi";
import queryString from "query-string";

 const NewServices = {
  getNews: async (query: any) => {
    const endpoint = `article/list-of-client-pin?${queryString.stringify(query)}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
   getNewsSearch: async (params: any) => {
    const endpoint = `news/search?search=${params.search}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getListNew: async (params: any) => {
    const endpoint = `article/filter?limit=${params.limit}&typeArticle=${params.typeArticle}&startDate=${params.startDate}&endDate=${params.endDate}&subCategoryId=${params.subCategoryId}&page=${params.page}&search=${params.search}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getTypeArticle: async (query: any) => {
    const endpoint = `type-article/list?${queryString.stringify(query)}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getListArticleFilter: async (params: any) => {
    const endpoint = `article/filter?typeArticle=${params.typeArticle}&subCategoryId=[${params.subCategoryId}]`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getFieldTreatment: async (query: any) => {
    const endpoint = `article/category-client?${queryString.stringify(query)}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
 getNewsDetail: async (url: any) => {
    const endpoint = `article/detail-of-client/${url}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getRelatedList: async (id: any) => {
    const endpoint = `article/list-concern-of-client/${id}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  
 getBookMark: async (id : any) =>{
  const endpoint = `my-article/bookmark`;
  const res = await callApi(endpoint, "POST", id);
  return res;
 },
 getListNewsOther: async (page:number,limit:number=10) => {
  const endpoint = `my-article/list?page=${page}&limit=${limit}`;
  const res = await callApi(endpoint, "GET");
  return res.data;
},
}


export default NewServices;
