import React from "react";
import BesinsCarousel from "components/BesinsCarousel";
import OutstandingCard from "./OutstandingCard";
interface CardNews {
  data?: any;
}

const CardNews = (props: CardNews) => {
  const { data } = props;
  return (
    <div className="home-section" style={{ marginBottom: -10 }}>
      <BesinsCarousel arrows={false}>
        {data?.map((el: any) => {
          return <OutstandingCard data={el} />;
        })}
      </BesinsCarousel>
    </div>
  );
};

export default React.memo(CardNews);
