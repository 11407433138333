import React, { useState } from "react";
import { Card } from "antd";
// import NewsImg from "../../../../../src/assets/images/news.png";
import Paragraph from "antd/lib/typography/Paragraph";
import HOST_URL_IMAGE from "variables/hostUrl";
import { bookmark_click_news_icon, bookmark_news_icon } from "variables/image";
import { useHistory } from "react-router-dom";

// Style
import "./style.scss";
import NewServices from "services/news.api";
import { useDispatch, useSelector } from "react-redux";
import { actBookMark } from "store/actions/news.action";
import { RootState } from "models/index.model";
interface CardsProps {
  data?: any;
}

const Cards = (props: CardsProps) => {
  const history = useHistory();
  const { data } = props;

  // const id = query.get("id") as string;
  function handleClick() {
    // history.push(`/newsdetail/${data.url}`);
    history.push({
      pathname: `/news/details/${data.url}`,
      search: `id=${data._id}`,
    });
  }
  const dispatch = useDispatch();
  const news_list_hots = useSelector((state: RootState) => state?.newsReducer.news_list?.news_list_hots);
  const news_list_new = useSelector((state: RootState) => state?.newsReducer.news_list?.news_list_new);

  const Bookmark_Click = async () => {
    const arrHotRemove = news_list_hots.filter((item:any) => item._id !== data?._id);
    const arrNewRemove = news_list_new.filter((item:any) => item._id !== data?._id);
    const hotnew = news_list_hots.find((item:any) => item._id === data?._id)
    const index_hotnew = news_list_hots.findIndex((item:any) => item._id === data?._id)
    const newnew = news_list_new.find((item:any) => item._id === data?._id)
    const index_newnew = news_list_new.findIndex((item:any) => item._id === data?._id)
    const dataDispatch = {
      new:arrNewRemove,
      hot:arrHotRemove
    }    
    const param = {
      articleId: data?._id,
      isBookmark: false,
    };
    try {
      const res = await NewServices.getBookMark(param);
      if (res?.data === "BOOK_MARK_SUCCESS") {
        if(index_hotnew < news_list_hots.length){
        arrHotRemove.splice(index_hotnew, 0, {...hotnew, isBookmark:true});
        }
        if(index_newnew !== -1){
        arrNewRemove.splice(index_newnew, 0, {...newnew, isBookmark:true});
        }
        dispatch(actBookMark(dataDispatch))
      } 
      if(res?.data === "UNBOOK_MARK_SUCCESS"){
        if(index_hotnew < news_list_hots.length){
          arrHotRemove.splice(index_hotnew, 0, {...hotnew, isBookmark:false});
          }
          if(index_newnew !== -1){
          arrNewRemove.splice(index_newnew, 0, {...newnew, isBookmark:false});
          }
        dispatch(actBookMark(dataDispatch))
      }
    } catch (error) {}
  };
  return (
    <Card
      hoverable
      cover={
        <img
          alt="example"
          src={`${HOST_URL_IMAGE}/${data.images?.size.SIXTEEN_NINE?.url}`}
          onClick={handleClick}
        />
      }
    >
      <div className="icon-storage-news" onClick={Bookmark_Click}>
        <img
          src={data?.isBookmark ? bookmark_click_news_icon : bookmark_news_icon}
          alt="icon-storage-news"
        />
      </div>
      <div className="card-wrap" onClick={handleClick}>
        <div className="card-wrap-content">
          <div className="tilte mb-1">
            <Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              className="card__desc"
            >
              {data?.title}
            </Paragraph>
          </div>
          <div className="tilte mb-2">
            <Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              className="card__title"
            >
              {data?.description}
            </Paragraph>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(Cards);
