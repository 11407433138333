import React, { useEffect } from "react";
import Slider from "react-slick";
import "./style.scss";

type Props = {
  step?: number;
  defaultActivekey?: number;
};

function BesinsSteps(props: Props) {
  const { step, defaultActivekey } = props;
  var refCurrent: any;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: defaultActivekey,
    swipe: true,
    variableWidth: true,
    touchMove: false,
  };

  useEffect(() => {
    refCurrent.slickGoTo(step);
  }, [refCurrent, step]);

  return (
    <div>
      <Slider
        ref={(slider) => (refCurrent = slider)}
        {...settings}
        className="steps-besins"
      >
        <div className="d-flex align-items-center" style={{ width: 135 }}>
          {step === 0 ? (
            <i
              style={{ color: "#0073C4" }}
              className="icon icon-check-circle mr-2"
            />
          ) : (
            <i className="icon-step-unactive mr-2" />
          )}

          <span
            className={
              step === 0 ? "ink-light-caption1" : "ink-lightest-caption2"
            }
          >
            Xác nhận thông tin
          </span>
        </div>
        <div
          className="d-flex align-items-center step-center"
          style={{ width: 165 }}
        >
          {step === 1 ? (
            <i
              style={{ color: "#0073C4" }}
              className="icon icon-check-circle mr-2"
            />
          ) : (
            <i className="icon-step-unactive mr-2" />
          )}
          <span
            className={
              step === 1 ? "ink-light-caption1" : "ink-lightest-caption2"
            }
          >
            Chọn sản phẩm
          </span>
        </div>
        <div className="d-flex align-items-center" style={{ width: 135 }}>
          {step === 2 ? (
            <i
              style={{ color: "#0073C4" }}
              className="icon icon-check-circle mr-2"
            />
          ) : (
            <i className="icon-step-unactive mr-2" />
          )}
          <span
            className={
              step === 2 ? "ink-light-caption1" : "ink-lightest-caption2"
            }
          >
            Xác nhận đơn hàng
          </span>
        </div>
      </Slider>
    </div>
  );
}

export default BesinsSteps;
