import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { PulseLoader } from "react-spinners";
// antd components
import { Button, Tooltip } from "antd";
// style css
import "./style.scss";

export type ButtonType = "primary" | "ghost" | "dashed" | "link" | "default";
export type ButtonShape = "circle" | "round";
export type ButtonSize = "small" | "middle" | "large";
export type HtmlType = "button" | "submit";

type ButtonProps = {
  type: ButtonType;
  htmlType?: HtmlType;
  onClick?: React.FormEventHandler<any>;
  onChange?: React.FormEventHandler<any>;
  size: ButtonSize;
  shape?: ButtonShape;
  href?: string;
  target?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  icon?: string | null;
  justIcon?: boolean;
  ghost?: boolean;
  children?: any;
  className: string;
  disabled?: boolean;
  tooltip?: string;
  block?: boolean;
};

BesinsButton.defaultProps = {
  htmlType: "button",
  size: "large",
  className: "",
  type: "primary",
  children: "Besins button",
  justIcon: false,
};

export default function BesinsButton(props: ButtonProps) {
  const {
    type,
    shape,
    loading,
    size,
    className,
    htmlType,
    children,
    icon,
    ghost,
    onClick,
    onChange,
    disabled,
    href,
    style,
    target,
    justIcon,
    tooltip,
    block,
  } = props;

  const renderIcon = (size: string) => {
    switch (size) {
      case "small":
        return <i className={`icon ${icon} icon-small`}></i>;
      case "default":
        return <i className={`icon ${icon} icon-default`}></i>;
      case "large":
        return <i className={`icon ${icon} icon-large`}></i>;
      default:
    }
    return null;
  };

  const btnClasses = classNames({
    "besins-btn": true,
    "nonanimating-btn": true,
    "btn-just-icon": justIcon,
    [className]: className,
  });

  return (
    <>
      {tooltip ? (
        <Tooltip placement="bottom" title={tooltip}>
          <Button
            block={block}
            onClick={onClick}
            onChange={onChange}
            href={href}
            shape={shape}
            target={target}
            htmlType={htmlType}
            type={type}
            size={size}
            style={style}
            ghost={ghost}
            disabled={disabled}
            className={btnClasses}
          >
            {loading ? (
              <PulseLoader
                loading={true}
                size={9}
                color={loading && disabled ? "#969696" : "white"}
              />
            ) : (
              <div className="btn-label" data-testid="btn-label">
                {icon ? renderIcon(size) : null} {justIcon ? null : children}
              </div>
            )}
          </Button>
        </Tooltip>
      ) : (
        <Button
          block={block}
          onClick={onClick}
          onChange={onChange}
          href={href}
          shape={shape}
          target={target}
          htmlType={htmlType}
          type={type}
          size={size}
          style={style}
          ghost={ghost}
          disabled={disabled}
          className={btnClasses}
        >
          {loading ? (
            <PulseLoader
              loading={true}
              size={9}
              color={loading && disabled ? "#969696" : "white"}
            />
          ) : (
            <div className="btn-label" data-testid="btn-label">
              {icon ? renderIcon(size) : null} {justIcon ? null : children}
            </div>
          )}
        </Button>
      )}
    </>
  );
}
