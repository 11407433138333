import { RootState } from "models/index.model";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ConfirmCode from "./ConfirmCode";
import EnterPhoneNumber from "./EnterPhoneNumber";
import CreateInfo from "./CreateInfo";
import "./style.scss";

const Register = () => {
  const { initStep } = useSelector(
    (state: RootState) => state.authReducer.register
  );

  return (
    <>
      {initStep === "step1" ? (
        <EnterPhoneNumber />
      ) : initStep === "step2" ? (
        <CreateInfo />
      ) : initStep === "step3" ? (
        <ConfirmCode />
      ) : (
        ""
      )}
    </>
  );
};

export default Register;
