import Types from "../actionTypes";
import { EventSchema } from "models/event.model";

export const actGetEventAll = (data: EventSchema) => {
  return {
    type: Types.GET_ALL_EVENT,
    payload: data,
  };
};

export const actLoadMoreEvents = (data: EventSchema) => {
  return {
    type: Types.GET_EVENT_BY_PAGE,
    payload: data,
  };
};

export const actLoadMoreEventsUpComing = (data: EventSchema) => {
  return {
    type: Types.GET_EVENT_UP_COMING_BY_PAGE,
    payload: data,
  };
};

export const actGetEventUpComing = (data: EventSchema) => {
  return {
    type: Types.GET_ALL_EVENT_UPCOMING,
    payload: data,
  };
};

export const actGetEventAllPin = (data: EventSchema) => {
  return {
    type: Types.GET_ALL_EVENT_PIN,
    payload: data,
  };
};

export const actRegisterEvent = (id: string) => {
  return {
    type: Types.REGISTER_EVENT,
    payload: id,
  };
};

export const actGetEventDetail = (data: any) => {
  console.log(data)
  return {
    type: Types.GET_EVENT_DETAIL,
    payload: data,
  };
};

export const actGetEventByDate = (data: any) => {
  return {
    type: Types.GET_EVENTS_BY_DATE,
    payload: data,
  };
};

export const actGetNotificationEvents = (data: any) => {
  return {
    type: Types.GET_NOTIFICATION_EVENTS,
    payload: data,
  };
};

export const actRegisterEventSuccess = (id: string) => {
  return {
    type: Types.REGISTER_EVENT_SUCCESS,
    payload: id,
  };
};
