import React from "react";
import { Card, Tag } from "antd";
import BesinsButton from "../../BesinsButton";
import { EventItemType } from "models/event.model";
import HOST_URL_IMAGE from "variables/hostUrl";
import moment, { Moment } from "moment";

import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";

interface CardProps {
  handleClick?: any;
  data: EventItemType;
  allowRegister?: boolean;
}

const BesinsCard = (props: CardProps) => {
  const { data, handleClick, allowRegister = true } = props;
  const history = useHistory();

  async function handleRegisterEvent(data: any) {
    if (data.status == "CONFIRMATION") {
      handlClickCard();
      return;
    }
    if (data.isShowForm == true) {
      history.push(`/register-event?url=${data.url}&id=${data._id}`);
      return;
    }
    window.open(`${data.hyperlink}`);
  }

  function compare(dateTimeA: Moment, dateTimeB: Moment) {
    const momentA = moment(dateTimeA, "DD/MM/YYYY");
    const momentB = moment(dateTimeB, "DD/MM/YYYY");
    if (momentA > momentB) return "Đăng ký tham dự";
    if (momentA < momentB) return "Form đăng ký nhận tài liệu";
  }

  function checkTypeEvent(type: string, dateTimeA: Moment, dateTimeB: Moment) {
    const dayStart5 = moment(dateTimeA).add(5, "days");
    const momentNow = moment(dateTimeB, "DD/MM/YYYY");
    if (dayStart5 < momentNow) return "Bấm vào để xem tài liệu";
    switch (type) {
      case "DOCUMENT_RECEVIVED":
        return "Đã đăng ký nhận tài liệu";
      case "ATTEND_THE_EVENT":
        const momentA = moment(dateTimeA, "DD/MM/YYYY").format("L");
        const momentB = moment(dateTimeB, "DD/MM/YYYY").format("L");
        if (momentA == momentB) return "Bấm vào để tham dự";
        return "Đã đăng ký tham dự";
      default:
        return "Đã đăng ký";
    }
  }
  function handlClickCard() {
    history.push(`/events/details/${data.url}?id=${data._id}`);
    window.location.reload(true);
  }

  return (
    <Card
      hoverable
      cover={
        <img
          onClick={handlClickCard}
          alt="example"
          src={`${HOST_URL_IMAGE}/${data.images?.size.SIXTEEN_NINE?.url}`}
        />
      }
    >
      <div className="card-wrap">
        <div className="card__content pb-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="card__calendar">
              <i className="icon icon-calendar" />
              <span className="ml-1">
                {moment(data.timeTakesPlace).format("DD [Thg] MM, YYYY")}
              </span>
            </div>
            <Tag color={data.type === "OFFLINE" ? "processing" : "success"}>
              {data.type === "OFFLINE"
                ? "Hội thảo gặp gỡ trực tiếp"
                : "Hội thảo trực tuyến"}
            </Tag>
          </div>
          <span className="card__title">{data.title}</span>
        </div>
        {allowRegister ? (
          <BesinsButton
            size="large"
            ghost
            justIcon={false}
            icon={data.status !== "NONE" ? "icon-check-circle" : null}
            onClick={() => handleRegisterEvent(data)}
            className={classNames({
              "button-wrap": true,
              "registed-event": data.status !== "NONE" ? true : false,
            })}
          >
            {data.status === "NONE"
              ? compare(moment(data?.timeTakesPlace), moment())
              : checkTypeEvent(
                  data?.eventReg?.type,
                  moment(data?.timeTakesPlace),
                  moment()
                )}
          </BesinsButton>
        ) : (
          <BesinsButton
            size="large"
            ghost
            justIcon={false}
            onClick={() => handleClick(data.url, data._id)}
            className={classNames({
              "button-wrap": true,
            })}
          >
            <span style={{ color: "#0045C6" }}>Xem thông tin chi tiết</span>
          </BesinsButton>
        )}
      </div>
    </Card>
  );
};

export default React.memo(BesinsCard);
