import types from "../actionTypes";

const initialState = {
  token: "",
  isLogin: false,
  isLoginMobile: false,
};

const tokenReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_TOKEN:
      state = {
        ...state,
        token: action.token,
        isLogin: true,
      };
      return state;

    case types.SET_TOKEN_FOR_MOBILE:
      state = {
        ...state,
        token: action.token,
        isLogin: true,
        isLoginMobile: action.isMobile,
      };
      return state;

    default:
      return state;
  }
};

export default tokenReducer;
