import React, { useEffect, useState } from "react";
import HOST_URL_IMAGE from "variables/hostUrl";
import { Card, Tag } from "antd";
import { icon_clock_news, bookmark_click, bookmark } from "variables/image";
import NewServices from "services/news.api";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
// reduce
import Paragraph from "antd/lib/typography/Paragraph";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  actCategoryBookmark,
  actGetNewNews,
  actStatePageNews,
} from "store/actions/news.action";
import WithNavigation from "layouts/WithNavigation";
interface NewsDetail {}

const NewsDetail = (props: NewsDetail) => {
  const history = useHistory();
  const [relatedList, setReLatedList] = useState([]);
  const [onScroll, setOnScroll] = useState(false);
  const [newDetail, setNewDetail] = useState({
    title: "",
    images: {
      size: {
        SIXTEEN_NINE: {
          url: "",
        },
      },
    },
    url: "",
    createdAt: "",
    content: "",
    description: "",
    categoryId: {
      title: "",
    },
    subCategoryId: [
      {
        title: "",
        _id: "",
      },
    ],
    typeArticle: {
      title: "",
      _id: "",
    },
    isBookmark: false,
  });

  const params: any = useParams();

  const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const id = query.get("id") as string;
  const dispatch = useDispatch();
  // getdata
  async function getDataNewDetails() {
    try {
      const res = await NewServices.getNewsDetail(params.url);
      setNewDetail(res);
    } catch (error) {}
  }

  async function getRelatedNews() {
    try {
      const res = await NewServices.getRelatedList(id);
      if (res) {
        setReLatedList(res);
      }
    } catch (error) {}
  }
  // bookmark
  async function onBookMarkDetail() {
    const params = {
      articleId: id,
    };
    await NewServices.getBookMark(params);
    getDataNewDetails();
  }

  async function onBookMarkRelared(el: any) {
    const paramss = {
      articleId: el?._id,
    };
    const res = await NewServices.getBookMark(paramss);

    const arr: any = relatedList.map((item: any) => {
      let itemTemp = item;
      if (el._id == item._id) {
        itemTemp.isBookmark = !item.isBookmark;
      }
      return itemTemp;
    });
    setReLatedList(arr);
  }

  function createMarkup(text: string) {
    return { __html: text };
  }

  function rawHTML(text: string) {
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }
  const onClickCategory = () => {
    const category = {
      title: newDetail?.subCategoryId[0]?.title,
      _id: newDetail?.subCategoryId[0]?._id,
    };
    dispatch(actCategoryBookmark(category));
    dispatch(actStatePageNews("category"));
  };

  /// useEffect
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [onScroll]);

  useEffect(() => {
    getDataNewDetails();
  }, [params]);

  useEffect(() => {
    setReLatedList(relatedList);
  }, [relatedList]);

  useEffect(() => {
    getRelatedNews();
  }, [id]);
  useEffect(() => {
    dispatch(actGetNewNews([]))
  },[])
  return (
    <>
      <header className="">
        <div className="sider-header">
          <div className="menu-toggler">
            <div className="flex-between menu-news">
              <i
                className="icon icon-arrow-back"
                style={{ color: "#6b778c" }}
                onClick={() => history.replace("/news")}
              />
              <div className="menu-views__filter" aria-hidden="true">
                <span className="subtitle-2"></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="event__content_news" style={{ paddingBottom: 55 }}>
        <div className="event__intro pl-3 pr-3 mb-1">
          <div className="event__image">
            <img
              src={`${HOST_URL_IMAGE}/${newDetail?.images?.size?.SIXTEEN_NINE?.url}`}
              alt=""
            />
          </div>
          <div className="event__calendar">
            <div className="">
              <img src={icon_clock_news} alt="icon-clock-news" />
              <span className="event__calendar-text ml-2">
                {moment(newDetail?.createdAt).format("DD [Thg] MM, YYYY")}
              </span>
            </div>
            <div onClick={onBookMarkDetail}>
              <img
                src={newDetail?.isBookmark ? bookmark_click : bookmark}
                alt=""
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="news__title mr-1">{newDetail?.categoryId?.title}</div>
            <RightOutlined
              style={{ fontSize: 14, color: "#0045C6", fontWeight: 600 }}
            />
            <Link
              to={{
                pathname: `/${"category"}/${
                  newDetail?.subCategoryId[0]?.title
                }`,
                search: `id=${newDetail?.subCategoryId[0]?._id}`,
              }}
              onClick={onClickCategory}
            >
              <div className="news__title ml-1" style={{ cursor: "pointer" }}>
                {newDetail?.subCategoryId[0]?.title}
              </div>
            </Link>
          </div>
          <div
            className="event__title"
            style={{ fontSize: 18, wordBreak: "break-word" }}
          >
            {newDetail?.title}
          </div>
          <Link
            to={{
              pathname: `/${"article"}/${newDetail?.typeArticle?.title}`,
              search: `id=${newDetail?.typeArticle?._id}`,
            }}
            // onClick={() => dispatch(actStatePageNews("article"))}
          >
            <div
              className="event__title"
              style={{ fontSize: 14, color: "#6B778C", fontWeight: 600 }}
            >
              <li>{newDetail?.typeArticle?.title}</li>
            </div>
          </Link>
          <div
            className="event__title"
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#42526E",
              wordBreak: "break-word",
            }}
          >
            {newDetail?.description}
          </div>
          <div className="event___content_news">
            {rawHTML(newDetail?.content)}
          </div>

          <div className="related-articles">
            <div
              className="articles__title"
              style={{ fontSize: 18, fontWeight: 600 }}
            >
              {relatedList.length > 0 ? "Bài viết liên quan" : ""}
            </div>
            {relatedList.map((el: any) => {
              return (
                <>
                  <Card className="latestCard-container">
                    <div className="latestCard-content">
                      <img
                        src={`${HOST_URL_IMAGE}/${el.images?.size.ONE_ONE?.url}`}
                        alt="image"
                        className="latestCard-avatar"
                      />
                      <div className="latestCard-description">
                        <Link
                          to={{
                            pathname: `/news/details/${el.url}`,
                            search: `id=${el._id}`,
                          }}
                          onClick={() => setOnScroll(!onScroll)}
                        >
                          <Paragraph
                            ellipsis={{ rows: 8, expandable: false }}
                            className="latestCard-title"
                          >
                            {el.title}
                          </Paragraph>
                        </Link>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <div className="d-flex align-items-center">
                            {el.tags?.length !== 0 ? (
                              <Tag color="processing">{el?.tags}</Tag>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            onClick={() => onBookMarkRelared(el)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={el.isBookmark ? bookmark_click : bookmark}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <div
                    className="dashs"
                    style={{ height: 1, backgroundColor: "#E6E8EB" }}
                  ></div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithNavigation(NewsDetail);
