import React, { useEffect, useState } from "react";
import BesinsInput from "components/BesinsInput";
import { Formik } from "formik";
import LayoutApp from "layouts/LayoutApp";
import * as yup from "yup";
import BesinsButton from "components/BesinsButton";
import SelectBesins from "components/SelectIcon";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserService from "services/user.api";
import {
  actGetDistrict,
  actGetProvince,
  actGetWard,
} from "store/actions/profile.action";
import { RootState } from "models/index.model";
import "./style.scss";
import { Modal, Tooltip } from "antd";
import { IconSuccess } from "variables/image";
import qs from "query-string";
import EventService from "services/event.api";
import { actRegisterEventSuccess } from "store/actions/event.action";
import { InfoCircleOutlined } from "@ant-design/icons";
import { convertText } from "utils/convertText";

interface FormValues {
  name: string;
  phone: string;
  email: string;
  office: string;
  address: string;
  provinceCode: Number;
  districtCode: Number;
  wardCode: Number;
  department: string;
  degree: string;
}

const RegisterEvent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id: eventId, url } = qs.parse(location.search);

  const { province, district, ward } = useSelector(
    (state: RootState) => state.profileReducer.initData
  );
  const { profile, details, email } = useSelector(
    (state: RootState) => state.profileReducer?.doctor_info
  );

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const formSchema = yup.object({
    name: yup.string().required("Tên không được để trống"),
    email: yup
      .string()
      .required("Email không được để trống")
      .email("Email không hợp lệ"),
    office: yup
      .string()
      .required("Tên Bệnh viện / Phòng khám không được để trống"),
    address: yup
      .string()
      .required("Địa chỉ Bệnh viện / Phòng khám không được để trống"),
  });

  const initialFormValues: FormValues = {
    name: profile?.fullName as string,
    phone: profile?.phoneOrder,
    email,
    office: details?.office as string,
    address: details?.address as string,
    provinceCode: details?.provinceCode,
    districtCode: details?.districtCode,
    wardCode: details?.wardCode,
    department: details?.department as string,
    degree: details?.degree as string,
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const params = {
        eventId,
        fullName: convertText(values.name).toUpperCase(),
        email: values.email,
        office: values.office,
        address: values.address,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        wardCode: values.wardCode,
        department: values.department,
        degree: values.degree
      };
      const res = await EventService.registerEventByDoctor(params);
      if (res.code === 1) {
        dispatch(actRegisterEventSuccess(eventId as string));
        setIsOpenModal(true);
        setTimeout(
          () =>     history.push(`/events/details/${url}?id=${eventId}`),
          3000
        );
      }
    } catch (error) {}
  };

  const getProvince = async () => {
    const res = await UserService.getProvince();
    const mapProvince = res.map((item: any) => ({
      label: item.name,
      value: item.provinceId,
    }));
    dispatch(actGetProvince(mapProvince));
  };

  const getDistrict = async (id: any) => {
    const res = await UserService.getDistrict(id);
    const mapDistrict = res.map((item: any) => ({
      label: item.name,
      value: item.districtId,
    }));
    dispatch(actGetDistrict(mapDistrict));
  };

  const getWard = async (id: any) => {
    const res = await UserService.getWard(id);
    const mapWard = res.map((item: any) => ({
      label: item.name,
      value: item.wardId,
    }));
    dispatch(actGetWard(mapWard));
  };

  const handleChangeProvince = async (id: any, setFieldValue: any) => {
    setFieldValue("provinceCode", id);
    setFieldValue("districtCode", null);
    setFieldValue("wardCode", null);
    getDistrict(id);
  };

  const handleChangeDistrict = async (id: any, setFieldValue: any) => {
    setFieldValue("districtCode", id);
    setFieldValue("wardCode", null);
    getWard(id);
  };

  const handleChangeWard = (id: string, setFieldValue: any) => {
    setFieldValue("wardCode", id);
  };

  useEffect(() => {
    getProvince();
  }, []);

  useEffect(() => {
    if (details?.provinceCode) getDistrict(details?.provinceCode);
  }, [details?.provinceCode]);

  useEffect(() => {
    if (details?.districtCode) getWard(details?.districtCode);
  }, [details?.districtCode]);

  return (
    <>
      <LayoutApp title="XÁC NHẬN THÔNG TIN">
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={formSchema}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
          }) => (
            <div className="formRegister">
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Họ tên"
                  markup="Nhập chữ hoa, không dấu"
                  placeholder="Nhập họ và tên"
                  value={values.name}
                  onChange={(e: any) => setFieldValue("name", e.target.value)}
                  onBlur={() => setFieldTouched("name")}
                />
                {errors && touched.name && (
                  <div className="input-error-message">{errors.name}</div>
                )}
              </div>
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Số điện thoại"
                  disabled
                  placeholder="Nhập số điện thoại"
                  value={values.phone}
                  onChange={(e: any) => setFieldValue("phone", e.target.value)}
                  onBlur={() => setFieldTouched("phone")}
                />
                {errors && touched.phone && (
                  <div className="input-error-message">{errors.phone}</div>
                )}
              </div>
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Email"
                  placeholder="Nhập email"
                  value={values.email}
                  onChange={(e: any) => setFieldValue("email", e.target.value)}
                  onBlur={() => setFieldTouched("email")}
                />
                {errors && touched.email && (
                  <div className="input-error-message">{errors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Bệnh viện / Nơi công tác"
                  placeholder="Nhập Bệnh viện / Nơi công tác"
                  value={values.office}
                  onChange={(e: any) => setFieldValue("office", e.target.value)}
                  onBlur={() => setFieldTouched("office")}
                />
                {errors && touched.office && (
                  <div className="input-error-message">{errors.office}</div>
                )}
              </div>
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Khoa/Phòng"
                  placeholder="Nhập Khoa/Phòng"
                  value={values.department}
                  onChange={(e: any) => setFieldValue("department", e.target.value)}
                  onBlur={() => setFieldTouched("department")}
                />
                {errors && touched.department && (
                  <div className="input-error-message">{errors.department}</div>
                )}
              </div>
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Học hàm/học vị"
                  placeholder="Nhập Học hàm/học vị"
                  value={values.degree}
                  onChange={(e: any) => setFieldValue("degree", e.target.value)}
                  onBlur={() => setFieldTouched("degree")}
                />
                {errors && touched.degree && (
                  <div className="input-error-message">{errors.degree}</div>
                )}
              </div>
              <div className="mb-3">
                <BesinsInput
                  className="input-custom"
                  label="Địa chỉ nhà hoặc phòng mạch tư"
                  placeholder="Địa chỉ nhà hoặc phòng mạch tư (Số nhà và tên đường)"
                  value={values.address}
                  onChange={(e: any) =>
                    setFieldValue("address", e.target.value)
                  }
                  onBlur={() => setFieldTouched("address")}
                />
                {errors && touched.address && (
                  <div className="input-error-message">{errors.address}</div>
                )}
              </div>
              <SelectBesins
                className="mb-3"
                allowClear={false}
                label="Tỉnh / Thành phố"
                placeholder="Chọn Tỉnh / Thành phố"
                optionFilterProp="label"
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                option={province}
                value={values.provinceCode}
                onChange={(value: any) =>
                  handleChangeProvince(value, setFieldValue)
                }
              />

              <SelectBesins
                className="mb-3"
                allowClear={false}
                label="Quận / Huyện"
                placeholder="Chọn Quận / Huyện"
                optionFilterProp="label"
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                option={district}
                value={values.districtCode}
                onChange={(value: any) =>
                  handleChangeDistrict(value, setFieldValue)
                }
                disabled={
                  district.length <= 0 ||
                  typeof values.provinceCode !== "string"
                }
              />
              <SelectBesins
                className="mb-3"
                allowClear={false}
                label="Phường / Xã"
                placeholder="Chọn Phường / Xã"
                optionFilterProp="label"
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                option={ward}
                value={values.wardCode}
                onChange={(value: any) =>
                  handleChangeWard(value, setFieldValue)
                }
                disabled={
                  ward.length <= 0 || typeof values.districtCode !== "string"
                }
              />

              <BesinsButton className="mb-4" block onClick={handleSubmit}>
                Đăng ký
              </BesinsButton>
            </div>
          )}
        </Formik>
      </LayoutApp>
      <Modal
        wrapClassName="modal-register"
        width={362}
        visible={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={null}
        closable={false}
      >
        <div className="text-center">
          <img src={IconSuccess} alt="icon" />
          <div className="modal__title">Đăng ký thành công!</div>
          <div className="modal__content">
            Chúc mừng bạn đã đăng ký tham dự hội thảo thành công. Đừng quên theo
            dõi thông tin mới nhất từ hội thảo bạn nhé!
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegisterEvent;
