import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { arrow_back, close } from "variables/image";
import "./style.scss";

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  right?: any;
  customGoBack?: boolean;
  onClickRight?: () => void;
  handleGoBack?: () => void;
}

const LayoutApp: FC<LayoutProps> = ({
  title,
  right,
  customGoBack = false,
  children,
  onClickRight,
  handleGoBack,
}) => {
  const history = useHistory();
  const goBack = () => {
    history.push('/');
  };

  return (
    <div className="layout">
      <div className="layout-header">
        {customGoBack ? (
          <img
            className="goBack centerVertical"
            src={close}
            alt="close"
            onClick={goBack}
          />
        ) : (
          <img
            className="goBack centerVertical"
            src={arrow_back}
            alt="goBack"
            onClick={goBack}
          />
        )}

        {title && <span className="layout-title">{title}</span>}
        {right && (
          <div className="header-right centerVertical" onClick={onClickRight}>
            {right}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default LayoutApp;
