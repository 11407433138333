import React, { useState } from "react";
//libraries
import { Input, Button } from "antd";

//services
import AuthenticationService from "../../../services/authentication.api";

//scss
import "./style.scss";

//components
import InformationModal from "../../BesinsModal/InformationModal";

//libraries
import { useHistory } from "react-router-dom";

//images
import { check_circle } from "../../../variables/image";

type Props = {
  phone?: string;
  content?: string;
  title?: string;
  text_button?: string;
};

function ChangePassword(props: Props) {
  let history = useHistory();
  //props
  const { phone, content, title, text_button } = props;

  //state
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    newPassword: "",
    currentNewPassword: "",
  });
  const [newPassword, setNewPassword] = useState("");
  const [currentNewPassword, setCurrentNewPassword] = useState("");

  function handleSignUp() {
    const errors = validateForm({ newPassword, currentNewPassword });
    setErrors(errors);
    if (!Object.keys(errors).length) {
      handleSubmit();
    }
  }

  async function updatePassword() {
    let data = {
      phone: phone,
      password: currentNewPassword,
    };
    await AuthenticationService.updatePassword(data).then((res) => {
      if (res.code === 1) {
        setMessage("");
        setIsOpenSuccessModal(true);
      } else {
        setMessage("FAIL");
      }
    });
  }

  function validateForm(values: any) {
    let errors: any = {};
    if (values.newPassword !== undefined) {
      if (!values.newPassword) errors.newPassword = "Vui lòng nhập mật khẩu!";
      else if (values.newPassword.length < 6)
        errors.newPassword = "Mật khẩu ít nhất 6 ký tự!";
    }
    if (values.currentNewPassword !== undefined) {
      if (!values.currentNewPassword)
        errors.currentNewPassword = "Vui lòng nhập mật khẩu!";
      else if (values.currentNewPassword.length < 6)
        errors.currentNewPassword = "Mật khẩu ít nhất 6 ký tự!";
      else if (values.newPassword !== values.currentNewPassword)
        errors.currentNewPassword = "Mật khẩu không trùng khớp!";
    }
    return errors;
  }

  function handleSubmit() {
    updatePassword();
  }

  return (
    <>
      <InformationModal
        image={check_circle}
        title={`Thông báo`}
        content={content}
        text_button={"Đồng ý"}
        onCancel={() => {
          setIsOpenSuccessModal(false);
          history.push("/login");
        }}
        visible={isOpenSuccessModal}
      />

      <div className="changePassword-form">
        <h2 className="changePassword-title">{title}</h2>

        <div className="title">Mật khẩu</div>
        <Input.Password
          placeholder="Nhập mật khẩu"
          size="large"
          onChange={(e) => {
            const errors = validateForm({ newPassword: e.target.value });
            setErrors(errors);
            setNewPassword(e.target.value);
          }}
          name="newPassword"
          type="password"
          style={{ marginBottom: 16 }}
        />
        {errors.newPassword !== "" && (
          <div className="input-error-message">{errors.newPassword}</div>
        )}
        <div className="title">Xác nhận mật khẩu</div>

        <Input.Password
          placeholder="Nhập mật khẩu"
          size="large"
          onChange={(e) => {
            const errors = validateForm({
              currentNewPassword: e.target.value,
              newPassword,
            });
            setErrors(errors);
            setCurrentNewPassword(e.target.value);
          }}
          name="currentNewPassword"
          type="password"
        />
        {errors.currentNewPassword !== "" && (
          <div className="input-error-message" style={{ marginTop: 12 }}>
            {errors.currentNewPassword}
          </div>
        )}
      </div>
      <div className="btn-changePassword-form" style={{ marginTop: 20 }}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="changePassword-form-button"
          onClick={handleSignUp}
        >
          {text_button}
        </Button>

        {message === "FAIL" && (
          <div className="input-error-message" style={{ marginTop: 10 }}>
            Đã có lỗi xảy ra vui lòng thử lại sau
          </div>
        )}
      </div>
    </>
  );
}

ChangePassword.propTypes = {};

export default ChangePassword;
