import React, { useState, useEffect } from "react";
//images
// import { logo } from "../../variables/image";
//scss
import "./style.scss";
import { Button, Row, Col, Input } from "antd";
import OTPInput from "../../components/BesinsOtpInput";
import AuthenticationService from "../../services/authentication.api";
import ChangePassword from "../../components/Form/ChangePassword";
import logo from "../../assets/images/besins-logo.svg";

function ForgotPage() {
  let interval: any = null;
  //props
  const [seconds, setSeconds] = useState(60);
  const [isSent, setSend] = useState(false);
  const [errOtp] = useState("");
  const [currentPhone, setCurrentPhone] = useState("");
  const [otpValues, setOTPValues] = useState(Array<string>(6).fill(""));
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isVisibleChangePwd, setIsVisibleChangePwd] = useState(false);
  const [errors, setErrors] = useState({
    phone: "",
    otp: "",
    currentPhone: "",
  });
  const [phone, setPhone] = useState("");

  async function handleChangeOTP(value: any) {
    const errors = validateForm({ otp: value });
    setErrors(errors);
    setOtp(value);
    setMessage("");
  }

  async function getOtp() {
    let data = {
      phone: phone,
    };
    try {
      await AuthenticationService.getOTPSurvey(data).then((res) => {
        if (res.code === 1) {
          setSend(true);
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  async function sendOtp() {
    const errors = validateForm({ phone });
    let data = {
      phone: phone,
    };
    setCurrentPhone(phone);
    setErrors(errors);
    setMessage("");
    setOTPValues(Array<string>(6).fill(""));
    setOtp("");
    if (!Object.keys(errors).length) {
      try {
        await AuthenticationService.checkPhone(data).then((res) => {
          if (res.code === 1) {
            if (res.data.isVerify === false) {
              setMessage("PHONE_DOES_NOT_VERIFY");
            } else {
              setIsActive(true);
              getOtp();
            }
          } else {
            setIsActive(false);
            setMessage("PHONE_DOES_NOT_EXIST");
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  }

  async function handleSubmit() {
    let data = {
      phone: phone,
      code: otp,
    };
    const errors = validateForm({ phone, otp, currentPhone });
    setErrors(errors);
    setMessage("");

    if (!Object.keys(errors).length) {
      await AuthenticationService.checkOTP(data).then((res) => {
        if (res.code === 1) {
          setIsVisibleChangePwd(true);
        } else {
          if (res.error === "CODE_IS_INCORRECT") {
            setMessage("CODE_IS_INCORRECT");
          }
        }
      });
    }
  }

  function validateForm(values: any) {
    let errors: any = {};
    const regexp = /^\d{10,11}$/;
    if (values.phone !== undefined) {
      const checkingResult = regexp.exec(values.phone);
      if (!values.phone) errors.phone = "Vui lòng nhập số điện thoại!";
      else if (checkingResult === null)
        errors.phone = "Số điện thoại phải có 10 - 11 chữ số.";
    }
    if (values.otp !== undefined) {
      if (values.otp.length < 6) errors.otp = "Mã OTP phải có 6 ký tự";
    }
    if (values.currentPhone !== undefined) {
      if (values.phone !== values.currentPhone)
        errors.currentPhone = "Số điện thoại chưa xác thực OTP!";
    }
    return errors;
  }

  useEffect(() => {
    if (isActive) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setSeconds(60);
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  return (
    <div className="forgot-container">
      <div className="body">
        <div className="forgot-logo">
          <img src={logo} alt="logo_besins" />
        </div>
        <div className="forgot-form">
          {isVisibleChangePwd && (
            <ChangePassword
              phone={phone}
              title={"Tạo mật khẩu mới"}
              content={"Đổi mật khẩu thành công!"}
              text_button={"Đổi mật khẩu"}
            />
          )}
          {!isVisibleChangePwd && (
            <>
              <h2 className="forgot-title">Quên mật khẩu?</h2>
              <p>Vui lòng cung cấp số điện thoại để lấy lại mật khẩu.</p>
              <div className="forgot-form__top">
                <label className="title">Số điện thoại</label>
                <Row gutter={[12, 0]}>
                  <Col span={16}>
                    <Input
                      placeholder="Nhập số điện thoại"
                      size="large"
                      onChange={(e) => {
                        const errors = validateForm({ phone: e.target.value });
                        setErrors(errors);
                        setPhone(e.target.value);
                      }}
                      name="phone"
                      type="tel"
                    />
                    {errors.phone !== "" && (
                      <div className="input-error-message">{errors.phone}</div>
                    )}
                    {errors.currentPhone !== "" && (
                      <div className="input-error-message">
                        {errors.currentPhone}
                      </div>
                    )}
                    {message === "PHONE_DOES_NOT_EXIST" && (
                      <div className="input-error-message">
                        Số điện thoại không tồn tại
                      </div>
                    )}
                    {message === "PHONE_DOES_NOT_VERIFY" && (
                      <div className="input-error-message">
                        Số điện thoại chưa đăng ký
                      </div>
                    )}
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      size="large"
                      className="otp-button"
                      disabled={isActive ? true : false}
                      onClick={sendOtp}
                    >
                      {isActive ? (
                        <span className="disabled">Gửi lại ({seconds}s)</span>
                      ) : (
                        "Gửi OTP"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="forgot-form__bottom">
                <label className="title">Nhập OTP</label>

                {isSent && (
                  <OTPInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    isNumberInput
                    autoFocus
                    error={errOtp !== ""}
                    errorText={errOtp}
                    value={otp}
                    otpValues={otpValues}
                    setOTPValues={setOTPValues}
                    onChangeOTP={handleChangeOTP}
                    //   disabled={!isSent}
                  />
                )}
                {!isSent && (
                  <OTPInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    isNumberInput
                    autoFocus
                    error={errOtp !== ""}
                    errorText={errOtp}
                    value={otp}
                    otpValues={otpValues}
                    setOTPValues={setOTPValues}
                    onChangeOTP={handleChangeOTP}
                    disabled
                  />
                )}
                {errors.otp !== "" && (
                  <div className="input-error-message">{errors.otp}</div>
                )}
                {message === "CODE_IS_INCORRECT" && (
                  <div
                    className="input-error-message"
                    style={{ marginTop: 10 }}
                  >
                    Mã OTP không hợp lệ!
                  </div>
                )}
              </div>
              <div className="btn-forgot-form">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="forgot-form-button"
                  onClick={handleSubmit}
                >
                  Tạo mật khẩu mới
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

ForgotPage.propTypes = {};

export default ForgotPage;
