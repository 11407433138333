import React from "react";
import { Modal, Button } from "antd";
import "./style.scss";
type Props = {
  visible?: boolean;
  onCancel?: any;
  image?: string;
  title?: string;
  content?: any;
  className?: string;
  text_button?: string;
  width?: number;
};
function InformationModal(props: Props) {
  const {
    visible,
    onCancel,
    image,
    title,
    content,
    className,
    text_button,
    width
  } = props;

  return (
    <Modal
      title="Modal"
      visible={visible}
      className={`confirmModal ` + className}
      width={width ? width : 432}
      footer={null}
      closable={false}
      centered={true}
    >
      <div className="confirmModal-body">
        <div className="confirmModal-head">
          <img src={image} alt="" className="image" />
          <div className="title">{title}</div>
        </div>
        <div className="confirmModal-content">{content}</div>
        <div className="confirmModal-button">
          <Button type="primary" onClick={onCancel}>
            {text_button}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

InformationModal.propTypes = {};

export default InformationModal;
