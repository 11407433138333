var initialState = {
  example: {},
};

const exampleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default exampleReducer;
