import actionTypes from "../actionTypes";

const initialState = {
  order_product_list: [],
  cart: [],
  order: {
    paymentStatus: "UNPAID",
  },
  order_detail: {
    orderId: "",
    isShow: false,
    detail: {},
  },
  user_notification: {},
  user_all_noti: {
    docs: [],
  },
  user_total_notification: {},
};

const orderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_SET_ORDER:
      state = { ...state, order: action.order };
      return state;
    case actionTypes.RESET_ORDER:
      state = { ...state, order: initialState.order };
      return state;
    case actionTypes.GET_ORDER_PRODUCT_LIST:
      state = {
        ...state,
        order_product_list: action.order_product_list,
      };
      return state;
    case actionTypes.UPDATE_CART:
      return Object.assign({}, state, {
        cart: action.cart,
      });
    case actionTypes.RESET_CART:
      state = { ...state, cart: initialState.cart };
      return state;
    case actionTypes.GET_ORDER_DETAIL:
      state = {
        ...state,
        order_detail: action.order_detail,
      };
      return state;
    case actionTypes.RESET_ORDER_DETAIL:
      state = { ...state, order_detail: initialState.order_detail };
      return state;
    case actionTypes.SET_NOTIFICATION_USER:
      state = {
        ...state,
        user_notification: action.data,
      };
      return state;
    case actionTypes.SET_ALL_NOTICES_USER:
      state = {
        ...state,
        user_all_noti: action.data,
      };
      return state;
    case actionTypes.SET_TOTAL_NOTIFICATION:
      state = {
        ...state,
        user_total_notification: action.data,
      };
      return state;
    default:
      return state;
  }
};

export default orderReducer;
