import LoginLayout from "../layouts/login";
import ForgotLayout from "../layouts/forgot";
// import RegisterLayout from "../layouts/register";
import CheckLoginBy from "layouts/CheckLoginBy";
import Register from "views/Auth/Register";
import verifyPhone from "../layouts/register"

const indexRoutes = [
  {
    path: "/login",
    exact: true,
    component: LoginLayout,
  },
  {
    path: "/forgot",
    exact: true,
    component: ForgotLayout,
  },
  {
    path: "/register",
    exact: true,
    component: Register,
  },
{
  path: "/verifyphone",
  exact :true,
  component : verifyPhone
},
  {
    path: "/",
    component: CheckLoginBy,
  },
];

export default indexRoutes;
