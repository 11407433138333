/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// components
import BesinsButton from "components/BesinsButton";
import LatestCard from "components/BesinsNews/LatestNews/LatestCard";
import WithNavigation from "layouts/WithNavigation";
import HOST_URL_IMAGE from "variables/hostUrl";
// redux
import { RootState } from "models/index.model";
import { useDispatch, useSelector } from "react-redux";
// services
import NewServices from "services/news.api";
// style
import "./style.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { actStatePageNews } from "store/actions/news.action";

type Props = {};

const NewsPage = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  //Redux
  const news = useSelector((state: RootState) => state?.newsReducer?.news);
  const state_page = useSelector(
    (state: RootState) => state?.newsReducer?.state_page
  );

  // Query Data
  const params: any = useParams();

  const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const id = query.get("id") as string;

  // State
  useEffect(() => {
    const path = window.location.pathname.slice(1, 8);

    if (path) {
      if (path === "news/sa") {
        dispatch(actStatePageNews("default"));
      }
      if (path === "article") {
        dispatch(actStatePageNews("article"));
      }
      if (path === "category") {
        dispatch(actStatePageNews("category"));
      }
    }
  }, [state_page]);
  const [listNew, setListNew] = useState<any[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [nowPage, setNowPage] = useState(1);
  const [limit, setLimit] = useState(5);

  async function getDataNews(
    reset: boolean = false,
    page: number = nextPage,
    numLimit: number = limit
  ) {
    const paramsFilter = {
      typeArticle: "",
      subCategoryId: JSON.stringify(id),
    };
    const paramsFilterArticle = {
      typeArticle: id,
      subCategoryId: "",
    };
    try {
      if (state_page === "default") {
        const res = await NewServices.getListNewsOther(page, numLimit);
        if (res) {
          setListNew(reset ? res.docs : [...listNew, ...res.docs]);
          setNextPage(res.nextPage);
          setNowPage(res.page);
          setLimit(numLimit);
        }
        return;
      } else {
        const res = await NewServices.getListArticleFilter(
          state_page === "category" ? paramsFilter : paramsFilterArticle
        );
        if (res) {
          setListNew(reset ? res.docs : [...listNew, ...res.docs]);
          setNextPage(res.nextPage);
          setNowPage(res.page);
        }
        return;
      }
    } catch (error) {}
  }
  function onClickSeeMore() {
    getDataNews();
  }

  const onBookmark = async () => {
    getDataNews(true, 1, nowPage * limit - 1);
  };
  const nullFunction = () => {};
  function LatestNewsItem() {
    return (
      <div className="latest-News">
        <div className="latest-News__list">
          {listNew?.map((el: any) => {
            return (
              <>
                <LatestCard
                  tags={el.tags}
                  news={el}
                  idArticle={el?._id}
                  loading={news.isLoading}
                  title={el?.title}
                  avatar={`${HOST_URL_IMAGE}/${el.images?.size.ONE_ONE?.url}`}
                  text={el?.description}
                  url={el.url}
                  createdAt={el?.createdAt}
                  isBookmark={state_page === "default" ? true : el?.isBookmark}
                  onBookmark={
                    state_page === "default" ? onBookmark : nullFunction
                  }
                />
                <div
                  className="dashs"
                  style={{ height: 1, backgroundColor: "#E6E8EB" }}
                ></div>
              </>
            );
          })}
          {listNew.length !== 0 && nextPage ? (
            <div className="latest-News__readMore">
              <BesinsButton
                type="primary"
                loading={news.isLoading}
                onClick={onClickSeeMore}
              >
                Xem Thêm
              </BesinsButton>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    getDataNews();
  }, [state_page]);

  return (
    <>
      <header className="">
        {/* <NotificationDrawer visible={openNoti} onClose={closeNoti} /> */}
        <div className="sider-header">
          <div className="menu-toggler">
            <div className="flex-between menu-news">
              <i
                className="icon icon-arrow-back"
                style={{ color: "#6b778c" }}
                onClick={() => history.push("/news")}
              />
              <div className="menu-views__filter" aria-hidden="true">
                <span
                  style={{
                    color: "#172B4D",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                  className="subtitle-2"
                >
                  {state_page === "default" ? "Tin đã lưu của tôi" : params.url}
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="home-card-white">
        <LatestNewsItem />
      </div>
    </>
  );
};

export default WithNavigation(NewsPage);
