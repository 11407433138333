import LayoutApp from "layouts/LayoutApp";
import React, { useEffect, useState } from "react";
import EventService from "services/event.api";

const GuideZoom = () => {
  const [data, setData] = useState<any>({});
  const getGuideZoom = async () => {
    const res = await EventService.getGuideZoom();
    setData(res.data);
  };

  function createMarkup(text: string) {
    return { __html: text };
  }

  function rawHTML(text: string) {
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }

  useEffect(() => {
    getGuideZoom();
  }, []);

  return (
    <LayoutApp title="Hướng dẫn sử dụng Zoom">
      <div className="container-wrap">{rawHTML(data?.content)}</div>
    </LayoutApp>
  );
};

export default GuideZoom;
