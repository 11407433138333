import Types from "../actionTypes";
import { User } from "../../models/user.model";

export const actGetUserProfile = (user_info: User) => {
  return {
    type: Types.GET_PROFILE,
    user_info,
  };
};

export const actGetDoctorProfile = (user_info: User) => {
  return {
    type: Types.GET_DOCTOR_PROFILE,
    user_info,
  };
};

export const actGetProvince = (data: any[]) => {
  return {
    type: Types.GET_PROVINCE,
    payload: data,
  };
};

export const actGetDistrict = (data: any[]) => {
  return {
    type: Types.GET_DISTRICT,
    payload: data,
  };
};

export const actGetWard = (data: any[]) => {
  return {
    type: Types.GET_WARD,
    payload: data,
  };
};
