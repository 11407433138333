import React, { useState, useEffect } from "react";
import "./style.scss";

//components
import BesinsInput from "../../BesinsInput";

//libraries
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

//services
import OrderService from "../../../services/order.api";
import { actGetOrderProductList } from "../../../store/actions/order.action";
import { RootState } from "../../../models/index.model";
import BesinsProductOrder from "../../BesinsProductOrder";
import { Spin } from "antd";

//redux

function Step2Order() {
  //variables
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#DADADA",
      }}
    />
  );
  const dispatch = useDispatch();

  //props
  const order = useSelector((state: RootState) => state.orderReducer.order);
  const order_product_list = useSelector(
    (state: RootState) => state.orderReducer.order_product_list
  );
  //states
  const [valueSearch, setValueSearch] = useState("");
  const [productList, setProductList] = useState(
    order_product_list?.length > 0 ? order_product_list : []
  );
  const [isLoading, setIsLoading] = useState(false);

  async function getOrderProductList(doctorId: string) {
    setIsLoading(true);
    try {
      const res = await OrderService.getProductListForDoctor(doctorId);
      if (res.code === 1) {
        setIsLoading(false);

        dispatch(actGetOrderProductList(res.data));
        setProductList(res.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  const change_alias = (alias: string) => {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " "
    );
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
  };

  useEffect(() => {
    getOrderProductList(order.buyer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.buyer]);

  return (
    <div className="step2order-container">
      <BesinsInput
        size="large"
        name="search"
        value={valueSearch}
        onChange={(e: any) => {
          const currValue = change_alias(e.target.value);
          setValueSearch(e.target.value);
          if (currValue !== "") {
            setProductList(
              productList?.filter((entry: any) =>
                change_alias(entry.name).includes(currValue)
              )
            );
          } else {
            setProductList(order_product_list);
          }
        }}
        placeholder="Tìm tên hoặc mã sản phẩm"
        allowClear
        suffix={suffix}
        className="step2order-input"
      />
      <Spin spinning={isLoading}>
        <div className="step2order-productList">
          {productList?.map((prop, i) => {
            return (
              <BesinsProductOrder
                key={i}
                unitPrice={"đ"}
                unitProduct={"hộp"}
                productItem={prop}
              />
            );
          })}
        </div>
      </Spin>
    </div>
  );
}

Step2Order.propTypes = {};

export default Step2Order;
