import React from "react";
import "../style.scss";
import { Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
export type InputSize = "small" | "large";
export type InputType =
  | "text"
  | "number"
  | "textarea"
  | "email"
  | "tel"
  | "password";

type Props = {
  ref?: any;
  value?: any;
  size?: InputSize;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: InputType;
  label?: string;
  markup?: string;
  onChange?: any;
  readOnly?: boolean;
  prefix?: any;
  suffix?: any;
  name?: string;
  min?: number;
  max?: number;
  style?: React.CSSProperties;
  allowClear?: boolean;
  maxLength?: number;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
};
const { TextArea } = Input;
function BesinInputTextArea(props: Props) {
  return (
    <div className={`custom-input ${props.className}`}>
      {props.label && (
        <div className="custom-input-label mb-1 d-flex align-items-center">
          {props.label}
          {props.markup && (
            <span className="ml-2 d-flex align-items-center">
              <Tooltip title={props.markup}>
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            </span>
          )}
        </div>
      )}      
      <TextArea
          ref={props.ref}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={(e) => props.onChange(e)}
          readOnly={props.readOnly}
          style={
            props.readOnly ? { background: "#F5F6F7", height:"65px" } : { background: "#FFFFFF", height:"65px" }
          }
          required
          prefix={props.prefix}
          allowClear={props.allowClear}
          maxLength={props.maxLength}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
    </div>
  );
}

BesinInputTextArea.propTypes = {};

export default BesinInputTextArea;