import callApi from "../utils/callApi";
import setParams from "../utils/setParams";

const OrderService = {
  get: async (data: any) => {
    data = { ...data, status: JSON.stringify(data.statusSelect) };
    const params = setParams(data);
    const endpoint = `order/listForDoctor?${params}`;
    const res = await callApi(endpoint);
    return res.data;
  },
  getDetail: async (id: string) => {
    const endpoint = `order/view/${id}`;
    const res = await callApi(endpoint);
    return res.data;
  },
  getProductListForDoctor: async (doctorId: string) => {
    const endpoint = `cms/promotion/get_by_product_to_order?doctorId=${doctorId}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getMedRep: async (doctorID: string) => {
    const endpoint = `order/medrep-list?idCustomer=${doctorID}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getChannelForCustomer: async (customerId: string) => {
    const endpoint = `order/channel_detail?customerId=${customerId}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  createOrder: async (data: any) => {
    const endpoint = `order/create`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  getNotificationForUser: async (data?: any) => {
    const endpoint =
      `cms/notification/my_notification?limit=${data.limit}` +
      `&page=${data.page}` +
      `&generalType=${data.type}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getTotalNotification: async () => {
    const endpoint = `cms/notification/count_my_notification`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  updateNotification: async (data?: any) => {
    const endpoint = `cms/notification/see_notification`;
    const res = await callApi(endpoint, "PUT", data);
    return res;
  },
  updateAllNotices: async (data?: any) => {
    const endpoint = `cms/notification/mark_read_all?generalType=${data.generalType}`;
    const res = await callApi(endpoint, "PUT");
    return res;
  },
  updateOrder: async (id?: string) => {
    const endpoint = `order/actDate/${id}`;
    const res = await callApi(endpoint, "PUT");
    return res;
  },
  rejectOrder: async (data: any) => {
    const endpoint = `order/reject`;
    const res = await callApi(endpoint, "PUT", data);
    return res;
  },
};
export default OrderService;
