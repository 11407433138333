import React, { useState, useEffect } from "react";
import "./style.scss";
import { Row, Col } from "antd";
import Step2Order from "../../components/OrderSteps/Step2Order.tsx";
import Step3Confirm from "../../components/OrderSteps/Step3/Step3Confirm";
import Step1Info from "../../components/OrderSteps/Step1/Step1Info";
import BesinsButton from "../../components/BesinsButton";
import OrderService from "../../services/order.api";
import { RootState } from "../../models/index.model";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModal from "../../components/BesinsModal/ConfirmationModal";
import InformationModal from "../../components/BesinsModal/InformationModal";
import {
  check_circle,
  notification_create_order,
  warning,
} from "../../variables/image";
import {
  actResetOrder,
  actResetCart,
  actGetSetOrder,
} from "../../store/actions/order.action";
import { useHistory } from "react-router-dom";
import { formatMoney } from "../../utils/formatMoney";
import BesinsSteps from "../../components/Steps";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
function CreateOrderPage() {
  const dispatch = useDispatch();
  let history = useHistory();
  //props
  const order = useSelector((state: RootState) => state.orderReducer.order);
  const order_product_list = useSelector(
    (state: RootState) => state.orderReducer.order_product_list
  );
  const cart = useSelector((state: RootState) => state.orderReducer.cart);

  //states
  const [visibleOrderDrawer, setVisibleOrderDrawer] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenFailModal, setIsOpenFailModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [showInformation, setShowInformation] = useState(false);

  useEffect(() => {
    _getOrder().then((res) => {
      if (res.length > 0) setShowInformation(true);
    });
  }, []);
  function renderStep() {
    switch (order?.step!) {
      case 0:
        return <Step1Info />;
      case 1:
        return <Step2Order />;
      case 2:
        return <Step3Confirm />;
      default:
        return "";
    }
  }
  async function _getOrder() {
    try {
      const data = { statusSelect: ["SUBMIT"] };
      const res_orders = await OrderService.get(data);
      return res_orders.docs;
    } catch (err) {
      //console.log(err);
    }
  }
  function setOrder() {
    let total = cart.reduce((total, item: any) => {
      return total + item.total;
    }, 0);

    let products =
      cart &&
      cart.map((product, i) => {
        return {
          amount: product.quantity,
          product: product._id,
          price: product.price,
        };
      });

    dispatch(
      actGetSetOrder({
        ...order,
        sum: total,
        products: products,
        step: 2,
      })
    );
  }

  async function createOrder() {
    const createData = {
      buyer: order.buyer,
      medRep: order.medRep,
      distributor: order.distributor,
      sum: order.sum,
      note: order.note,
      products: order.products,
      oldProductList: order_product_list,
      paymentStatus: order.paymentStatus,
      channel: order.channel,
      channelId: order.channelId,
    };
    setIsOpenConfirmModal(false);
    try {
      const res = await OrderService.createOrder(createData);
      if (res.code === 1) {
        setIsOpenSuccessModal(true);
      } else {
        setIsOpenFailModal(true);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  function closeSuccessConfirmModal() {
    setIsOpenSuccessModal(false);
    dispatch(actResetOrder());
    dispatch(actResetCart());
    history.push("/orders");
  }

  function closeFailConfirmModal() {
    setIsOpenFailModal(false);
    dispatch(actResetOrder());
    dispatch(actResetCart());
    history.push("/orders");
  }

  function cancelOrder() {
    dispatch(actResetOrder());
    dispatch(actResetCart());
    history.push("/orders");
  }

  useEffect(() => {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 0,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotal = () => {
    const total = cart.reduce((total, item: any) => {
      return total + item.total;
    }, 0);
    return total;
  };

  useEffect(() => {
    let newTotal = calculateTotal();
    setTotal(newTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  function goToProducts() {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 1,
      })
    );
  }
  return (
    <>
      <ConfirmationModal
        width={362}
        visible={isOpenConfirmModal}
        cancelText="Huỷ"
        confirmText="Đặt hàng"
        title="Đặt hàng"
        content="Bạn chắc chắn muốn đặt đơn hàng này?"
        cancel={() => setIsOpenConfirmModal(false)}
        confirm={createOrder}
      />

      <InformationModal
        image={check_circle}
        content={"Đơn hàng của bạn được tạo thành công"}
        title={"Hoàn tất!"}
        text_button={"Đồng ý"}
        onCancel={closeSuccessConfirmModal}
        visible={isOpenSuccessModal}
      />

      <InformationModal
        image={check_circle}
        content={"Đã có lỗi xảy ra. Vui lòng thử lại sau!"}
        title={"Lỗi"}
        text_button={"Đồng ý"}
        onCancel={closeFailConfirmModal}
        visible={isOpenFailModal}
      />

      {/* <OrderDrawer
        onClose={() => setVisibleOrderDrawer(false)}
        visible={visibleOrderDrawer}
      /> */}
      <ScrollToTopOnMount />
      {!showInformation && (
        <div id="notification">
          <Row
            gutter={[0, 0]}
            style={{ marginTop: 56, textAlign: "center", height: "100%" }}
          >
            <div className="notification-card">
              <Col
                span={24}
                // style={{ paddingTop: 35 }}
              >
                <img
                  src={notification_create_order}
                  alt=""
                  style={{ paddingLeft: 25 }}
                />
                <p
                  style={{
                    marginTop: 24,
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  Liên hệ Nhà phân phối sản phẩm
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    marginBottom: 12,
                    lineHeight: "20px",
                    textAlign: "start",
                  }}
                >
                  Chức năng này có giá trị để kết nối Nhà Phân Phối. Các chương
                  trình phân phối dành cho các Phòng khám có chức năng kinh
                  doanh Dược.
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    marginBottom: 24,
                    lineHeight: "20px",
                    textAlign: "start",
                  }}
                >
                  Nếu ấn{" "}
                  <span style={{ fontWeight: 600, fontSize: 14 }}>
                    {" "}
                    “Tiếp tục”{" "}
                  </span>
                  nghĩa là bạn xác nhận chức năng này là đúng.
                </p>

                <Row style={{ width: "100%" }}>
                  <Col
                    lg={12}
                    style={{ paddingLeft: 5, paddingRight: 5, width: "50%" }}
                  >
                    <BesinsButton
                      style={{ width: "100%" }}
                      type="default"
                      size="large"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Hủy
                    </BesinsButton>
                  </Col>
                  <Col
                    lg={12}
                    style={{ paddingLeft: 5, paddingRight: 5, width: "50%" }}
                  >
                    <BesinsButton
                      style={{ width: "100%" }}
                      type="primary"
                      size="large"
                      onClick={() => setShowInformation(true)}
                    >
                      Tiếp tục
                    </BesinsButton>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </div>
      )}

      <div
        className={`createOrder-layout ${order?.step === 0 && "step1-layout"}`}
      >
        {showInformation && (
          <BesinsSteps step={order?.step} defaultActivekey={0} />
        )}
        {showInformation && renderStep()}

        {/* {showInformation && order?.step! === 0 && (
          <Row className="step1-btn">
            <Col span={24} style={{ paddingRight: 11, textAlign: "center" }}>
              <BesinsButton
                style={{ width: "100%" }}
                disabled={!order.channel}
                onClick={goToProducts}
              >
                Đồng ý & Liên hệ NPP
              </BesinsButton>
            </Col>
          </Row>
        )} */}
        {order?.step! === 1 && (
          <Row className="step2-btn">
            <div className="cart-total">
              <div className="cart-total__title">
                Tổng tiền:
                <p
                  className="cart-detail__txt"
                  onClick={() => setVisibleOrderDrawer(true)}
                >
                  Hiển thị chi tiết
                </p>
              </div>
              <div className="cart-total__money">
                <div className="money">{formatMoney(total)}</div>
                <div className="describe">(Đã bao gồm VAT)</div>
              </div>
            </div>
            <Col span={24} style={{ paddingRight: 0, textAlign: "center" }}>
              <BesinsButton
                style={{ width: "100%" }}
                disabled={cart?.length > 0 ? false : true}
                onClick={() => {
                  setOrder();
                }}
              >
                Xác nhận đơn hàng
              </BesinsButton>
            </Col>
          </Row>
        )}
        {order?.step! === 2 && (
          <Row className="step3-btn">
            <div className="cart-total">
              <div className="cart-total__title">Tổng tiền:</div>
              <div className="cart-total__money">
                <div className="money">{formatMoney(total)}</div>
                <div className="describe">(Đã bao gồm VAT)</div>
              </div>
            </div>
            <Col span={24} style={{ paddingRight: 0, textAlign: "center" }}>
              <BesinsButton
                style={{ width: "100%" }}
                onClick={() => setIsOpenConfirmModal(true)}
              >
                Đặt hàng
              </BesinsButton>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

CreateOrderPage.propTypes = {};

export default CreateOrderPage;
