import LayoutApp from "layouts/LayoutApp";
import { RootState } from "models/index.model";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.scss";

const Settings = () => {
  const history = useHistory();
  const { token } = useSelector((state: RootState) => state.tokenReducer);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("fullName");
    localStorage.removeItem("role");
    localStorage.clear();
    // history.push("/login");
    window.location.href = "/login";
  };

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <LayoutApp title="Cài đặt">
      <div className="d-flex justify-content-between align-items-center item-li" onClick={() => history.push("/change-password")}>
        <span className="text">Đổi mật khẩu</span>
        <i className="icon icon-chevron-right"></i>
      </div>
      <div className="d-flex justify-content-between align-items-center item-li">
        <span className="text" onClick={handleLogout}>
          Đăng xuất
        </span>
      </div>
    </LayoutApp>
  );
};

export default Settings;
