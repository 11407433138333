import React from "react";
import { ClipLoader } from "react-spinners";
import "./style.scss";

interface IProps {
  loading?: boolean;
}

function Spinner({ loading }: IProps) {
  return (
    <div className="sweet-loading">
      <ClipLoader size={60} color={"#dee2e6"} loading={loading} />
    </div>
  );
}

export default Spinner;
