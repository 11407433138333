import Types from "../actionTypes";

export const actCheckPhoneExist = (data: any) => {
  return {
    type: Types.CHECK_PHONE_EXIST,
    payload: data,
  };
};

export const actVerifyCodePending = () => {
  return {
    type: Types.VERIFY_CODE_PENDING,
  };
};
export const actVerifyCodeError = (data: any) => {
  return {
    type: Types.VERIFY_CODE_ERROR,
    payload: data,
  };
};

export const actVerifyCodeSuccess = (data: any) => {
  return {
    type: Types.VERIFY_CODE_SUCCESS,
    payload: data,
  };
};
export const actResetAuth = () => {
  return {
    type: Types.RESET_AUTH,
  };
};