import React, { useState, useEffect } from "react";
//scss
import "./style.scss";
//images
// import { logo } from "../../variables/image";
//libraries
import { Input, Checkbox, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/besins-logo.svg";

//services
import AuthenticationService from "../../services/authentication.api";
import { actSetToken } from "../../store/actions/token.action";
import { actCheckPhoneExist } from "store/actions/auth.action";

type Props = {};

const LoginPage = (props: Props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [remember, setRemember] = useState(false);
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    phone: "",
    password: "",
  });
  const firstlogin = true;
  function validateForm(values: any) {
    let errors: any = {};
    const regexp = /^\d{10,11}$/;
    if (values.phone !== undefined) {
      const checkingResult = regexp.exec(values.phone);
      if (!values.phone) errors.phone = "Vui lòng nhập số điện thoại!";
      else if (checkingResult === null)
        errors.phone = "Số điện thoại phải có 10 - 11 chữ số.";
    }
    if (values.password !== undefined) {
      if (!values.password) errors.password = "Vui lòng nhập mật khẩu!";
      else if (values.password.length < 6)
        errors.password = "Mật khẩu ít nhất 6 ký tự";
    }
    return errors;
  }

   function loginByPhoneNumber() {
    setMessage("");
    let data = {
      phone: phone,
      password: password,
    };
    try {
      AuthenticationService.login(data).then((res) => {
        if (res.code === 1) {
          if(res.data.isVerify){
          localStorage.setItem("access_token", res.data["token"]);
          localStorage.setItem("fullName", res.data.profile.fullName);
          localStorage.setItem("role", res.data.type);
          localStorage.setItem('first-login', JSON.stringify(firstlogin));
          dispatch(actSetToken(res.data["token"]));
          if (remember) {
            const encodedString = new Buffer(password).toString("base64");
            let rememberUser = {
              phone: phone,
              password: encodedString,
              isRemember: remember,
            };
            localStorage.setItem("rememberUser", JSON.stringify(rememberUser));
          } else {
            if (localStorage.getItem("rememberUser"))
              localStorage.removeItem("rememberUser");
          }
          history.push("/");
        }
        if(!res.data.isVerify){
          localStorage.setItem("access_token", res.data["token"]);
          dispatch(actCheckPhoneExist({initStep : "step2"}));
          history.push("/register");
        }
        } else {
          if (res.error === "INVALID_PHONE_NUMBER_OR_PASSWORD") {
            setMessage("INVALID_PHONE_NUMBER_OR_PASSWORD");
          } else if (res.error === "YOU_HAVE_NOT_LOGIN_YET") {
            history.push("/verifyphone?phone=" + phone);
          } else if (res.error === "YOU_HAVE_QUIT") {
            setMessage("YOU_HAVE_QUIT");
          } else {
            setMessage("FAIL");
          }
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  function handleSignUp(e: any) {
    e.preventDefault();
    const errors = validateForm({ phone, password });
    setErrors(errors);
    if (!Object.keys(errors).length) {
      loginByPhoneNumber();
    }
  }

  useEffect(() => {
    if (localStorage.getItem("rememberUser")) {
      const rememberUser = localStorage.getItem("rememberUser")!;
      let storage = JSON.parse(rememberUser);
      let decodePwd = atob(storage.password);
      setPhone(storage.phone);
      setPassword(decodePwd);
      setRemember(storage.isRemember);
    }
  }, []);

  return (
    <div className="login-container">
      <div className="body">
        <div className="login-logo">
          <img src={logo} alt="logo_besins" />
        </div>
        <form className="login-form" onSubmit={handleSignUp}>
          <div className="login-form__top">
            <label className="title">Số điện thoại</label>
            <Input
              placeholder="Nhập số điện thoại"
              size="large"
              onChange={(e) => {
                const errors = validateForm({ phone: e.target.value });
                setErrors(errors);
                setPhone(e.target.value);
              }}
              value={phone}
              name="phone"
              type="tel"
            />
            {errors.phone !== "" && (
              <div className="input-error-message">{errors.phone}</div>
            )}
          </div>
          <div className="login-form__bottom">
            <label className="title">Mật khẩu</label>
            <Input.Password
              placeholder="Nhập mật khẩu"
              size="large"
              type="password"
              name="password"
              onChange={(e) => {
                const errors = validateForm({ password: e.target.value });
                setErrors(errors);
                setPassword(e.target.value);
              }}
              value={password}
            />
            {errors.password !== "" && (
              <div className="input-error-message">{errors.password}</div>
            )}
          </div>
          <div className="remember-login-form">
            <Checkbox
              onChange={() => setRemember(!remember)}
              checked={remember}
              className="remember-checkbox"
            >
              Nhớ mật khẩu
            </Checkbox>
            <div className="forgot-button">
              <Link to={`forgot`}>
                <a href={`forgot`}>Quên mật khẩu?</a>
              </Link>
            </div>
          </div>
          <div className="btn-login-form">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="login-form-button"
              onClick={handleSignUp}
            >
              Đăng nhập
            </Button>
            {message === "INVALID_PHONE_NUMBER_OR_PASSWORD" && (
              <div className="input-error-message" style={{ marginTop: 10 }}>
                Số điện thoại hoặc mật khẩu không đúng
              </div>
            )}
            {message === "YOU_HAVE_NOT_LOGIN_YET" && (
              <div className="input-error-message" style={{ marginTop: 10 }}>
                Số điện thoại này chưa đăng ký
              </div>
            )}
            {message === "YOU_HAVE_QUIT" && (
              <div className="input-error-message" style={{ marginTop: 10 }}>
                Bạn không có quyền đăng nhập vào hệ thống. Hãy liên lạc với quản
                trị viên.
              </div>
            )}
          </div>
          <div className="login-form-account">
            <span className="text">Bạn chưa có tài khoản?</span>
            <span className="create-button">
              <Link to={"/register"}>
                <a href="/register">Tạo tài khoản</a>
              </Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
