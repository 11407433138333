import axios from "axios";
import HOST from "../variables/host";

export default async function callApi(
  endpoint,
  method = "GET",
  data = null,
  form_data = false
) {
  const token = localStorage.getItem("access_token") || "";
  return axios({
    method: method,
    url: `${HOST}/${endpoint}`,
    data: data,
    headers: {
      "api-token": token,
      "content-type": !form_data
        ? "application/json;charset=UTF-8"
        : "multipart/form-data",
    },
  })
    .then((res) => {
      const { code } = res.data;
      if (code === -100) {
        throw res.data;
      }
      return res.data;
    })
    .catch((err) => {
      // alert(`${HOST}/${endpoint}`);
      // alert(JSON.stringify(err));
      if (err.type === "ACCESS_DENIED") {
        window.location.href = "/login";
      }
      throw err;
    });
}
