import callApi from "utils/callApi";

const UserService = {
  getProvince: async () => {
    const endpoint = `cms/provinceList`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getDistrict: async (id: number) => {
    const endpoint = `cms/districtList?provinceId=${id}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getWard: async (id: number) => {
    const endpoint = `cms/wardList?districtId=${id}`;
    const res = await callApi(endpoint, "GET");
    return res.data;
  },
  getDoctorInfo: async () => {
    const endpoint = `user/get_detail_doctor`;
    const res = await callApi(endpoint, "GET");
    return res?.data;
  },
  updateUser: async (data: any) => {
    const endpoint = `user/update_infor_doctor`;
    const res = await callApi(endpoint, "PUT", data);
    return res.data;
  },
  createUser: async (data: any) => {
    const endpoint = `user/doctor_register`;
    const res = await callApi(endpoint, "POST", data);
    return res.data;
  },
  sendContentUpdate: async (data: any) => {
    const endpoint = `user/send-email-change-info?typeOrder=${data.type}`;
    const res = await callApi(endpoint, "POST", data.content);
    return res.data;
  },
};

export default UserService;
