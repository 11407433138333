import React, { useEffect } from "react";
import "./style.scss";
import RegisterPage from "../../views/RegisterPage";
function RegisterLayout() {
  useEffect(() => {
    localStorage.removeItem("access_token");
  },[])
  return (
    <div className="register-layout">
      <RegisterPage />
    </div>
  );
}

RegisterLayout.propTypes = {};

export default RegisterLayout;
