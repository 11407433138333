import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card } from "antd";
import "./style.scss";
import { arrow_back, bookmark, bookmark_click } from "variables/image";
import BesinsInputSearch from "components/BesinsInputSearch";
import NewServices from "services/news.api";
import {  useHistory } from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";
import HOST_URL_IMAGE from "variables/hostUrl";
import moment from "moment";

type Props = {
  visible?: boolean;
  onCancel?: any;
  image?: string;
  title?: string;
  content?: any;
  className?: string;
  text_button?: string;
  closable?: boolean;
  allowClear: boolean;
  width?: number;
};
function InformationModal(props: Props) {
  const {
    visible,
    onCancel,
    closable,
    width,
  } = props;
  const history = useHistory();

  // const [stateBookmark, setStateBookmark] = useState(false);

  const tyingTimeoutRef: any = useRef(null);

  const [listNew, setListNew] = useState([]);

  async function onChangeSearch(e: any) {
    const paramsFilter = {
      limit: 10,
      typeArticle: "",
      startDate: "",
      endDate: "",
      subCategoryId: [],
      search: e.target.value,
    };

    try {
      const res = await NewServices.getListNew(paramsFilter);
      if (res) {
        setListNew(res.docs);
      }
    } catch (error) {}
  }
  function onGetDetailPage (el : any) {
    history.push({
      pathname: `/news/details/${el.url}`,
      search: `id=${el._id}`,
    });
    onCancel()
  }
  async function onBookMarkRelared(el: any) {
    const params = {
      articleId: el?._id,
    };
    const res = await NewServices.getBookMark(params);
    const arr: any = listNew.map((item: any) => {
      let itemTemp = item;
      // eslint-disable-next-line eqeqeq
      if (el._id == item._id) {
        itemTemp.isBookmark = !item.isBookmark;
      }
      return itemTemp;
    });
    setListNew(arr);
  }
  useEffect(() => {
    setListNew(listNew);
  }, [listNew]);

  return (
    <Modal
      title={
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="icon-arrow-backModel">
              <img src={arrow_back} onClick={onCancel} />
            </div>
            <BesinsInputSearch onChange={onChangeSearch} allowClear={true} />
          </div>
        </>
      }
      visible={visible}
      className={`SearchNewModel `}
      width={width ? width : "100%"}
      footer={null}
      centered={true}
      onCancel={onCancel}
      closable={closable}
    >
      {listNew.map((el: any) => {
        return (
          <>
            <Card className="latestCard-container">
              <div className="latestCard-content">
                <img
                  src={`${HOST_URL_IMAGE}/${el.images?.size.ONE_ONE?.url}`}
                  alt=""
                  className="latestCard-avatar"
                />
                <div className="latestCard-description" onClick={()=> onGetDetailPage(el)}>
                    <Paragraph
                      ellipsis={{ rows: 8, expandable: false }}
                      className="latestCard-title"
                    >
                      {el.title}
                    </Paragraph>
                  {/* <Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              className="latestCard-text"
            >
              {text}
            </Paragraph> */}
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <div className="d-flex align-items-center">
                      {/* <i className="icon icon-calendar mr-1"></i> */}
                      <span className="event__calendar-text">
                        {moment(el.createdAt).format("DD [Thg] MM, YYYY")}
                      </span>
                    </div>
                    <div onClick={() => onBookMarkRelared(el)}>
                      <img
                        src={el.isBookmark ? bookmark_click : bookmark}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </>
        );
      })}
    </Modal>
  );
}

InformationModal.propTypes = {};

export default InformationModal;
