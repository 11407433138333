import Types from "../actionTypes";
import { ProductOrder, Product, Order, OrderDetail } from "../../models/order.model";

export const actGetOrderProductList = (order_product_list: Array<Product>) => {
  return {
    type: Types.GET_ORDER_PRODUCT_LIST,
    order_product_list,
  };
};

export const actUpdateCart = (cart: Array<ProductOrder>) => {
  return {
    type: Types.UPDATE_CART,
    cart,
  };
};

export const actResetCart = () => {
  return {
    type: Types.RESET_CART,
  };
};

export const actGetSetOrder = (order: Order) => {
  return {
    type: Types.GET_SET_ORDER,
    order,
  };
};

export const actResetOrder = () => {
  return {
    type: Types.RESET_ORDER,
  };
};

export const actGetOrderDetail = (order_detail: OrderDetail) => {
  return {
    type: Types.GET_ORDER_DETAIL,
    order_detail,
  };
};

export const actResetOrderDetail = () => {
  return {
    type: Types.RESET_ORDER_DETAIL
  };
};
export const actSetUserNotification = (data: any) => {
  return {
    type: Types.SET_NOTIFICATION_USER,
    data,
  };
};

export const actSetUserAllNotices = (data: any) => {
  return {
    type: Types.SET_ALL_NOTICES_USER,
    data,
  };
};

export const actSetTotalNotification = (data: any) => {
  return {
    type: Types.SET_TOTAL_NOTIFICATION,
    data,
  };
};
