import React from "react";
//scss
import "./style.scss";

//images
import { arrow_back } from "../../variables/image";

//libraries
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import moment from "moment";

//models
import { RootState } from "../../models/index.model";
import { useHistory } from "react-router-dom";

type Props = {
  visible?: boolean;
  onClose?: any;
};

function PromotionDetail(props: Props) {
  const { onClose, visible } = props;
  let history = useHistory();
  const promotion_detail = useSelector(
    (state: RootState) => state.promotionReducer.promotion_detail
  );
  function handleClose() {
    onClose();
    history.replace("/promotions");
  }
  return (
    <div>
      <Drawer
        placement={"left"}
        closable={false}
        onClose={onClose}
        visible={visible}
        key={"left"}
        className="promotionDetail-drawer"
      >
        <div className="promotionDetail-body">
          <div className="header">
            <div className="title">Chi tiết khuyến mãi</div>
            <img src={arrow_back} alt="" onClick={handleClose} />
          </div>
          <div className="promotion-detail">
            <div className="promotion-content">
              <div className="title">Tên khuyến mãi</div>
              <div className="description">{promotion_detail?.title}</div>
            </div>

            <div className="promotion-content">
              <div className="title">Sản phẩm</div>
              <div className="description">
                {promotion_detail?.product?.name}
              </div>
            </div>

            <div className="promotion-content">
              <div className="title">Khuyến mãi</div>
              <div className="description">% {promotion_detail?.percent}</div>
            </div>

            <div className="promotion-content">
              <div className="title">Hiệu lực đến:</div>
              <div className="description">
                {moment(promotion_detail?.timeEnd).format("DD/MM/YYYY")}
              </div>
            </div>

            <div className="promotion-content">
              <div className="title">
                Điều kiện khuyến mãi <span>(Trên số lần mua)</span>
              </div>
              <div className="description">
                Số lượng tối thiểu phải mua:{" "}
                <span>{promotion_detail?.amount}</span> hộp
              </div>
              {promotion_detail?.maxPerMonth !== null && (
                <div className="description">
                  Số lượng tối đa được mua:{" "}
                  <span>{promotion_detail?.maxPerMonth}</span> hộp
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

PromotionDetail.propTypes = {};

export default PromotionDetail;
