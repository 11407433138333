import BesinsButton from "components/BesinsButton";
import { RootState } from "models/index.model";
import React, { FC, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actCheckPhoneExist, actResetAuth } from "store/actions/auth.action";
import { logoBesins } from "variables/image";
import logo from "../../assets/images/besins-logo.svg";
import "./style.scss";

interface IAuthLayout {
  title?: string;
  hasDescription?: boolean;
  children: ReactNode;
}

const AuthLayout: FC<IAuthLayout> = (props) => {
  const { title, children, hasDescription = true } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const register = useSelector(
    (state: RootState) => state.authReducer.register
  );
  return (
    <div className="layout-auth pl-3 pr-3">
      <div className="layout-auth-logo text-center mb-3">
        <img src={logo} alt="logo" />
      </div>
      <div className="layout-auth-title text-center">
        <div className="mb-3">{title}</div>
        {!!hasDescription && (
          <div className="layout-auth-desc text-center pr-5 pl-5">
            Vui lòng nhập mã xác thực đã được gửi vào số điện thoại{" "}
            <span className="desc-phone">{register.data.phone}</span>
          </div>
        )}
      </div>

      {children}

      <div className="layout-auth-footer text-center pb-3">
        Bạn đã có tài khoản ?
        <span
          className="footer-login ml-2"
          onClick={() => {
            localStorage.removeItem("access_token")
            dispatch(actCheckPhoneExist({initStep: "step1"}))
            history.push("/login")
          }}
        >
          Đăng nhập
        </span>
      </div>
    </div>
  );
};

export default AuthLayout;
