import BesinsButton from "components/BesinsButton";
import AuthLayout from "layouts/AuthLayout";
import React, { useState } from "react";
import CodeInput from "./CodeInput";
import "./style.scss";
import { notification } from "antd";
import AuthenticationService from "services/authentication.api";
import { RootState } from "models/index.model";
import { useDispatch, useSelector } from "react-redux";
import {
  actVerifyCodeError,
  actVerifyCodePending,
  actVerifyCodeSuccess,
} from "store/actions/auth.action";
import UserService from "services/user.api";
import { useHistory } from "react-router-dom";

const ConfirmCode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dataRegister = useSelector(
    (state: RootState) => state.authReducer.register
  );
  const [verifyAccount, setVerifyAccount] = useState(false);
  const handleOnChange = (value: string) => {
    if (value.length === 6) {
      handleSubmit(null, value);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setVerifyAccount(true);
    }
  }, []);
  const handleSubmit = async (e?: any, value?: string) => {
    dispatch(actVerifyCodePending());
    try {
      const res = await AuthenticationService.checkOTP({
        phone: dataRegister.data.phoneOrder,
        code: value,
      });
      if (res.code === -1) {
        dispatch(actVerifyCodeError({ error: res.error }));
      }
      if (res.code === 1) {
        if (verifyAccount) {
          await AuthenticationService.verifyAccount(dataRegister?.data?._id);
        } else {
          await UserService.createUser(dataRegister.data);
        }
        notification.success({
          message: "Đăng ký thành công! ",
        });
        history.push("/login");
      }
    } catch (error) {
      dispatch(actVerifyCodeError({ error: "Đã có lỗi xảy ra!" }));
    }
  };

  const resendCode = async () => {
    await AuthenticationService.getOTP({
      phone: dataRegister.data.phoneOrder,
    });
  };
  return (
    <AuthLayout title="Mã xác thực">
      <CodeInput onChange={handleOnChange} />
      <div className="layout-auth-help text-center">
        Chưa nhận được mã?
        <span
          className="ml-2 font-weight-bold text-resend"
          onClick={resendCode}
        >
          Gửi lại
        </span>
      </div>
      <BesinsButton block className="layout-auth-submit" onClick={handleSubmit}>
        Tiếp tục
      </BesinsButton>
    </AuthLayout>
  );
};

export default ConfirmCode;
