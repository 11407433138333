import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../models/index.model";
import { handleSocket } from "../../../variables/socket";
import { notification } from "antd";
import { socket_cms } from "../../../variables/connection";
import { icon_error_noti, icon_success_noti } from "../../../variables/image";
import "./style.scss";
import {
  actSetUserAllNotices,
  actSetTotalNotification,
} from "../../../store/actions/order.action";
import OrderService from "../../../services/order.api";
function NotificationPopUp() {
  const dispatch = useDispatch();
  const user_info = useSelector(
    (state: RootState) => state.profileReducer.user_info
  );
  async function getAllNotices() {
    let data = {
      limit: 10,
      page: 1,
      type: JSON.stringify(["ORDER"]),
    };
    await OrderService.getNotificationForUser(data).then((res: any) => {
      getTotalNotification();
      if (res.code === 1) {
        dispatch(actSetUserAllNotices(res.data));
      } else {
        //console.log(res.error);
      }
    });
  }

  async function getTotalNotification() {
    try {
      await OrderService.getTotalNotification().then((res: any) => {
        if (res.code === 1) {
          dispatch(actSetTotalNotification(res.data));
        } else {
          //console.log(res.error);
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  function renderContent(item: any) {
    switch (item.type) {
      //orders
      case "ADMIN_SUBMIT_ORDER":
        return (
          <span>
            Đã cập nhật trạng thái sang <b>đơn mới.</b>
          </span>
        );
      case "ADMIN_PROCESSING_ORDER":
        return <span>Đơn hàng đang được chờ xử lý</span>;
      case "ADMIN_PROCEED_ORDER":
        return (
          <>
            <div>Đơn hàng đã được xử lý</div>
          </>
        );
      case "ADMIN_DELIVERING_ORDER":
        return <span>Đơn hàng bắt đầu đi giao.</span>;
      case "ADMIN_COMPLETE_ORDER":
        return (
          <span>
            Đơn hàng đã được giao <b>thành công.</b>
          </span>
        );
      case "ADMIN_BACK_ORDER":
        return (
          <>
            <div>
              Đơn hàng đang chờ xử lý. Chúng tôi sẽ cập nhật và liên hệ cho Quý
              Bác sĩ
            </div>
          </>
        );
      case "ADMIN_CANCEL_ORDER":
        return (
          <span>
            Đã cập nhật trạng thái sang <b>đã hủy.</b>
          </span>
        );
      case "ADMIN_RETURN_ORDER":
        return (
          <span>
            Đã cập nhật trạng thái sang <b>trả hàng.</b>
          </span>
        );
      case "ADMIN_UPDATE_ORDER":
        return null;
      case "CREATE_ORDER_FOR_ADMIN":
        return <span>Đơn hàng được tạo thành công.</span>;
      case "ADMIN_RE_ORDER":
        return null;
      case "CREATE_ORDER_FOR_BUYER":
        return <span>Đơn hàng vừa được tạo.</span>;
      default:
        return "";
    }
  }

  function renderTitle(item: any) {
    switch (item.generalType) {
      case "ORDER":
        return `ĐƠN HÀNG ${item.order !== "" && `- ${item.order.orderId}`}`;
      default:
        break;
    }
  }

  const openNotificationWithIcon = (response: any) => {
    var type = "success";
    const config = {
      message: renderTitle(response),
      description: renderContent(response),
      duration: 8,
      className: `notification-socket ${
        type === "success" ? "success" : "error"
      }`,
      icon: (
        <img
          src={type === "success" ? icon_success_noti : icon_error_noti}
          alt=""
        />
      ),
    };
    if (type === "success") {
      notification.success(config);
    }
    if (type === "error") {
      notification.error(config);
    }
  };

  useEffect(() => {
    socket_cms.on("NOTIFICATION", (response: any) => {
      if (response) {
        getAllNotices();
        openNotificationWithIcon(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user_info._id) {
      handleSocket(user_info._id);
    }
  }, [user_info]);

  return <div></div>;
}

NotificationPopUp.propTypes = {};

export default NotificationPopUp;
