import moment from "moment";


export const formatDate = (dateString: string) => {
  var date = "";
  date = moment(dateString).format("YYYY-MM-DD");
  return date;
}

export const formatStringToDate = (dateString: string, lang: string) => {
  var date = "";
  if (lang === "EN") {
    date = moment(dateString).format("ll");
  }
  if (lang === "VN") {
    date = moment(dateString).format("DD [Thg] MM, YYYY");
  }
  return date;
};
  
// export function generateGreetings(dateString: string, lang: string) {
//   let CurrentDay = moment(dateString).format("L")
// }


export const timeAgo = (dateString: string, lang: string) => {
  let now = new Date();
  let datefromAPITimeStamp = new Date(dateString).getTime();
  let nowTimeStamp = now.getTime();
  let microSecondsDiff = Math.abs(datefromAPITimeStamp - nowTimeStamp);
  // Number of milliseconds per day =
  //   24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 msecs/second
  let daysDiff = microSecondsDiff / (1000 * 60 * 60 * 24);
  let hoursDiff = Math.floor(microSecondsDiff / (1000 * 60 * 60));
  let minutesDiff = Math.floor(microSecondsDiff / (1000 * 60));
  if (daysDiff > 1) {
    return formatStringToDate(dateString, lang);
  }
  if (lang === "EN") {
    if (hoursDiff >= 1 && hoursDiff < 24) {
      return `${hoursDiff} hour ago`;
    }
    if (minutesDiff >= 1 && minutesDiff < 60) {
      return `${minutesDiff} min ago`;
    }
    if (minutesDiff >= 0 && minutesDiff < 1) {
      return `Just Now`;
    }
  }
  if (lang === "VN") {
    if (hoursDiff >= 1 && hoursDiff < 24) {
      return `${hoursDiff} giờ trước`;
    }
    if (minutesDiff >= 1 && minutesDiff < 60) {
      return `${minutesDiff} phút trước`;
    }
    if (minutesDiff >= 0 && minutesDiff < 1) {
      return `Vừa xong`;
    }
  }
  
};
