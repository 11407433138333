import React, { useEffect, useState } from "react";
import "./style.scss";
//host
import HOST_URL_IMAGE from "../../variables/hostUrl";

//images
import { logo, promotion } from "../../variables/image";

//func
import { formatMoney } from "../../utils/formatMoney";

//libraries
import { useSelector, useDispatch } from "react-redux";

//models
import { RootState } from "../../models/index.model";

//actions
import { actUpdateCart } from "../../store/actions/order.action";

type Props = {
  unitPrice?: string;
  unitProduct?: string;
  productItem?: any;
};
function BesinsProductOrder(props: Props) {
  const dispatch = useDispatch();
  //props
  const { unitPrice, unitProduct, productItem } = props;
  const cart = useSelector((state: RootState) => state.orderReducer.cart);

  //states
  const [quantity, setQuantity] = useState(0);
  const updateQuantityItem = (quantity: number) => {
    const itemIndex = cart.findIndex((p) => p._id === productItem._id);
    if (itemIndex >= 0) {
      let cartCopy = [...cart];
      cartCopy[itemIndex].quantity = quantity;
      dispatch(actUpdateCart(cartCopy));
    }
  };

  const addToCart = (quantity: number) => {
    const productOrder = productItem;
    productOrder.quantity = quantity;
    productOrder.remainingQuantity =
      productOrder.maxPerMonth > 0
        ? productOrder.maxPerMonth - productOrder.orderedAmount
        : 0;
    dispatch(actUpdateCart([...cart, productOrder]));
  };

  const increase = () => {
    // updateQuantityItem(cartItem.quantity + 1);
    const itemIndex = cart.findIndex((p) => p._id === productItem._id);
    if (itemIndex <= -1) {
      addToCart(1);
    } else {
      updateQuantityItem(quantity + 1);
    }
    setQuantity(quantity + 1);
  };

  const deleteProduct = () => {
    const itemIndex = cart.findIndex((p) => p._id === productItem._id);
    if (itemIndex >= 0) {
      let cartCopy = [...cart];
      cartCopy.splice(itemIndex, 1);
      dispatch(actUpdateCart(cartCopy));
    }
  };

  const decline = () => {
    let currentQuantity = quantity;
    if (currentQuantity - 1 > 0) {
      setQuantity(quantity - 1);
      updateQuantityItem(quantity - 1);
    } else {
      deleteProduct();
      updateQuantityItem(0);
      setQuantity(0);
    }
  };

  const onChangeNumber = (e: any) => {
    const regexp = /^[0-9]*$/;
    const checkingNumber = regexp.exec(e.target.value);
    let number = parseInt(e.target.value);
    const itemIndex = cart.findIndex((p) => p._id === productItem._id);
    if (e.target.value !== "") {
      if (checkingNumber === null) {
        return null;
      } else {
        setQuantity(number);
      }
    } else {
      setQuantity(number);
    }
    if (itemIndex <= -1) {
      addToCart(number);
    } else {
      updateQuantityItem(number);
    }
  };

  const onBlurNumber = (e: any) => {
    const regexp = /^[0-9]*$/;
    const checkingNumber = regexp.exec(e.target.value);
    let number = parseInt(e.target.value);
    const itemIndex = cart.findIndex((p) => p._id === productItem._id);

    if (e.target.value === "") {
      if (itemIndex >= -1) {
        deleteProduct();
      } else {
        setQuantity(0);
      }
    } else {
      if (checkingNumber === null) {
        return null;
      } else {
        if (number < 1) {
          deleteProduct();
          setQuantity(0);
        } else {
          updateQuantityItem(number);
          setQuantity(number);
        }
      }
    }
  };

  async function filterPromotion(quantity: number) {
    let itemIndex = cart.findIndex((p) => p._id === productItem._id);
    let cartItem = [...cart];
    let promotionList = cartItem[itemIndex]?.promotion?.filter((item: any) => {
      if (item.maxPerMonth === 0) {
        return item.amount <= quantity;
      }
      const maxQuantity = item.maxPerMonth - item.orderedAmount;
      return quantity >= item.amount && quantity <= maxQuantity;
    });
    findMaxQuantity(promotionList);
  }

  async function findMaxQuantity(arrayPromotion: any) {
    let arrPercent: []  = arrayPromotion?.map((item: any) => item.percent);
    let maxAmount: number = 0;
    arrPercent?.forEach((e) => {
      if(e > maxAmount){
        maxAmount = e;
      }
    })
    const promotion = arrayPromotion?.filter(
      (item: any) => item.percent === maxAmount
    );
    setAvailablePromotion(promotion);
  }

  async function setAvailablePromotion(promotion: any) {
    let itemIndex = cart.findIndex((p) => p._id === productItem._id);
    let cartItem = [...cart];
    if (itemIndex >= 0) {
      cartItem[itemIndex].availablePromotion = promotion?.[0]!;
      dispatch(actUpdateCart(cartItem));
    }
  }

  async function totalProduct(quantity: number) {
    let total = 0;
    let itemIndex = cart.findIndex((p) => p._id === productItem._id);
    let cartItem = [...cart];
    //nếu có promotion thích hợp
    if (cartItem[itemIndex]?.availablePromotion!) {
      total =
        quantity * cartItem[itemIndex]?.price -
        (quantity *
          cartItem[itemIndex]?.price *
          cartItem[itemIndex]?.availablePromotion?.percent!) /
          100;
    } else {
      // nếu ko có promotion
      total = cartItem[itemIndex]?.price * quantity;
    }
    if (itemIndex >= 0) {
      cartItem[itemIndex].total = total;
      dispatch(actUpdateCart(cartItem));
    }
  }

  useEffect(() => {
    let itemIndex = cart.findIndex((p) => p._id === productItem._id);
    let cartCopy = [...cart];
    if (itemIndex >= 0) {
      setQuantity(cartCopy[itemIndex].quantity);
    } else {
      setQuantity(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    filterPromotion(quantity);
    totalProduct(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <div className="productOrder">
      <div className="productOrder-item">
        <div className="productOrder-item__content">
          <div className="productOrder-item__img">
            {productItem.thumnail !== "" && (
              <img src={`${HOST_URL_IMAGE}${productItem?.thumnail}`} alt="" />
            )}
            {productItem.thumnail === "" && (
              <img src={logo} alt="" width="64" />
            )}
          </div>
          <div className="productOrder-item__info">
            <div className="productOrder-info__name">{productItem.name}</div>
            <div className="productOrder-info__price">
              <span className="unit-price">{unitPrice}</span>
              <span className="original-price">
                {" "}
                {formatMoney(productItem.price)}
              </span>
              <span className="unit-product"> / {unitProduct}</span>
            </div>
            <div className="productOrder-item_buttons">
              <div className="number-input">
                <button
                  onClick={decline}
                  // disabled={currentStep === 1 ? false : true}
                  disabled={quantity === 0 ? true : false}
                ></button>

                <input
                  className="quantity"
                  name="quantity"
                  type="number"
                  value={quantity}
                  defaultValue={quantity}
                  onChange={(e) => onChangeNumber(e)}
                  onBlur={(e) => onBlurNumber(e)}
                />

                <button
                  className="plus"
                  onClick={increase}
                  // disabled={currentStep === 1 ? false : true}
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {productItem?.promotion?.map((item: any) => {
        return (
          <div className="productOder-promotion__list">
            <div className="promotion-item">
              <img src={promotion} alt="promotion" className="promotion-img" />
              <div className="promotion-info">
                <div className="promotion-info__title">
                  <span className="text">{item && item.title}</span>{" "}
                  <span className="percent">-{item && item.percent}%</span>
                  <span className="info">
                    {item.maxPerMonth > 0 && (
                      <>
                        <span className="info_text"> (Số lượng còn lại: </span>
                        {item.maxPerMonth - item.orderedAmount} hộp)
                      </>
                    )}
                  </span>
                </div>
                <div className="promotion-info__describe">
                  {item && item.description}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

BesinsProductOrder.propTypes = {};

export default BesinsProductOrder;
