import callApi from "../utils/callApi";

const AuthenticationService = {
  login: async (data: any) => {
    const endpoint = `cms/user/login_by_phone_number`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  checkPhone: async (data: any) => {
    const endpoint = `cms/user/check_verification_for_customer`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  checkEmail: async (data: any) => {
    const endpoint = `user/check-email-existed`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  
  getOTP: async (data: any) => {
    const endpoint = `sms/get_otp`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  checkOTP: async (data: any) => {
    const endpoint = `sms/check_otp`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  updatePassword: async (data: any) => {
    const endpoint = `cms/user/set_password_for_doctor`;
    const res = await callApi(endpoint, "PUT", data);
    return res;
  },
  getCurrentUser: async () => {
    const endpoint = `user/current_user`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  changePassword: async (data: any) => {
    const endpoint = `user/doctor_change_password`;
    const res = await callApi(endpoint, "PUT", data);
    return res;
  },
  checkPhoneExist: async (data: { phone: string }) => {
    const endpoint = `user/check_phone_existed`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  verifyAccount: async (data: any) => {
    const endpoint = `user/verify-doctor/${data}`;
    const res = await callApi(endpoint, "PUT");
    return res;
  },
  getOTPSurvey: async (data: any) => {
    const endpoint = `sms/get_otp_survey`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
};
export default AuthenticationService;
