import BesinsInput from "components/BesinsInput";
import { Formik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import SelectBesins from "components/SelectIcon";
import { RootState } from "models/index.model";
import { useDispatch, useSelector } from "react-redux";
import {
  actGetDistrict,
  actGetProvince,
  actGetWard,
} from "store/actions/profile.action";
import UserService from "services/user.api";
import BesinsButton from "components/BesinsButton";
import AuthLayout from "layouts/AuthLayout";
import { useHistory } from "react-router-dom";
import { notification, Tooltip } from "antd";
import { convertText } from "utils/convertText";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./style.scss";
import { actCheckPhoneExist } from "store/actions/auth.action";
import AuthenticationService from "services/authentication.api";
import BesinInputTextArea from "components/BesinsInput/BesinInputTextArea";

interface FormValues {
  fullName: string;
  office: string;
  address: string;
  provinceCode: Number | null;
  districtCode: Number | null;
  wardCode: Number | null;
  content: string | null;
}

interface IInfo {
  phone: Number;
  provinceCode: Number | null;
  districtCode: Number | null;
  wardCode: Number | null;
}
const FormInfo = () => {
  const dispatch = useDispatch();
  //State
  const [userOtp, setUserOtp] = React.useState<any>();
  const [checkNoOtp, setCheckNoOtp] = React.useState(false);
  const [checkContent, setCheckContent] = React.useState(false);
  // Call Store
  const data = useSelector(
    (state: RootState) => state.authReducer.register.data
  );
  const { profile, details = {} } = useSelector(
    (state: RootState) => state.profileReducer?.doctor_info
  );
  const { province, district, ward } = useSelector(
    (state: RootState) => state.profileReducer.initData
  );
  const { provinceCode, districtCode, wardCode } = details! as IInfo;
  // Hook
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      getCurrentUser();
    }
  }, []);
  async function getCurrentUser() {
    try {
      const res = await UserService.getDoctorInfo();
      setUserOtp(res);
      setCheckNoOtp(true);
    } catch (error) { }
  }
  function removeAccents(str: string) {
    return str.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd').replace(/Đ/g, 'D');
  }
  //Form 
  const formSchema = yup.object({
    fullName: yup.string().required("Tên không được để trống"),
    office: yup
      .string()
      .required("Tên Bệnh viện / Phòng khám không được để trống"),
    address: yup
      .string()
      .required("Địa chỉ Bệnh viện / Phòng khám không được để trống")
    ,
  });
  async function SendContent(values: any) {
    const contentInfo = {
      fullName: convertText(values.fullName).toUpperCase(),
      email: userOtp?.email,
      phoneOrder: userOtp.profile?.phoneOrder,
      content: values.content,
    };
    const data = {
      type : false,
      content: contentInfo
    }

    await UserService.sendContentUpdate(data);
    notification.success({
      message: "Gửi thông tin thành công !",
    });
  }
  const initialFormValues: FormValues = {
    fullName: checkNoOtp ? userOtp?.profile?.fullName : "",
    office: checkNoOtp ? userOtp?.details?.office : "",
    address: checkNoOtp ? userOtp?.details?.address : "",
    provinceCode: checkNoOtp ? userOtp?.details?.provinceCode : null,
    districtCode: checkNoOtp ? userOtp?.details?.districtCode : null,
    wardCode: checkNoOtp ? userOtp?.details?.wardCode : null,
    content: null,
  };
  const onSubmit = async (values: FormValues) => {
    const provinceUser = province.find(
      (e: any) => e.value === values.provinceCode
    );
    const districtUser = district.find(
      (e: any) => e.value === values.districtCode
    );
    const wardUser = ward.find((e: any) => e.value === values.wardCode);
    try {
      const dataDispatch = {
        ...values,
        fullName: convertText(values.fullName).toUpperCase(),
        province: provinceUser?.label,
        district: districtUser?.label,
        ward: wardUser?.label,
        phoneOrder: checkNoOtp ? userOtp?.profile?.phoneOrder : data.phone,
        email: checkNoOtp ? userOtp?.email : data.email,
        password: checkNoOtp ? userOtp?.email : data.password,
        _id: checkNoOtp ? userOtp?._id : "",
      };
      const resOTP = await AuthenticationService.getOTP({
        phone: checkNoOtp ? userOtp?.profile?.phoneOrder : data?.phone,
      });
      if (checkContent && checkNoOtp) {
        SendContent(values);
      }
      if (resOTP.code === 1) {
        dispatch(
          actCheckPhoneExist({
            initStep: "step3",
            data: dataDispatch,
          })
        );
      }
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra !",
      });
    }
  };

  const getProvince = async () => {
    const res = await UserService.getProvince();
    const mapProvince = res.map((item: any) => ({
      label: item.name,
      value: item.provinceId,
    }));
    dispatch(actGetProvince(mapProvince));
  };

  const getDistrict = async (id: any) => {
    const res = await UserService.getDistrict(id);
    const mapDistrict = res.map((item: any) => ({
      label: item.name,
      value: item.districtId,
    }));
    dispatch(actGetDistrict(mapDistrict));
  };

  const getWard = async (id: any) => {
    const res = await UserService.getWard(id);
    const mapWard = res.map((item: any) => ({
      label: item.name,
      value: item.wardId,
    }));
    dispatch(actGetWard(mapWard));
  };

  const handleChangeProvince = async (id: any, setFieldValue: any) => {
    setFieldValue("provinceCode", id);
    setFieldValue("districtCode", null);
    setFieldValue("wardCode", null);
    getDistrict(id);
  };

  const handleChangeDistrict = async (id: any, setFieldValue: any) => {
    setFieldValue("districtCode", id);
    setFieldValue("wardCode", null);
    getWard(id);
  };

  const handleChangeWard = (id: string, setFieldValue: any) => {
    setFieldValue("wardCode", id);
  };

  useEffect(() => {
    getProvince();
  }, []);

  useEffect(() => {
    if (provinceCode) getDistrict(provinceCode);
  }, [provinceCode]);

  useEffect(() => {
    if (districtCode) getWard(districtCode);
  }, [districtCode]);

  return (
    <AuthLayout title="Đăng ký" hasDescription={false}>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <div className="formRegister form-register">
            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                label="Họ tên"
                markup="Nhập chữ hoa, không dấu"
                placeholder="Nhập họ và tên"
                value={values.fullName}
                onChange={(e: any) => setFieldValue("fullName", e.target.value)}
                onBlur={() => setFieldTouched("fullName")}
                prefix={
                  <Tooltip title="Nhập chữ hoa, không dấu">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
                readOnly={checkNoOtp ? true : false}
              />
              {errors && touched.fullName && (
                <div className="input-error-message mt-1">
                  {errors.fullName}
                </div>
              )}
            </div>
            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                label="Bệnh viện / Nơi công tác"
                placeholder="Nhập Bệnh viện / Nơi công tác"
                value={values.office}
                onChange={(e: any) => setFieldValue("office", e.target.value)}
                onBlur={() => setFieldTouched("office")}
                readOnly={checkNoOtp ? true : false}
              />
              {errors && touched.office && (
                <div className="input-error-message mt-1">{errors.office}</div>
              )}
            </div>
            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                label="Địa chỉ nhà hoặc phòng mạch tư"
                placeholder="Địa chỉ nhà hoặc phòng mạch tư (Số nhà và tên đường)"
                value={values.address}
                onChange={(e: any) => {
                  setFieldValue("address", removeAccents(e.target.value))
                }}
                onBlur={() => setFieldTouched("address")}
                readOnly={checkNoOtp ? true : false}
              />
              {errors && touched.address && (
                <div className="input-error-message mt-1">{errors.address}</div>
              )}
            </div>
            <SelectBesins
              className="mb-3"
              allowClear={false}
              label="Tỉnh / Thành phố"
              placeholder="Chọn Tỉnh / Thành phố"
              optionFilterProp="label"
              filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              option={province}
              value={values.provinceCode}
              onChange={(value: any) =>
                handleChangeProvince(value, setFieldValue)
              }
              disabled={checkNoOtp ? true : false}
            />

            <SelectBesins
              className="mb-3"
              allowClear={false}
              label="Quận / Huyện"
              placeholder="Chọn Quận / Huyện"
              optionFilterProp="label"
              filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              option={district}
              value={
                checkNoOtp ? userOtp?.details.district : values.districtCode
              }
              onChange={(value: any) =>
                handleChangeDistrict(value, setFieldValue)
              }
              disabled={
                district.length <= 0 ||
                  typeof values.provinceCode !== "string" ||
                  checkNoOtp
                  ? true
                  : false
              }
            />
            <SelectBesins
              className="mb-3"
              allowClear={false}
              label="Phường / Xã"
              placeholder="Chọn Phường / Xã"
              optionFilterProp="label"
              filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              option={ward}
              value={checkNoOtp ? userOtp?.details.ward : values.wardCode}
              onChange={(value: any) => handleChangeWard(value, setFieldValue)}
              disabled={
                ward.length <= 0 ||
                  typeof values.districtCode !== "string" ||
                  checkNoOtp
                  ? true
                  : false
              }
            />
            {checkNoOtp ? (
              <>
                <div className="mb-3">
                  <BesinsInput
                    className="input-custom"
                    label="Số điện thoại"
                    value={userOtp.profile.phoneOrder}
                    readOnly={true}
                  />
                </div>
                <div className="mb-3">
                  <BesinsInput
                    className="input-custom"
                    label="Email"
                    value={userOtp?.email}
                    readOnly={true}
                  />
                </div>
                <BesinInputTextArea
                  className="input-custom"
                  label="Ghi Chú"
                  placeholder="Nhập nội dung ghi chú cần và gửi cho quản trị viên "
                  value={values.content}
                  onChange={(e: any) => {
                    setFieldValue("content", e.target.value);
                    if (e.target.value.length > 0) {
                      setCheckContent(true);
                    }
                  }}
                  onBlur={() => setFieldTouched("content")}
                />
              </>
            ) : (
                ""
              )}
            <div className="register__content">
              &nbsp; BHUP2D là nền tảng trực tuyến dành cho nhân viên y tế, nhằm
              mục đích cung cấp thông tin hội thảo, thông tin y khoa. Vui lòng
              kiểm tra tất cả thông tin trên để đảm bảo tất cả thông tin trên
              đều chính xác. Khi Bấm “Đăng ký” và sử dụng ứng dụng BHUP2D, điều
              đó có nghĩa là Quý thành viên đã đăng ký và chịu trách nhiệm với
              các thông tin, hoạt động và tương tác của Quý thành viên trên Ứng
              dụng BHUP2D. Nếu không đồng ý, Quý thành viên vui lòng không sử
              dụng dịch vụ hoặc ngừng truy cập ứng dụng BHUP2D
            </div>
            <BesinsButton
              className="layout-auth-submit"
              block
              onClick={handleSubmit}
            >
              Đăng ký
            </BesinsButton>
          </div>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default FormInfo;
