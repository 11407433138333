import BesinsCarousel from "components/BesinsCarousel";
import React, { useEffect, useState } from "react";
import NewServices from "services/news.api";
import HomeCard from "./HomeCard";
import { useHistory } from "react-router-dom";
// import { ClipLoader } from "react-spinners";

interface CardNews {}
const CardNews = (props: CardNews) => {
  const [article, setArticle] = useState([]);
  const history = useHistory();

  async function getData() {
    try {
      const res = await NewServices.getNews("");
      if (res) {
        setArticle(res.docs);
      }
    } catch (err) {}
  }

  function handleClick(url: any, id: string) {
    history.push({
      pathname: `/news/details/${url}`,
      search: `id=${id}`,
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <BesinsCarousel arrows={false}>
      {article &&
        article.map((el: any) => {
          return (
            <HomeCard
              key={el._id}
              data={el}
              handleClick={() => handleClick(el.url, el._id)}
            />
          );
        })}
    </BesinsCarousel>
  );
};

export default React.memo(CardNews);
