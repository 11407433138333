import React from "react";
import { Card } from "antd";
import "./style.scss";
import Paragraph from "antd/lib/typography/Paragraph";
import HOST_URL_IMAGE from "variables/hostUrl";
import { bookmark_click_news_icon, bookmark_news_icon } from "variables/image";
import NewServices from "services/news.api";
interface CardsProps {
  data?: any;
  handleClick?: any;
}

const Cards = (props: CardsProps) => {
  const { handleClick, data } = props;
  const [stateBookmark, setStateBookmark] = React.useState(data?.isBookmark);
  const Bookmark_Click = async () => {
    const param = {
      articleId: data?._id,
    };
    try {
      const res = await NewServices.getBookMark(param);
      if (res?.data === "BOOK_MARK_SUCCESS") {
        setStateBookmark(true);
      } else {
        setStateBookmark(false);
      }
    } catch (error) {}
  };
  return (
    <Card
      hoverable
      cover={
        <img
          alt="example"
          src={`${HOST_URL_IMAGE}/${data.images?.size.SIXTEEN_NINE?.url}`}
          onClick={handleClick}
        />
      }
    >
      <div className="icon-storage-news" onClick={Bookmark_Click}>
        <img
          src={stateBookmark ? bookmark_click_news_icon : bookmark_news_icon}
          alt="icon-storage-news"
        />
      </div>
      <div className="card-wrap" onClick={handleClick}>
        <div className="card-wrap-content">
          <div className="tilte mb-1">
            <Paragraph
              ellipsis={{ rows: 3, expandable: false }}
              className="card__desc"
            >
              {data.title}
            </Paragraph>
          </div>
          <div className="tilte mb-2">
            <Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              className="card__title"
            >
              {data.description}
            </Paragraph>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(Cards);
