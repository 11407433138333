/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import momentTimezone from "moment-timezone";
// styles
import "./style.scss";
// images
import FILTER from "assets/images/filter.svg";
import { arrow_back, icon_sale } from "../../variables/image";
// services
import PromotionService from "../../services/promotion.api";
// store redux
import { useSelector, useDispatch } from "react-redux";
import {
  actGetPromotionList,
  actGetPromotionDetail,
} from "../../store/actions/promotion.action";
// models
import { RootState } from "../../models/index.model";
// components
import { Dropdown, Button, Menu, Pagination, Empty } from "antd";
import BesinsInputSearch from "../../components/BesinsInputSearch";
import PromotionDetail from "../../components/PromotionDetail";
import { ClipLoader } from "react-spinners";
import PromotionFilter from "../../components/PromotionFilter";
import { useHistory } from "react-router-dom";

type Props = {
  history?: any;
  match?: any;
};

function PromotionPage(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  const promotion_list = useSelector(
    (state: RootState) => state.promotionReducer.promotion_list
  );
  const user_info = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );
  if(user_info?.details?.isOrderable=== false){
    promotion_list.docs = []
  }
 
  const historyState = props.history.location.state;
  const [valueSearch, setValueSearch] = useState("");
  const [page, setPage] = useState(historyState?.page ? historyState.page : 1);
  const [limit, setLimit] = useState(
    historyState?.limit ? historyState?.limit : 10
  );
  const [filter, setFilter] = useState({
    products: [],
  });
  const [loading, setLoading] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleDetail, setVisibleDetail] = useState(false);

  useEffect(() => {
    onLoading();
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let variables = { page, limit, search: valueSearch, ...filter };
    handleGetPromotionList(variables);
  }, [page, limit, valueSearch]);

  function onLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function getPromotionDetail(id: any) {
    try {
      let res = await PromotionService.getPromotion(id);
      if (res.code === 1) {
        dispatch(actGetPromotionDetail(res.data));
      }
    } catch (err) {
      //console.log(err);
    }
  }

  async function handleGetPromotionList(variables: any) {
    try {
      let res = await PromotionService.getListPromotion(variables);
      if (res.code === 1) {
        dispatch(actGetPromotionList(res.data));
      }
    } catch (err) {
      //console.log(err);
    }
  }

  function handleSubmitFilter() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    props.history.push(`/promotions?page=${1}`, {
      page: page,
      limit: limit,
    });
    let variables = { page: 1, limit, search: valueSearch, ...filter };
    handleGetPromotionList(variables);
    setVisibleFilter(false);
  }

  function handleChangeSearch(e: any) {
    setValueSearch(e.target.value);
    setPage(1);
  }

  function handleChangePagination(page: any, pageSize: any) {
    setPage(page);
    setLimit(pageSize);
    props.history.push(`/promotions?page=${page}`, {
      page: page,
      limit: pageSize,
    });
  }

  const memuItemPromotion = (item: any) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setVisibleDetail(true);
          getPromotionDetail(item._id);
        }}
      >
        Xem chi tiết
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PromotionFilter
        data={promotion_list?.product_infor}
        filter={filter}
        submit={handleSubmitFilter}
        setFilter={(data: any) => setFilter(data)}
        visible={visibleFilter}
        onClose={() => setVisibleFilter(false)}
      />
      <PromotionDetail
        onClose={() => setVisibleDetail(false)}
        visible={visibleDetail}
      />
      <div className="promotion-page">
        <div className="header d-flex">
          <img
            className="goBack"
            src={arrow_back}
            onClick={() => history.push("/")}
            alt=""
          />
          <h3 className="title text-center">Thông tin khuyến mãi</h3>
        </div>
        <div className="promotion-activity">
          <div style={{ width: "calc(100% - 100px)" }}>
            <BesinsInputSearch
              value={valueSearch}
              onChange={handleChangeSearch}
            />
          </div>
          <div
            className="d-flex align-items-center"
            onClick={() => setVisibleFilter(true)}
          >
            <img src={FILTER} alt="filter" className="ml-4 mr-2" />
            <span>Lọc</span>
          </div>
        </div>

        {loading && (
          <div
            className="promotion-list d-flex align-items-center justify-content-center"
            style={{ height: "400px" }}
          >
            <ClipLoader loading={true} size={30} color={"#0073C4"} />
          </div>
        )}

        {promotion_list?.docs?.length === 0 && (
          <div
            className="promotion-list d-flex align-items-center justify-content-center"
            style={{ height: "400px" }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{
                height: 60,
              }}
              description={<span>Danh sách khuyến mãi không tìm thấy</span>}
            ></Empty>
          </div>
        )}

        {!loading && promotion_list?.docs?.length > 0 && (
          <>
            <hr style={{ margin: "16px 0" }} />
            <div className="promotion-list">
              {promotion_list?.docs.map((item: any, key) => {
                return (
                  <div className="promotion-item-wrapper" key={key}>
                    <div className="promotion-item">
                      <div
                        className="d-flex align-items-start"
                        style={{ width: "calc(100% - 100px)" }}
                      >
                        <img src={icon_sale} alt="icon sale" />

                        <div className="ml-3">
                          <div
                            className="ink-caption1 mb-3"
                            onClick={() => {
                              setVisibleDetail(true);
                              getPromotionDetail(item._id);
                            }}
                          >
                            {item.title}
                          </div>
                          <div className="ink-caption2 mb-1">
                            Sản phẩm: <b>{item.products.name}</b>
                          </div>
                          <div className="ink-lightest-caption2">
                            HSD:{" "}
                            {momentTimezone
                              .tz(item.timeStart, "Asia/Ho_Chi_Minh")
                              .utc()
                              .format("DD/MM/YYYY")}{" "}
                            -{" "}
                            {momentTimezone
                              .tz(item.timeEnd, "Asia/Ho_Chi_Minh")
                              .utc()
                              .format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Dropdown
                          overlay={() => memuItemPromotion(item)}
                          placement="bottomRight"
                          trigger={["click"]}
                          overlayClassName={"dropdown-item-promotion"}
                        >
                          <Button
                            icon={
                              <i
                                className="icon icon-more-horiz"
                                style={{ fontSize: 18 }}
                              />
                            }
                            style={{ borderRadius: 4 }}
                          />
                        </Dropdown>
                      </div>
                    </div>
                    <hr style={{ margin: "16px 0" }} />
                  </div>
                );
              })}
            </div>
            <hr style={{ margin: "16px 0" }} />
            <div className="text-right">
              <Pagination
                total={promotion_list?.totalDocs}
                pageSize={promotion_list?.limit}
                defaultPageSize={promotion_list?.limit}
                current={promotion_list?.page}
                defaultCurrent={promotion_list?.page}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                showSizeChanger={true}
                showLessItems={true}
                pageSizeOptions={["5", "10", "20", "30", "40", "50"]}
                onChange={handleChangePagination}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PromotionPage;
