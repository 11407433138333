import { socket_cms } from "./connection";

var handleSocket = async function (userId: string) {
  socket_cms.emit("INIT_CONNECTION", { userId });
};

var listenNotiFromServerCMS = function (callback: any) {
  socket_cms.on("NOTIFICATION", (response: any) => {
    if (
      Object.entries(response).length !== 0 &&
      response.constructor === Object
    ) {
      callback(response);
    } else {
      callback([]);
    }
  });
};

var socketOnServer = function (callback: any) {
  socket_cms.on("RESPONSE_CONNECTION", (response: any) => {
    if (
      Object.entries(response).length !== 0 &&
      response.constructor === Object
    ) {
      callback(response);
    } else {
      callback([]);
    }
  });
};

export { handleSocket, listenNotiFromServerCMS, socketOnServer };
