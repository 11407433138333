import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventService from "services/event.api";
import {
  actGetEventAll,
  actGetEventDetail,
  actGetEventUpComing,
  actLoadMoreEvents,
  actLoadMoreEventsUpComing,
} from "store/actions/event.action";
import BesinsButton from "components/BesinsButton";
import BessinsCard from "components/BesinsCard";
import ShowSearchModal from "components/BesinsModal/showSearchModal";
import { normalize } from "normalizr";
import { eventSchema } from "schema/Event";
import Spinner from "components/Spinner";
import { RootState } from "models/index.model";
import { useHistory, useLocation } from "react-router-dom";
import WithNavigation from "layouts/WithNavigation";
import "./style.scss";
import { icon_help, search } from "variables/image";
import { Tabs } from "antd";

type Props = {
  placeholder?: string;
  name?: string;
  onChange?: any;
  value?: any;
};

const { TabPane } = Tabs;

function EventPage(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [modelSearch, setModelSearch] = useState(false);
  const [
    currentPageEventUpComing,
    setCurrentPageEventUpComing,
  ] = useState<number>(1);
  const [flag, setFlag] = useState(query.get("url") !== null);
  const [activeTab, setActiveTab] = useState("1");

  const { entitiesEvent = {}, eventId = [], events } = useSelector(
    (state: RootState) => state.eventReducer.events
  );

  const {
    entitiesEvent: entitiesEventUpComing = {},
    eventId: eventUpComingId = [],
    events: eventsUpComingList,
  } = useSelector((state: RootState) => state.eventReducer.eventsUpComing);

  const hasNextPage = events.hasNextPage || false;
  const hasNextPageEventUpComing = eventsUpComingList.hasNextPage || false;

  // const callback = () => {};

  const handleOnClickCard = async (url: string, id: string) => {
    history.push(`/events/details/${url}?id=${id}`);
    // try {
    //   const {  data: eventDetail } = await EventService.getEventDetail(
    //     url
    //   );
    //     dispatch(
    //       actGetEventDetail(eventDetail)
    //     );
    // } catch {}
  };

  async function getAllEvents() {
    setLoading(true);
    try {
      const { code, data, entities, result } = await callEvent(1);
      if (code === 1) {
        dispatch(
          actGetEventAll({
            events: data,
            entitiesEvent: entities.events,
            eventId: result,
          })
        );
        setLoading(false);
      }
    } catch {}
  }

  async function getAllEventUpComing() {
    setLoading(true);
    try {
      const { code, data, entities, result } = await callEventUpComing(1);

      if (code === 1) {
        dispatch(
          actGetEventUpComing({
            events: data,
            entitiesEvent: entities.events,
            eventId: result,
          })
        );
        setLoading(false);
      }
    } catch {}
  }

  const callEvent = async (page: number) => {
    const params = {
      page,
      limit: 3,
    };
    const { code, data } = await EventService.getEvents(params);
    const { entities, result } = normalize(data.docs, [eventSchema]);
    return { code, data, entities, result };
  };

  const callEventUpComing = async (page: number) => {
    const params = {
      page,
      limit: 3,
    };
    const { code, data } = await EventService.getEventsUpComing(params);

    const { entities, result } = normalize(data.docs, [eventSchema]);
    return { code, data, entities, result };
  };

  const handleLoadMore = async () => {
    setActiveTab("2");
    setCurrentPage(currentPage + 1);
    setLoadMore(true);
    try {
      const { code, data, entities, result } = await callEvent(currentPage + 1);
      if (code === 1) {
        setTimeout(() => {
          dispatch(
            actLoadMoreEvents({
              events: data,
              entitiesEvent: entities.events,
              eventId: result,
            })
          );
          setLoadMore(false);
        }, 500);
      }
    } catch {}
  };

  const handleLoadMoreEventUpComing = async () => {
    setActiveTab("1");
    setCurrentPageEventUpComing(currentPageEventUpComing + 1);
    setLoadMore(true);
    try {
      const { code, data, entities, result } = await callEventUpComing(
        currentPage + 1
      );
      if (code === 1) {
        setTimeout(() => {
          dispatch(
            actLoadMoreEventsUpComing({
              events: data,
              entitiesEvent: entities.events || {},
              eventId: result,
            })
          );
          setLoadMore(false);
        }, 500);
      }
    } catch {}
  };

  useEffect(() => {
    if(query.get('url') === null){
    getAllEvents();
    getAllEventUpComing();
    }
  }, [query.get('url')]);

  const handleClickTabs = (key: string) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="events">
        <div className="events-wrap">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <div className="events__content">
              <Tabs
                defaultActiveKey={activeTab}
                // onChange={callback}
                onTabClick={(activeKey: string) => handleClickTabs(activeKey)}
              >
                <TabPane tab="Hội thảo sắp tới" key="1">
                  <div className="guide-zoom d-flex align-items-center justify-content-between">
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setModelSearch(true);
                      }}
                    >
                      <img
                        src={search}
                        alt="search"
                        className="icon-search mr-2"
                      />
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#6B778C",
                        }}
                      >
                        Tìm kiếm
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => history.push("/guide-zoom")}
                    >
                      <img src={icon_help} alt="help" />
                      <div className="guide-zoom-text ml-2">
                        Hướng dẫn sử dụng Zoom
                      </div>
                    </div>
                  </div>

                  <div className="events__section">
                    <div className="events__section-content">
                      {eventUpComingId.map((id: string) => {
                        const eventUpComing = entitiesEventUpComing[id] || {};
                        return (
                          <BessinsCard
                            handleClick={handleOnClickCard}
                            data={eventUpComing}
                            key={`event-${eventUpComing._id}`}
                          />
                        );
                      })}
                    </div>
                    {!!hasNextPageEventUpComing && (
                      <div className="text-center mt-3">
                        <BesinsButton
                          ghost
                          type="default"
                          className="showMore"
                          onClick={handleLoadMoreEventUpComing}
                          loading={loadMore}
                          disabled={loadMore}
                        >
                          Xem thêm
                        </BesinsButton>
                      </div>
                    )}
                  </div>
                </TabPane>
                <TabPane tab="Hội thảo đã diễn ra" key="2">
                  <div className="guide-zoom d-flex align-items-center justify-content-between">
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setModelSearch(true);
                      }}
                    >
                      <img
                        src={search}
                        alt="search"
                        className="icon-search mr-2"
                      />
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#6B778C",
                        }}
                      >
                        Tìm kiếm
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => history.push("/guide-zoom")}
                    >
                      <img src={icon_help} alt="help" />
                      <div className="guide-zoom-text ml-2">
                        Hướng dẫn sử dụng Zoom
                      </div>
                    </div>
                  </div>
                  <div className="events__section">
                    <div className="events__section-content">
                      {eventId.map((id: string) => {
                        const event = entitiesEvent[id] || {};
                        return (
                          <BessinsCard
                            key={`event-${event._id}`}
                            handleClick={handleOnClickCard}
                            data={event}
                          />
                        );
                      })}
                    </div>
                    {!!hasNextPage && (
                      <div className="text-center mt-3">
                        <BesinsButton
                          ghost
                          type="default"
                          className="showMore"
                          onClick={handleLoadMore}
                          loading={loadMore}
                          disabled={loadMore}
                        >
                          Xem thêm
                        </BesinsButton>
                      </div>
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
        <ShowSearchModal
          visible={modelSearch}
          onCancel={() => setModelSearch(false)}
          closable={false}
        />
      </div>
    </>
  );
}

export default WithNavigation(EventPage);
