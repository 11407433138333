import { DatePicker } from "antd";
import { Moment } from "moment";
import React, { FC } from "react";
import "./style.scss";

interface DatePickerProps {
  defaultValue?: Moment;
  label?: string;
  className?: any;
  placeholder?: string;
  value: any;
  onChange: (date: any) => void;
  disabled:any
}

const DatePickerCustom: FC<DatePickerProps> = ({
  label,
  className,
  placeholder,
  value,
  onChange,
  defaultValue,
  disabled,
  ...other
}) => {
  return (
    <div className={`custom-input ${className}`}>
      <div className="custom-input-label mb-1">{label}</div>
      <DatePicker
        // defaultValue={moment("2015/01/01", "DD-MM-YYYY")}
        defaultValue={defaultValue}
        format={"DD-MM-YYYY"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...other}
      />
    </div>
  );
};

export default DatePickerCustom;
