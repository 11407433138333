import actionTypes from "../actionTypes";

const initialState = {
  news: {
    isShow: false,
    isLoading: false,
    isBookmark: false
  },
  category_bookmark: {},
  click_bookmark: [],
  state_page: '',
  news_list: {
    news_list_hots: [{
      isBookmark:false
    }],
    news_list_new: []
  }
};

const newsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_HOT_NEWS:
      state = {
        ...state, news_list: {
          ...state.news_list,
          news_list_hots: action.payload,
        },
      };
      return state; 
    case actionTypes.GET_NEW_NEWS:
      state = {
        ...state, news_list: {
          ...state.news_list,
          news_list_new: action.payload,
        },
      };      return state;
    case actionTypes.GET_SET_NEWS:
      state = { ...state, news: action.news };
      return state;
    case actionTypes.RESET_NEWS:
      state = { ...state, news: initialState.news };
      return state;
    case actionTypes.GET_BOOKMARK:
      state = {
        ...state, news_list: {
          ...state.news_list,
          news_list_new: action.payload.new,
          news_list_hots: action.payload.hot,
        },
      };      return state;
    case actionTypes.SET_STATE_PAGE:
      state = { ...state, state_page: action.state_page };
      return state;
    case actionTypes.CATEGORY_BOOKMARK:
      state = { ...state, category_bookmark: action.category_bookmark };
      return state;
    default:
      return state;
  }
};

export default newsReducer;
