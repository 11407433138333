import React from "react";
import "./style.scss";
import { Select, Avatar, Tooltip } from "antd";
const { Option } = Select;

type Props = {
  allowClear?: boolean;
  withIcon?: boolean;
  placeholder?: string;
  label?: string;
  option?: Array<any>;
  disabled?: boolean;
  showSearch?: boolean;
  error?: boolean;
  errorText?: string;
  onChange?: any;
  onSearch?: any;
  value?: any;
  filterOption?: boolean | any;
  labelInValue?: boolean;
  optionFilterProp?: string;
  loading?: boolean;
  className?: string;
  defaultValue?: any;
};

const CustomSelect = (props: Props) => {
  const {
    allowClear,
    withIcon,
    placeholder,
    label,
    disabled,
    showSearch,
    option,
    onChange,
    onSearch,
    value,
    filterOption,
    labelInValue,
    optionFilterProp,
    loading,
    className,
    defaultValue,
  } = props;

  function limitTitle(title: string, number: number) {
    if (title.length < number) {
      return <span style={{ color: "#172B4D" }}>{title}</span>;
    } else {
      return (
        <Tooltip title={title} placement="bottom">
          <span style={{ color: "#172B4D" }}>
            {title.substring(0, number)}...
          </span>
        </Tooltip>
      );
    }
  }
  return (
    <div className={`custom-select-icon ${className}`}>
      <label className="select-label">{label}</label>
      <Select
        defaultValue={defaultValue}
        value={value}
        labelInValue={labelInValue}
        showSearch={showSearch}
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={disabled}
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        loading={loading}
        size="large"
        style={{ width: "100%", fontSize: "14px" }}
      >
        {option &&
          option.map((item) => (
            <Option
              className="option-item"
              key={item.value}
              value={item.value}
              label={item.label}
            >
              <div
                className="select-option"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {withIcon ? (
                  <div style={{ width: "100%" }}>
                    {" "}
                    {item.icon ? (
                      <Avatar src={item.icon} size="small" />
                    ) : (
                      <Avatar size="small" style={{ background: "#0073c4" }}>
                        {item.label && item.label[0]}
                      </Avatar>
                    )}
                    <span
                      className="option-label"
                      style={{ marginLeft: "8px" }}
                    >
                      {limitTitle(item.label, 30)}
                    </span>
                  </div>
                ) : (
                  <span className="option-label">{item.label}</span>
                )}
              </div>
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default CustomSelect;

CustomSelect.defaultProps = {
  allowClear: true,
  withIcon: false,
  labelInValue: false,
  className: "",
  placeholder: "",
  label: "",
  disabled: false,
  showSearch: true,
  error: false,
  errorText: "",
};
