import React from "react";
import { Input } from "antd";
// styles
import "./style.scss";
import { cancel_search } from "variables/image";

type Props = {
  placeholder?: string;
  name?: string;
  onChange?: any;
  value?: any;
  allowClear?: boolean;
};

function BesinsInputSearch(props: Props) {
  return (
    <div className="input-search">
      <Input
        prefix={
          <>
            <i
              className="icon icon-search"
              style={{
                color: "rgba(0,0,0,.25)",
                fontSize: 24,
                marginTop: 3,
              }}
            />
          </>
        }
        // suffix={
        //   <>
        //     <img src={cancel_search} />
        //   </>
        // }
        allowClear={props.allowClear}
        className="input-search-besins"
        type="search"
        autoComplete="off"
        size="small"
        value={props.value}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
      />
    </div>
  );
}

BesinsInputSearch.defaultProps = {
  placeholder: "Tìm kiếm bằng từ khoá",
  name: "valueSearch",
};

export default BesinsInputSearch;
