const actionTypes = {
  SET_TOKEN: "SET_TOKEN",
  SET_TOKEN_FOR_MOBILE: "SET_TOKEN_FOR_MOBILE",

  GET_PROFILE: "GET_PROFILE",
  GET_DOCTOR_PROFILE: "GET_DOCTOR_PROFILE",

  GET_ORDER_PRODUCT_LIST: "GET_ORDER_PRODUCT_LIST",
  CATEGORY_BOOKMARK : "CATEGORY_BOOKMARK",

  ARTICLE_BOOKMARK:"CATEGORY_BOOKMARK",
  RESET_CART: "RESET_CART",
  UPDATE_CART: "UPDATE_CART",

  GET_SET_ORDER: "GET_SET_ORDER",
  RESET_ORDER: "RESET_ORDER",
  CONTACT_SUPLLIER:"CONTACT_SUPLLIER",

  GET_QUANTITY_HOMEPAGE: "GET_QUANTITY_HOMEPAGE",

  GET_PROMOTION_LIST: "GET_PROMOTION_LIST",
  GET_PROMOTION_DETAIL: "GET_PROMOTION_DETAIL",

  GET_ORDER_DETAIL: "GET_ORDER_DETAIL",
  RESET_ORDER_DETAIL: "RESET_ORDER_DETAIL",

  SET_NOTIFICATION_USER: "SET_NOTIFICATION_USER",
  SET_ALL_NOTICES_USER: "SET_ALL_NOTICES_USER",
  SET_TOTAL_NOTIFICATION: "SET_TOTAL_NOTIFICATION",
  
  GET_ALL_EVENT: "GET_ALL_EVENT",
  GET_EVENT_BY_PAGE: "GET_EVENT_BY_PAGE",
  GET_EVENT_UP_COMING_BY_PAGE: "GET_EVENT_UP_COMING_BY_PAGE",
  GET_ALL_EVENT_PIN: "GET_ALL_EVENT_PIN",
  GET_ALL_EVENT_UPCOMING: "GET_ALL_EVENT_UPCOMING",
  REGISTER_EVENT: "REGISTER_EVENT",
  REGISTER_EVENT_SUCCESS: "REGISTER_EVENT_SUCCESS",
  GET_EVENT_DETAIL: "GET_EVENT_DETAIL",
  GET_EVENTS_BY_DATE: "GET_EVENTS_BY_DATE",
  GET_NOTIFICATION_EVENTS: "GET_NOTIFICATION_EVENTS",
  GET_PROVINCE: "GET_PROVINCE",
  GET_DISTRICT: "GET_DISTRICT",
  GET_WARD: "GET_WARD",

  CHECK_PHONE_EXIST: "CHECK_PHONE_EXIST",
  VERIFY_CODE_PENDING: "VERIFY_CODE_PENDING",
  VERIFY_CODE_ERROR: "VERIFY_CODE_ERROR",
  VERIFY_CODE_SUCCESS: "VERIFY_CODE_SUCCESS",
  SEND_OTP :"SEND_OTP",
  CHECK_OTP_PHONE: "CHECK_OTP_PHONE",
  RESET_AUTH: "RESET_AUTH",

  GET_NEW_NEWS:'GET_NEW_NEWS',
  GET_HOT_NEWS:'GET_HOT_NEWS',
  GET_SET_NEWS: 'GET_SET_NEWS',
  RESET_NEWS: 'RESET_NEWS',
  SET_STATE_PAGE: 'SET_STATE_PAGE',
  GET_BOOKMARK : 'GET_BOOKMARK'
};

export default actionTypes;
