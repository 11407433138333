import BesinsButton from "components/BesinsButton";
import BesinsInput from "components/BesinsInput";
import BesinInputTextArea from "components/BesinsInput/BesinInputTextArea"
import LayoutApp from "layouts/LayoutApp";
import React, { useEffect } from "react";
import DatePicker from "components/DatePicker";
import { Input } from "antd";
import SelectBesins from "components/SelectIcon";
import { Formik } from "formik";
import * as yup from "yup";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actGetProvince,
  actGetDistrict,
  actGetWard,
  actGetDoctorProfile,
} from "store/actions/profile.action";
import UserService from "services/user.api";
import { RootState } from "models/index.model";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { notification } from "antd";
import { convertText } from "utils/convertText";
import { checkGender } from "utils/utilsCommon";

interface FormValues {
  fullName: string;
  birthday: any;
  gender: string | null;
  email: string;
  office: string;
  address: string;
  provinceCode: number | null;
  districtCode: number | null;
  wardCode: number | null;
  content: string | null;
}
const gender = [
  { label: "Nam", value: "MALE" },
  { label: "Nữ", value: "FEMALE" },
  { label: "Khác", value: "OTHER" },
];
const { TextArea } = Input;

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );
  const userCurrent = useSelector(
    (state: RootState) => state.profileReducer.user_info
  );

  const onSubmit = async (values: FormValues) => {
    const data = {
      fullName: convertText(values.fullName).toUpperCase(),
      email: values.email,
      phoneOrder: user.profile?.phone || user.profile?.phoneOrder,
      content: values.content
    };
    const dataContent = {
      type: false,
      content: data
    }
    try {
        await UserService.sendContentUpdate(dataContent);
        notification.success({
          message: "Gửi thông tin thành công !",
        });
        history.push("/");
    } catch (error) {
      notification.error({
        message: "Đã xảy ra lỗi!",
      });
    }
  };
  const [submitForm, setSubmitForm] = React.useState(false);
  const { province, district, ward } = useSelector(
    (state: RootState) => state.profileReducer.initData
  );

  const formSchema = yup.object({
    fullName: yup.string().required("Tên không được để trống"),
    email: yup
      .string()
      .required("Email không được để trống")
      .email("Email không hợp lệ"),
    office: yup
      .string()
      .required("Tên Bệnh viện / Phòng khám không được để trống"),
    address: yup
      .string()
      .required("Địa chỉ Bệnh viện / Phòng khám không được để trống"),
  });
  const initialFormValues: FormValues = {
    fullName: user.profile?.fullName as string,
    birthday: user.profile?.birthday ? moment(user.profile?.birthday) : null,
    gender: user.profile?.gender as string,
    email: user.email,
    office: user.details?.office as string,
    address: user.details?.address as string,
    provinceCode: user.details?.provinceCode,
    districtCode: user.details?.districtCode,
    wardCode: user.details?.wardCode,
    content: null
  };

  const getProvince = async () => {
    const res = await UserService.getProvince();
    const mapProvince = res.map((item: any) => ({
      label: item.name,
      value: item.provinceId,
    }));
    dispatch(actGetProvince(mapProvince));
  };

  const getDistrict = async (id: any) => {
    const res = await UserService.getDistrict(id);
    const mapDistrict = res.map((item: any) => ({
      label: item.name,
      value: item.districtId,
    }));
    dispatch(actGetDistrict(mapDistrict));
  };

  const getWard = async (id: any) => {
    const res = await UserService.getWard(id);
    const mapWard = res.map((item: any) => ({
      label: item.name,
      value: item.wardId,
    }));
    dispatch(actGetWard(mapWard));
  };

  useEffect(() => {
    if (user.details?.provinceCode) getDistrict(user.details?.provinceCode);
  }, [user.details?.provinceCode]);

  useEffect(() => {
    if (user.details?.districtCode) getWard(user.details?.districtCode);
  }, [user.details?.districtCode]);

  useEffect(() => {
    getProvince();
  }, []);

  const handleChangeProvince = async (id: any, setFieldValue: any) => {
    setFieldValue("provinceCode", id);
    setFieldValue("districtCode", null);
    setFieldValue("wardCode", null);

    getDistrict(id);
  };
  function disabledDate(current: Date) {
    // Can not select days before today and today
    console.log(Date.now);
    return current && current.valueOf() < Date.now();
  }
  const handleChangeDistrict = async (id: any, setFieldValue: any) => {
    setFieldValue("districtCode", id);
    setFieldValue("wardCode", null);
    getWard(id);
  };

  const handleChangeWard = (id: string, setFieldValue: any) => {
    setFieldValue("wardCode", id);
  };

  // const getDoctorInfo = async () => {
  //   const result = await UserService.getDoctorInfo();
  //   dispatch(actGetDoctorProfile(result));
  // };
  return (
    <LayoutApp title="Cập nhập thông tin">
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <div className="container-wrap">
            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                label="Họ tên"
                markup="Nhập chữ hoa, không dấu"
                placeholder="Nhập tên bác sĩ"
                value={values.fullName}
                onChange={(e: any) => setFieldValue("fullName", e.target.value)}
                onBlur={() => setFieldTouched("fullName")}
                readOnly
              />
              {errors && touched.fullName && (
                <div className="input-error-message">{errors.fullName}</div>
              )}
            </div>
            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                label="Số điện thoại"
                readOnly
                value={user.profile?.phone || user.profile?.phoneOrder}
              />
            </div>
            <DatePicker
              disabled={true}
              defaultValue={moment("20-11-1975", "DD-MM-YYYY")}
              className="input-custom mb-3"
              label="Ngày sinh"
              placeholder="Chọn ngày sinh"
              value={values.birthday}
              onChange={(value: any) => setFieldValue("birthday", value)}
            />
            <SelectBesins
              className="mb-3"
              label="Giới tính"
              placeholder="Chọn giới tính"
              allowClear={false}
              disabled
              optionFilterProp="label"
              filterOption={(input: any, option: any) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              option={gender}
              value={checkGender(values.gender as string)}
              onChange={(value: any) => setFieldValue("gender", value)}
            />

            <div className="mb-3">
              <BesinsInput
                className="input-custom"
                readOnly
                label="Email"
                placeholder=""
                value={values.email}
                onChange={(e: any) => setFieldValue("email", e.target.value)}
                onBlur={() => setFieldTouched("email")}
              />
              {errors && touched.email && (
                <div className="input-error-message">{errors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <BesinsInput
                readOnly
                className="input-custom"
                label="Bệnh viện / Nơi công tác"
                placeholder=""
                value={values.office}
                onChange={(e: any) => setFieldValue("office", e.target.value)}
                onBlur={() => setFieldTouched("office")}
              />
              {errors && (
                <div className="input-error-message">{errors.office}</div>
              )}
            </div>

            <div className="mb-3">
              <BesinsInput
                readOnly
                className="input-custom"
                label="Địa chỉ nhà hoặc phòng mạch tư"
                placeholder="Nhập số nhà, tên đường"
                allowClear={false}
                value={values.address}
                onChange={(e: any) => setFieldValue("address", e.target.value)}
                onBlur={() => setFieldTouched("address")}
              />
              {errors && (
                <div className="input-error-message">{errors.address}</div>
              )}
            </div>
            <SelectBesins
              disabled
              className="mb-3"
              label="Tỉnh / Thành phố"
              placeholder="Chọn Tỉnh / Thành phố"
              allowClear={false}
              optionFilterProp="label"
              filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              option={province}
              value={values.provinceCode}
              onChange={(value: any) =>
                handleChangeProvince(value, setFieldValue)
              }
            />
            <SelectBesins
              className="mb-3"
              label="Quận / Huyện"
              placeholder="Chọn Quận / Huyện"
              allowClear={false}
              optionFilterProp="label"
              filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              option={district}
              value={values.districtCode}
              onChange={(value: any) =>
                handleChangeDistrict(value, setFieldValue)
              }
              disabled
            />
            <SelectBesins
              className="mb-3"
              label="Phường / Xã"
              placeholder="Chọn Phường / Xã"
              allowClear={false}
              optionFilterProp="label"
              filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              option={ward}
              value={values.wardCode}
              onChange={(value: any) => handleChangeWard(value, setFieldValue)}
              disabled
            />
            <BesinInputTextArea
              label="Ghi chú"
              className="input-custom mb-3"
              allowClear={false}
              value={values.content}
              onChange={(e: any) => {
                setFieldValue("content", e.target.value);
                 if (e.target.value.length > 2) {
                   setSubmitForm(true);
                }
                else {
                  setSubmitForm(false);
                }
              }}
            />
            <div className="custom-lable">Nếu cần chỉnh sửa thông tin đăng ký, quý thành viên vui lòng ghi chú tại đây hoặc liên hệ email: customerservicebhvn@gmail.com để được hỗ trợ </div>
            <BesinsButton className="mb-4" block onClick={handleSubmit} disabled={submitForm ? false : true}>
              Gửi thông tin cho hỗ trợ
            </BesinsButton>
          </div>
        )}
      </Formik>
    </LayoutApp>
  );
};

export default UpdateProfile;
