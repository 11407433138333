import { search } from 'variables/image';
import callApi from "../utils/callApi";
import queryString from "query-string";

const EventService = {
  getEvents: async (query: any) => {
    const endpoint = `event/list-by-doctor?${queryString.stringify(query)}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },

   getEventsSearch: async (params: any) => {
    const endpoint = `event/search?search=${params.search}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },

  getEventsUpComing: async (query: any) => {
    const endpoint = `event/list-by-doctor-upcoming?${queryString.stringify(
      query
    )}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getEventsPin: async () => {
    const query = {
      page: 1,
      limit: 10,
    };
    const endpoint = `event/list-by-doctor-pin?${queryString.stringify(query)}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getEventDetail: async (url: string) => {
    const endpoint = `event/event-detail-client/${url}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  registerEvent: async (id: string) => {
    const endpoint = `event/event-registration`;
    const res = await callApi(endpoint, "POST", id);
    return res;
  },
  registerEventByDoctor: async (data: any) => {
    const endpoint = `event/event-registration`;
    const res = await callApi(endpoint, "POST", data);
    return res;
  },
  getEventByCalendar: async (date: string) => {
    const endpoint = `event/my-event-calendar?date=${date}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getNotification: async (data?: any) => {
    const endpoint = `cms/notification/my_notification?generalType=["EVENT"]&limit=${data?.limit}`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
  getGuideZoom: async () => {
    const endpoint = `setting/detail/DOC_ZOOM`;
    const res = await callApi(endpoint, "GET");
    return res;
  },
};

export default EventService;
