import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
//redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import appReducers from "./store/reducers/index.reducer";
//react-router-dom
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import indexRoutes from "./routes/index.route";
import NotificationPopUp from "./components/Notification/NotificationPopUp";
//scss
import "./assets/scss/reset.scss";
import "./assets/scss/css/iconstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SplashScreen from "components/SplashScreen";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
// import SplashScreen from "components/SplashScreen";

const instance = createInstance({
  urlBase: 'https://wejelly.matomo.cloud/',
  siteId: 2,
  trackerUrl: 'https://wejelly.matomo.cloud/matomo.php',
  srcUrl: 'https://wejelly.matomo.cloud/matomo.js', 
  disabled: false,
  heartBeat: { 
    active: true, 
    seconds: 10 
  },
  linkTracking: true,
})

const actionSanitizer = (action: any) => {
  const uiRouterActions = /@ui-router.+/g;
  return uiRouterActions.test(action.type)
    ? {
      type: action.type,
      transition: sanitizeUIRouterTransition(action.transition),
    }
    : action;
};

const stateSanitizer = (state: any) => {
  if (state.router && state.router.last && state.router.last) {
    return {
      ...state,
      router: sanitizeUIRouterTransition(state.router.last),
    };
  }
  return state;
};

const sanitizeUIRouterTransition = (transition: any): any => ({
  params:
    transition.router &&
    transition.router.globals &&
    transition.router.globals.params,
  current:
    transition.router &&
    transition.router.globals &&
    transition.router.globals.current,
  targetState: transition.targetState && transition.targetState().state(),
  from: transition.from && transition.from(),
  to: transition.to && transition.to(),
});

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
  actionSanitizer,
  stateSanitizer,
});

const store = createStore(
  appReducers,
  composeEnhancers(applyMiddleware(thunk))
);
function App() {
  return (
    <Provider store={store}>  
        <Router>
          <Switch>
            {indexRoutes.map((route, key) => {
              return (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={key}
                />
              );
            })}
          </Switch>
        </Router>
      <NotificationPopUp />
    </Provider>
  )
}
ReactDOM.render(
  (<MatomoProvider value={instance}>
    <App />
  </MatomoProvider>), document.getElementById("root")
);

serviceWorker.unregister();
